import * as actionTypes from './actionTypes';
import { SectionActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { ListResults } from '../../common/List/List';
import { Section } from '../../domain/Section';

export type SectionStateType = {
  sectionsList: ListResults<Section> | null;
  sectionsLoading: boolean;
  sectionsError: HttpError;
  sectionsListUpdateNeeded: boolean;
  sectionCreateLoading: boolean;
  sectionCreateError: HttpError;
  sectionUpdateLoading: boolean;
  sectionUpdateError: HttpError;
  sectionDeleteLoading: boolean;
  sectionDeleteError: HttpError;
  sectionsPriorityUpdateLoading: boolean;
  sectionsPriorityUpdateError: HttpError;
  sectionsPriorityUpdateSuccess: boolean;
  sectionStatusLoading: boolean;
  sectionStatusSuccess: boolean;
  sectionStatusError: HttpError;
};

export type SectionActionType = SectionStateType & {
  type: SectionActionTypes;
};

export const initialState: SectionStateType = {
  sectionsList: null,
  sectionsLoading: false,
  sectionsListUpdateNeeded: false,
  sectionsError: null,
  sectionCreateLoading: false,
  sectionCreateError: null,
  sectionUpdateLoading: false,
  sectionUpdateError: null,
  sectionDeleteLoading: false,
  sectionDeleteError: null,
  sectionsPriorityUpdateLoading: false,
  sectionsPriorityUpdateError: null,
  sectionsPriorityUpdateSuccess: false,
  sectionStatusError: null,
  sectionStatusLoading: false,
  sectionStatusSuccess: false,
};

const fetchSectionsStart = (state: SectionStateType): SectionStateType => ({
  ...state,
  sectionsLoading: true,
});

const fetchSectionsSuccess = (
  state: SectionStateType,
  action: SectionActionType,
): SectionStateType => ({
  ...state,
  sectionsList: action.sectionsList,
  sectionsLoading: false,
  sectionsError: null,
  sectionsListUpdateNeeded: false,
});

const fetchSectionsFail = (
  state: SectionStateType,
  action: SectionActionType,
): SectionStateType => ({
  ...state,
  sectionsError: action.sectionsError,
  sectionsLoading: false,
});

const createSectionStart = (state: SectionStateType): SectionStateType => ({
  ...state,
  sectionCreateLoading: true,
  sectionsPriorityUpdateSuccess: false,
});

const createSectionSuccess = (state: SectionStateType): SectionStateType => ({
  ...state,
  sectionCreateLoading: false,
  sectionCreateError: null,
  sectionsListUpdateNeeded: true,
});

const createSectionFail = (
  state: SectionStateType,
  action: SectionActionType,
): SectionStateType => ({
  ...state,
  sectionCreateLoading: false,
  sectionCreateError: action.sectionCreateError,
});

const updateSectionStart = (state: SectionStateType): SectionStateType => ({
  ...state,
  sectionUpdateLoading: true,
  sectionsPriorityUpdateSuccess: false,
});

const updateSectionSuccess = (state: SectionStateType): SectionStateType => ({
  ...state,
  sectionUpdateLoading: false,
  sectionUpdateError: null,
  sectionsListUpdateNeeded: true,
});

const updateSectionFail = (
  state: SectionStateType,
  action: SectionActionType,
): SectionStateType => ({
  ...state,
  sectionUpdateLoading: false,
  sectionUpdateError: action.sectionUpdateError,
});

const deleteSectionStart = (state: SectionStateType): SectionStateType => ({
  ...state,
  sectionDeleteLoading: true,
  sectionsPriorityUpdateSuccess: false,
});

const deleteSectionSuccess = (state: SectionStateType): SectionStateType => ({
  ...state,
  sectionDeleteLoading: false,
  sectionDeleteError: null,
  sectionsListUpdateNeeded: true,
});

const deleteSectionFail = (
  state: SectionStateType,
  action: SectionActionType,
): SectionStateType => ({
  ...state,
  sectionDeleteLoading: false,
  sectionDeleteError: action.sectionDeleteError,
});

const updateSectionsPriorityStart = (
  state: SectionStateType,
): SectionStateType => ({
  ...state,
  sectionsPriorityUpdateLoading: true,
  sectionsPriorityUpdateSuccess: false,
});

const updateSectionsPrioritySuccess = (
  state: SectionStateType,
): SectionStateType => ({
  ...state,
  sectionsPriorityUpdateError: null,
  sectionsPriorityUpdateSuccess: true,
  sectionsListUpdateNeeded: true,
});

const updateSectionsPriorityFail = (
  state: SectionStateType,
  action: SectionActionType,
): SectionStateType => ({
  ...state,
  sectionsPriorityUpdateLoading: false,
  sectionsPriorityUpdateError: action.sectionsPriorityUpdateError,
});

const toggleSectionStatusStart = (
  state: SectionStateType,
): SectionStateType => ({
  ...state,
  sectionStatusLoading: true,
});

const toggleSectionStatusSuccess = (
  state: SectionStateType,
): SectionStateType => ({
  ...state,
  sectionStatusLoading: false,
  sectionStatusError: null,
  sectionStatusSuccess: true,
  sectionsListUpdateNeeded: true,
});

const toggleSectionStatusFail = (
  state: SectionStateType,
  action: SectionActionType,
): SectionStateType => ({
  ...state,
  sectionStatusLoading: false,
  sectionStatusError: action.sectionStatusError,
});

const resetSectionStore = (): SectionStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: SectionActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_SECTIONS_START:
      return fetchSectionsStart(state);
    case actionTypes.FETCH_SECTIONS_SUCCESS:
      return fetchSectionsSuccess(state, action);
    case actionTypes.FETCH_SECTIONS_FAIL:
      return fetchSectionsFail(state, action);
    case actionTypes.CREATE_SECTION_START:
      return createSectionStart(state);
    case actionTypes.CREATE_SECTION_SUCCESS:
      return createSectionSuccess(state);
    case actionTypes.CREATE_SECTION_FAIL:
      return createSectionFail(state, action);
    case actionTypes.UPDATE_SECTION_START:
      return updateSectionStart(state);
    case actionTypes.UPDATE_SECTION_SUCCESS:
      return updateSectionSuccess(state);
    case actionTypes.UPDATE_SECTION_FAIL:
      return updateSectionFail(state, action);
    case actionTypes.DELETE_SECTION_START:
      return deleteSectionStart(state);
    case actionTypes.DELETE_SECTION_SUCCESS:
      return deleteSectionSuccess(state);
    case actionTypes.DELETE_SECTION_FAIL:
      return deleteSectionFail(state, action);
    case actionTypes.RESET_SECTION_STORE:
      return resetSectionStore();
    case actionTypes.TOGGLE_SECTION_STATUS_START:
      return toggleSectionStatusStart(state);
    case actionTypes.TOGGLE_SECTION_STATUS_SUCCESS:
      return toggleSectionStatusSuccess(state);
    case actionTypes.TOGGLE_SECTION_STATUS_FAIL:
      return toggleSectionStatusFail(state, action);
    case actionTypes.UPDATE_SECTIONS_PRIORITY_START:
      return updateSectionsPriorityStart(state);
    case actionTypes.UPDATE_SECTIONS_PRIORITY_SUCCESS:
      return updateSectionsPrioritySuccess(state);
    case actionTypes.UPDATE_SECTIONS_PRIORITY_FAIL:
      return updateSectionsPriorityFail(state, action);
    default:
      return state;
  }
};

export default reducer;
