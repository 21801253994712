import * as actionTypes from './actionTypes';
import { RiskActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';

export type RiskStateType = {
  risksListUpdateNeeded: boolean;
  riskCreateLoading: boolean;
  riskCreateError: HttpError;
  riskUpdateLoading: boolean;
  riskUpdateError: HttpError;
  riskDeleteLoading: boolean;
  riskDeleteError: HttpError;
};

export type RiskActionType = RiskStateType & {
  type: RiskActionTypes;
};

export const initialState: RiskStateType = {
  risksListUpdateNeeded: false,
  riskCreateLoading: false,
  riskCreateError: null,
  riskUpdateLoading: false,
  riskUpdateError: null,
  riskDeleteLoading: false,
  riskDeleteError: null,
};

const createRiskStart = (state: RiskStateType): RiskStateType => ({
  ...state,
  riskCreateLoading: true,
});

const createRiskSuccess = (state: RiskStateType): RiskStateType => ({
  ...state,
  riskCreateLoading: false,
  riskCreateError: null,
  risksListUpdateNeeded: true,
});

const createRiskFail = (
  state: RiskStateType,
  action: RiskActionType,
): RiskStateType => ({
  ...state,
  riskCreateLoading: false,
  riskCreateError: action.riskCreateError,
});

const updateRiskStart = (state: RiskStateType): RiskStateType => ({
  ...state,
  riskUpdateLoading: true,
});

const updateRiskSuccess = (state: RiskStateType): RiskStateType => ({
  ...state,
  riskUpdateLoading: false,
  riskUpdateError: null,
  risksListUpdateNeeded: true,
});

const updateRiskFail = (
  state: RiskStateType,
  action: RiskActionType,
): RiskStateType => ({
  ...state,
  riskUpdateLoading: false,
  riskUpdateError: action.riskUpdateError,
});

const deleteRiskStart = (state: RiskStateType): RiskStateType => ({
  ...state,
  riskDeleteLoading: true,
});

const deleteRiskSuccess = (state: RiskStateType): RiskStateType => ({
  ...state,
  riskDeleteLoading: false,
  riskDeleteError: null,
  risksListUpdateNeeded: true,
});

const deleteRiskFail = (
  state: RiskStateType,
  action: RiskActionType,
): RiskStateType => ({
  ...state,
  riskDeleteLoading: false,
  riskDeleteError: action.riskDeleteError,
});

const resetRiskStore = (): RiskStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: RiskActionType) => {
  switch (action.type) {
    case actionTypes.CREATE_RISK_START:
      return createRiskStart(state);
    case actionTypes.CREATE_RISK_SUCCESS:
      return createRiskSuccess(state);
    case actionTypes.CREATE_RISK_FAIL:
      return createRiskFail(state, action);
    case actionTypes.UPDATE_RISK_START:
      return updateRiskStart(state);
    case actionTypes.UPDATE_RISK_SUCCESS:
      return updateRiskSuccess(state);
    case actionTypes.UPDATE_RISK_FAIL:
      return updateRiskFail(state, action);
    case actionTypes.DELETE_RISK_START:
      return deleteRiskStart(state);
    case actionTypes.DELETE_RISK_SUCCESS:
      return deleteRiskSuccess(state);
    case actionTypes.DELETE_RISK_FAIL:
      return deleteRiskFail(state, action);
    case actionTypes.RESET_RISK_STORE:
      return resetRiskStore();
    default:
      return state;
  }
};

export default reducer;
