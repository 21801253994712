import * as actionTypes from './actionTypes';
import { CompanyMeetingActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { ListResults } from '../../common/List/List';
import { CompanyMeeting } from '../../domain/CompanyMeeting';
import { Employee } from '../../domain/Employee';

export type CompanyMeetingStateType = {
  companyMeetingsList: ListResults<CompanyMeeting> | null;
  companyMeetingsLoading: boolean;
  companyMeetingsError: HttpError;
  companyMeeting: CompanyMeeting | null;
  companyMeetingLoading: boolean;
  companyMeetingError: HttpError;
  companyMeetingsListUpdateNeeded: boolean;
  companyMeetingCreateLoading: boolean;
  companyMeetingCreateError: HttpError;
  companyMeetingUpdateLoading: boolean;
  companyMeetingUpdateError: HttpError;
  companyMeetingDeleteLoading: boolean;
  companyMeetingDeleteError: HttpError;
  companyMeetingProtocolCreateLoading: boolean;
  companyMeetingProtocolCreateError: HttpError;
  companyMeetingProtocolCreateSuccess: boolean;
  companyMeetingProtocolUpdateLoading: boolean;
  companyMeetingProtocolUpdateError: HttpError;
  companyMeetingProtocolUpdateSuccess: boolean;
  askToSignLoading: boolean;
  askToSignSuccess: boolean;
  askToSignError: HttpError;
  signedEmployees: Employee[] | null;
  signedEmployeesLoading: boolean;
  signedEmployeesError: HttpError;
};

export type CompanyMeetingActionType = CompanyMeetingStateType & {
  type: CompanyMeetingActionTypes;
};

export const initialState: CompanyMeetingStateType = {
  companyMeetingsList: null,
  companyMeetingsLoading: false,
  companyMeetingsListUpdateNeeded: false,
  companyMeetingsError: null,
  companyMeetingCreateLoading: false,
  companyMeetingCreateError: null,
  companyMeetingUpdateLoading: false,
  companyMeetingUpdateError: null,
  companyMeetingDeleteLoading: false,
  companyMeetingDeleteError: null,
  companyMeetingProtocolCreateError: null,
  companyMeetingProtocolCreateLoading: false,
  companyMeetingProtocolCreateSuccess: false,
  companyMeeting: null,
  companyMeetingError: null,
  companyMeetingLoading: false,
  companyMeetingProtocolUpdateError: null,
  companyMeetingProtocolUpdateSuccess: false,
  companyMeetingProtocolUpdateLoading: false,
  askToSignError: null,
  askToSignSuccess: false,
  askToSignLoading: false,
  signedEmployees: null,
  signedEmployeesError: null,
  signedEmployeesLoading: false,
};

const fetchCompanyMeetingsStart = (
  state: CompanyMeetingStateType,
): CompanyMeetingStateType => ({
  ...state,
  companyMeetingsLoading: true,
});

const fetchCompanyMeetingsSuccess = (
  state: CompanyMeetingStateType,
  action: CompanyMeetingActionType,
): CompanyMeetingStateType => ({
  ...state,
  companyMeetingsList: action.companyMeetingsList,
  companyMeetingsLoading: false,
  companyMeetingsError: null,
  companyMeetingsListUpdateNeeded: false,
});

const fetchCompanyMeetingsFail = (
  state: CompanyMeetingStateType,
  action: CompanyMeetingActionType,
): CompanyMeetingStateType => ({
  ...state,
  companyMeetingsError: action.companyMeetingsError,
  companyMeetingsLoading: false,
});

const fetchCompanyMeetingStart = (
  state: CompanyMeetingStateType,
): CompanyMeetingStateType => ({
  ...state,
  companyMeetingLoading: true,
});

const fetchCompanyMeetingSuccess = (
  state: CompanyMeetingStateType,
  action: CompanyMeetingActionType,
): CompanyMeetingStateType => ({
  ...state,
  companyMeeting: action.companyMeeting,
  companyMeetingLoading: false,
  companyMeetingError: null,
});

const fetchCompanyMeetingFail = (
  state: CompanyMeetingStateType,
  action: CompanyMeetingActionType,
): CompanyMeetingStateType => ({
  ...state,
  companyMeetingError: action.companyMeetingError,
  companyMeetingLoading: false,
});

const createCompanyMeetingStart = (
  state: CompanyMeetingStateType,
): CompanyMeetingStateType => ({
  ...state,
  companyMeetingCreateLoading: true,
});

const createCompanyMeetingSuccess = (
  state: CompanyMeetingStateType,
): CompanyMeetingStateType => ({
  ...state,
  companyMeetingCreateLoading: false,
  companyMeetingCreateError: null,
  companyMeetingsListUpdateNeeded: true,
});

const createCompanyMeetingFail = (
  state: CompanyMeetingStateType,
  action: CompanyMeetingActionType,
): CompanyMeetingStateType => ({
  ...state,
  companyMeetingCreateLoading: false,
  companyMeetingCreateError: action.companyMeetingCreateError,
});

const updateCompanyMeetingStart = (
  state: CompanyMeetingStateType,
): CompanyMeetingStateType => ({
  ...state,
  companyMeetingUpdateLoading: true,
});

const updateCompanyMeetingSuccess = (
  state: CompanyMeetingStateType,
): CompanyMeetingStateType => ({
  ...state,
  companyMeetingUpdateLoading: false,
  companyMeetingUpdateError: null,
  companyMeetingsListUpdateNeeded: true,
});

const updateCompanyMeetingFail = (
  state: CompanyMeetingStateType,
  action: CompanyMeetingActionType,
): CompanyMeetingStateType => ({
  ...state,
  companyMeetingUpdateLoading: false,
  companyMeetingUpdateError: action.companyMeetingUpdateError,
});

const deleteCompanyMeetingStart = (
  state: CompanyMeetingStateType,
): CompanyMeetingStateType => ({
  ...state,
  companyMeetingDeleteLoading: true,
});

const deleteCompanyMeetingSuccess = (
  state: CompanyMeetingStateType,
): CompanyMeetingStateType => ({
  ...state,
  companyMeetingDeleteLoading: false,
  companyMeetingDeleteError: null,
  companyMeetingsListUpdateNeeded: true,
});

const deleteCompanyMeetingFail = (
  state: CompanyMeetingStateType,
  action: CompanyMeetingActionType,
): CompanyMeetingStateType => ({
  ...state,
  companyMeetingDeleteLoading: false,
  companyMeetingDeleteError: action.companyMeetingDeleteError,
});

const createCompanyMeetingProtocolStart = (
  state: CompanyMeetingStateType,
): CompanyMeetingStateType => ({
  ...state,
  companyMeetingProtocolCreateLoading: true,
});

const createCompanyMeetingProtocolSuccess = (
  state: CompanyMeetingStateType,
): CompanyMeetingStateType => ({
  ...state,
  companyMeetingProtocolCreateLoading: false,
  companyMeetingProtocolCreateError: null,
  companyMeetingProtocolCreateSuccess: true,
});

const createCompanyMeetingProtocolFail = (
  state: CompanyMeetingStateType,
  action: CompanyMeetingActionType,
): CompanyMeetingStateType => ({
  ...state,
  companyMeetingProtocolCreateLoading: false,
  companyMeetingProtocolCreateError: action.companyMeetingProtocolCreateError,
});

const updateCompanyMeetingProtocolStart = (
  state: CompanyMeetingStateType,
): CompanyMeetingStateType => ({
  ...state,
  companyMeetingProtocolUpdateLoading: true,
});

const updateCompanyMeetingProtocolSuccess = (
  state: CompanyMeetingStateType,
): CompanyMeetingStateType => ({
  ...state,
  companyMeetingProtocolUpdateLoading: false,
  companyMeetingProtocolUpdateError: null,
  companyMeetingProtocolUpdateSuccess: true,
});

const updateCompanyMeetingProtocolFail = (
  state: CompanyMeetingStateType,
  action: CompanyMeetingActionType,
): CompanyMeetingStateType => ({
  ...state,
  companyMeetingProtocolUpdateLoading: false,
  companyMeetingProtocolUpdateError: action.companyMeetingProtocolUpdateError,
});

const askToSignStart = (
  state: CompanyMeetingStateType,
): CompanyMeetingStateType => ({
  ...state,
  askToSignLoading: true,
  askToSignSuccess: false,
});

const askToSignSuccess = (
  state: CompanyMeetingStateType,
): CompanyMeetingStateType => ({
  ...state,
  askToSignLoading: false,
  askToSignError: null,
  askToSignSuccess: true,
  companyMeetingsListUpdateNeeded: true,
});

const askToSignFail = (
  state: CompanyMeetingStateType,
  action: CompanyMeetingActionType,
): CompanyMeetingStateType => ({
  ...state,
  askToSignLoading: false,
  askToSignError: action.askToSignError,
});

const fetchSignedEmployeesStart = (
  state: CompanyMeetingStateType,
): CompanyMeetingStateType => ({
  ...state,
  signedEmployeesLoading: true,
  signedEmployees: null,
  signedEmployeesError: null,
});

const fetchSignedEmployeesSuccess = (
  state: CompanyMeetingStateType,
  action: CompanyMeetingActionType,
): CompanyMeetingStateType => ({
  ...state,
  signedEmployeesLoading: false,
  signedEmployeesError: null,
  signedEmployees: action.signedEmployees,
});

const fetchSignedEmployeesFail = (
  state: CompanyMeetingStateType,
  action: CompanyMeetingActionType,
): CompanyMeetingStateType => ({
  ...state,
  signedEmployeesLoading: false,
  signedEmployeesError: action.signedEmployeesError,
});

const resetCompanyMeetingStore = (): CompanyMeetingStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: CompanyMeetingActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_COMPANY_MEETINGS_START:
      return fetchCompanyMeetingsStart(state);
    case actionTypes.FETCH_COMPANY_MEETINGS_SUCCESS:
      return fetchCompanyMeetingsSuccess(state, action);
    case actionTypes.FETCH_COMPANY_MEETINGS_FAIL:
      return fetchCompanyMeetingsFail(state, action);
    case actionTypes.FETCH_COMPANY_MEETING_START:
      return fetchCompanyMeetingStart(state);
    case actionTypes.FETCH_COMPANY_MEETING_SUCCESS:
      return fetchCompanyMeetingSuccess(state, action);
    case actionTypes.FETCH_COMPANY_MEETING_FAIL:
      return fetchCompanyMeetingFail(state, action);
    case actionTypes.CREATE_COMPANY_MEETING_START:
      return createCompanyMeetingStart(state);
    case actionTypes.CREATE_COMPANY_MEETING_SUCCESS:
      return createCompanyMeetingSuccess(state);
    case actionTypes.CREATE_COMPANY_MEETING_FAIL:
      return createCompanyMeetingFail(state, action);
    case actionTypes.UPDATE_COMPANY_MEETING_START:
      return updateCompanyMeetingStart(state);
    case actionTypes.UPDATE_COMPANY_MEETING_SUCCESS:
      return updateCompanyMeetingSuccess(state);
    case actionTypes.UPDATE_COMPANY_MEETING_FAIL:
      return updateCompanyMeetingFail(state, action);
    case actionTypes.DELETE_COMPANY_MEETING_START:
      return deleteCompanyMeetingStart(state);
    case actionTypes.DELETE_COMPANY_MEETING_SUCCESS:
      return deleteCompanyMeetingSuccess(state);
    case actionTypes.DELETE_COMPANY_MEETING_FAIL:
      return deleteCompanyMeetingFail(state, action);
    case actionTypes.CREATE_COMPANY_MEETING_PROTOCOL_START:
      return createCompanyMeetingProtocolStart(state);
    case actionTypes.CREATE_COMPANY_MEETING_PROTOCOL_SUCCESS:
      return createCompanyMeetingProtocolSuccess(state);
    case actionTypes.CREATE_COMPANY_MEETING_PROTOCOL_FAIL:
      return createCompanyMeetingProtocolFail(state, action);
    case actionTypes.UPDATE_COMPANY_MEETING_PROTOCOL_START:
      return updateCompanyMeetingProtocolStart(state);
    case actionTypes.UPDATE_COMPANY_MEETING_PROTOCOL_SUCCESS:
      return updateCompanyMeetingProtocolSuccess(state);
    case actionTypes.UPDATE_COMPANY_MEETING_PROTOCOL_FAIL:
      return updateCompanyMeetingProtocolFail(state, action);
    case actionTypes.FETCH_SIGNED_EMPLOYEES_START:
      return fetchSignedEmployeesStart(state);
    case actionTypes.FETCH_SIGNED_EMPLOYEES_SUCCESS:
      return fetchSignedEmployeesSuccess(state, action);
    case actionTypes.FETCH_SIGNED_EMPLOYEES_FAIL:
      return fetchSignedEmployeesFail(state, action);
    case actionTypes.ASK_TO_SIGN_START:
      return askToSignStart(state);
    case actionTypes.ASK_TO_SIGN_SUCCESS:
      return askToSignSuccess(state);
    case actionTypes.ASK_TO_SIGN_FAIL:
      return askToSignFail(state, action);
    case actionTypes.RESET_COMPANY_MEETING_STORE:
      return resetCompanyMeetingStore();
    default:
      return state;
  }
};

export default reducer;
