import React, { Suspense, useEffect, useMemo } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { connect } from 'react-redux';
import Loader from '../../common/Loader/Loader';
import { StoreState } from '../StoreProvider/StoreProvider';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import * as authService from '../../store/auth/service';
import * as userService from '../../store/user/service';
import { routes } from './routes';
import Layout from '../../common/Layout/Layout';
import jwtDecode from 'jwt-decode';
import moment from 'moment';
import { JwtToken } from '../Axios/axios-instance';
import { logout, selectLocale } from '../../store/auth/actions';
import * as languageService from '../../store/language/service';
import { Language } from '../../domain/Language';
import { DEFAULT_LOCALE } from '../constants';
import { Locale } from '../../domain/Translation';
import { IntlProvider } from 'react-intl';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from '../../common/ErrorFallback/ErrorFallback';
import { User } from '../../domain/User';
import { Roles } from '../../domain/Role';
import { getLocaleUrl } from '../../utility/url/urlHelper';
import { Company, CompanySystem } from '../../domain/Company';
import { isVisibleRoute } from '../../utility/system/system';

const LoginPage = React.lazy(
  () => import('../../pages/Public/LoginPage/LoginPage'),
);

const RegistrationPage = React.lazy(
  () => import('../../pages/Public/RegistrationPage/RegistrationPage'),
);

const RegistrationConfirmationPage = React.lazy(
  () =>
    import(
      '../../pages/Public/RegistrationConfirmationPage/RegistrationConfirmationPage'
    ),
);

const PasswordRemindPage = React.lazy(
  () => import('../../pages/Public/PasswordRemindPage/PasswordRemindPage'),
);

const PasswordResetPage = React.lazy(
  () => import('../../pages/Public/PasswordResetPage/PasswordResetPage'),
);

const UsersListPage = React.lazy(
  () => import('../../pages/Admin/User/UsersListPage/UsersListPage'),
);

const UserCreatePage = React.lazy(
  () => import('../../pages/Admin/User/UserCreatePage/UserCreatePage'),
);

const UserEditPage = React.lazy(
  () => import('../../pages/Admin/User/UserEditPage/UserEditPage'),
);

const TranslationsPage = React.lazy(
  () =>
    import(
      '../../pages/Admin/Translation/TranslationListPage/TranslationListPage'
    ),
);

const Companies = React.lazy(
  () => import('../../pages/Admin/Company/Companies/Companies'),
);

const CompanyCreate = React.lazy(
  () => import('../../pages/Admin/Company/CompanyCreate/CompanyCreate'),
);

const CompanyEdit = React.lazy(
  () => import('../../pages/Admin/Company/CompanyEdit/CompanyEdit'),
);

const CourseShop = React.lazy(
  () => import('../../pages/Admin/Course/CourseShopPage/CourseShop'),
);

const Courses = React.lazy(
  () => import('../../pages/Admin/Course/CoursesListPage/CourseListPage'),
);

const CourseCreate = React.lazy(
  () => import('../../pages/Admin/Course/CourseCreatePage/CourseCreatePage'),
);

const CourseEdit = React.lazy(
  () => import('../../pages/Admin/Course/CourseEditPage/CourseEditPage'),
);

const CourseDetails = React.lazy(
  () => import('../../pages/Admin/Course/CourseDetailsPage/CourseDetailsPage'),
);

const CourseMyDetails = React.lazy(
  () =>
    import('../../pages/Admin/Course/CourseMyDetailsPage/CourseMyDetailsPage'),
);

const CourseTest = React.lazy(
  () => import('../../pages/Admin/Course/CourseTestPage/CourseTestPage'),
);

const Employees = React.lazy(
  () => import('../../pages/Admin/Employee/Employees/Employees'),
);

const EmployeeCreate = React.lazy(
  () => import('../../pages/Admin/Employee/EmployeeCreate/EmployeeCreate'),
);

const EmployeeEdit = React.lazy(
  () => import('../../pages/Admin/Employee/EmployeeEdit/EmployeeEdit'),
);

const CompanyTypes = React.lazy(
  () => import('../../pages/Admin/CompanyType/CompanyTypes/CompanyTypes'),
);

const DynamicPage = React.lazy(
  () => import('../../pages/Public/DynamicPage/DynamicPage'),
);

const JobTitles = React.lazy(
  () => import('../../pages/Admin/JobTitles/JobTitles'),
);

const Instructions = React.lazy(
  () => import('../../pages/Admin/Instructions/Instructions'),
);

const CompanyInstructions = React.lazy(
  () =>
    import('../../pages/Admin/Company/CompanyInstructions/CompanyInstructions'),
);

const Meetings = React.lazy(() => import('../../pages/Admin/Meeting/Meetings'));

const CompanyMeetings = React.lazy(
  () => import('../../pages/Admin/Company/CompanyMeetings/CompanyMeetings'),
);
const CompanyMeetingProtocol = React.lazy(
  () =>
    import(
      '../../pages/Admin/Company/CompanyMeetingProtocol/CompanyMeetingProtocol'
    ),
);

const Risks = React.lazy(() => import('../../pages/Admin/Risks/Risks'));

const CompanyRisks = React.lazy(
  () => import('../../pages/Admin/Company/CompanyRisks/CompanyRisks'),
);

const Accidents = React.lazy(
  () => import('../../pages/Admin/Company/CompanyAccidents/CompanyAccidents'),
);

const Export = React.lazy(() => import('../../pages/Admin/Export/Export'));

const EmployeeInstructions = React.lazy(
  () => import('../../pages/Admin/Employee/Instructions/EmployeeInstructions'),
);
const EmployeeMeetings = React.lazy(
  () => import('../../pages/Admin/Employee/Meetings/EmployeeMeetings'),
);

const EmployeeAccidents = React.lazy(
  () => import('../../pages/Admin/Employee/Accidents/EmployeeAccidents'),
);

const Clients = React.lazy(() => import('../../pages/Admin/Clients/Clients'));

const Projects = React.lazy(
  () => import('../../pages/Admin/Projects/Projects'),
);

const EmployeeWorkLog = React.lazy(
  () => import('../../pages/Admin/Employee/WorkLogs/EmployeeWorkLogs'),
);

const WorkLogs = React.lazy(
  () => import('../../pages/Admin/WorkLogs/WorkLogs'),
);

export type Props = {
  isInitCompleted: boolean;
  isAuthenticated: boolean;
  onTryAutoSignup: () => void;
  isCurrentUserLoading: boolean;
  refreshTokenLoading: boolean;
  onFetchCurrentUser: () => void;
  onRefreshToken: () => void;
  jwtToken: string | null;
  lastActionAt: moment.Moment | null;
  onLogout: () => void;
  onLanguageFetch: (locale: string) => void;
  language: Language | null;
  onLanguagesInit: () => void;
  onSelectLocale: (locale: Locale) => void;
  selectedLocale: Locale;
  languages: Language[] | null;
  currentUser: User | null;
  selectedCompany: number | null;
  selectedSystem: CompanySystem | null;
  availableSystems: CompanySystem[] | null;
  selectedRole: Roles | null;
  companyOptions: Company[];
  isCompanyOptionsLoading: boolean;
};

const ALLOWED_LOCALES = ['en', 'lt', 'no'];

export const Router = ({
  isInitCompleted,
  isAuthenticated,
  onTryAutoSignup,
  isCurrentUserLoading,
  onFetchCurrentUser,
  refreshTokenLoading,
  onRefreshToken,
  jwtToken,
  lastActionAt,
  onLogout,
  onLanguageFetch,
  language,
  onLanguagesInit,
  selectedLocale,
  onSelectLocale,
  languages,
  currentUser,
  selectedCompany,
  selectedSystem,
  availableSystems,
  selectedRole,
  companyOptions,
  isCompanyOptionsLoading,
}: Props) => {
  const locale =
    window.location?.pathname?.substring(1)?.split('/')?.[0] ?? DEFAULT_LOCALE;
  const parsedLocale = (
    locale && ALLOWED_LOCALES.includes(locale) ? locale : DEFAULT_LOCALE
  ) as Locale;

  useEffect(() => {
    if (!jwtToken) {
      return;
    }

    const decodedJson: JwtToken = jwtDecode(jwtToken);

    if (!decodedJson) {
      return;
    }

    const difference = moment.duration(
      moment(decodedJson.exp * 1000).diff(moment()),
    );
    const differenceLastAction = moment.duration(moment().diff(lastActionAt));

    if (
      difference.asMinutes() < 5 &&
      differenceLastAction.asMinutes() < 5 &&
      !refreshTokenLoading
    ) {
      onRefreshToken();
    }

    const timeout = setTimeout(() => {
      onLogout();
    }, difference.asMilliseconds());

    return () => clearTimeout(timeout);
  }, [jwtToken, lastActionAt]);

  useEffect(() => {
    onTryAutoSignup();
  }, []);

  useEffect(() => {
    onFetchCurrentUser();
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      onFetchCurrentUser();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (!parsedLocale) {
      onLanguageFetch(parsedLocale ?? DEFAULT_LOCALE);
      return;
    }

    moment.locale(parsedLocale === 'en' ? 'en-gb' : parsedLocale);
    onLanguageFetch(parsedLocale);
    onLanguagesInit();
    onSelectLocale(parsedLocale);
  }, [parsedLocale]);

  const currentLanguage = useMemo(() => {
    const foundLanguage = languages?.find(
      (singleLanguage) => singleLanguage.locale === selectedLocale,
    );

    return foundLanguage ?? language;
  }, [languages, selectedLocale]);

  const mappedTranslations = currentLanguage?.translations.reduce(
    (obj, item) =>
      Object.assign(obj, {
        [item.alias]: item.value ? item.value : item.defaultValue,
      }),
    {},
  );

  return (
    <BrowserRouter basename="/">
      {isInitCompleted &&
      !isCurrentUserLoading &&
      language &&
      currentLanguage ? (
        <IntlProvider
          messages={mappedTranslations}
          locale={currentLanguage?.locale ?? language?.locale ?? DEFAULT_LOCALE}
          defaultLocale="en"
        >
          <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onReset={() => {
              window.location.reload();
            }}
          >
            <Suspense fallback={<Loader isLoading isFullScreen />}>
              <Layout isAuthenticated={isAuthenticated}>
                <Routes>
                  {!isAuthenticated && (
                    <>
                      <Route
                        path={getLocaleUrl(routes.admin, selectedLocale)}
                        element={
                          <Navigate
                            replace
                            to={getLocaleUrl(routes.login, selectedLocale)}
                          />
                        }
                      />
                      <Route
                        path={getLocaleUrl(routes.login, selectedLocale)}
                        element={<LoginPage />}
                      />
                      <Route
                        path={getLocaleUrl(routes.remind, selectedLocale)}
                        element={<PasswordRemindPage />}
                      />
                      <Route
                        path={getLocaleUrl(
                          routes.resetPassword,
                          selectedLocale,
                        )}
                        element={<PasswordResetPage />}
                      />
                      <Route
                        path={getLocaleUrl(routes.register, selectedLocale)}
                        element={<RegistrationPage />}
                      />
                      <Route
                        path={getLocaleUrl(
                          routes.registrationConfirmation,
                          selectedLocale,
                        )}
                        element={<RegistrationConfirmationPage />}
                      />
                      <Route
                        path={getLocaleUrl(routes.dynamicPage, selectedLocale)}
                        element={<DynamicPage />}
                      />
                      <Route
                        path="*"
                        element={
                          <Navigate
                            replace
                            to={getLocaleUrl(routes.login, selectedLocale)}
                          />
                        }
                      />
                    </>
                  )}
                  {isAuthenticated && currentUser && (
                    <>
                      {selectedCompany &&
                        (selectedRole === Roles.ADMIN ||
                          selectedRole === Roles.OWNER ||
                          selectedRole === Roles.MANAGER) && (
                          <>
                            <Route
                              path="*"
                              element={
                                <Navigate
                                  replace
                                  to={getLocaleUrl(
                                    selectedCompany
                                      ? routes.employees.list
                                      : selectedRole === Roles.ADMIN
                                      ? routes.users.list
                                      : routes.employees.list,
                                    selectedLocale,
                                  )}
                                />
                              }
                            />
                            <Route
                              path={getLocaleUrl(
                                routes.employees.list,
                                selectedLocale,
                              )}
                              element={<Employees />}
                            />
                            <Route
                              path={getLocaleUrl(
                                routes.employees.create,
                                selectedLocale,
                              )}
                              element={<EmployeeCreate />}
                            />
                            <Route
                              path={getLocaleUrl(
                                routes.employees.edit,
                                selectedLocale,
                              )}
                              element={<EmployeeEdit />}
                            />
                            <Route
                              path={getLocaleUrl(
                                routes.jobTitles.list,
                                selectedLocale,
                              )}
                              element={<JobTitles />}
                            />

                            {(selectedRole === Roles.OWNER ||
                              selectedRole === Roles.ADMIN) && (
                              <Route
                                path={getLocaleUrl(
                                  routes.export,
                                  selectedLocale,
                                )}
                                element={<Export />}
                              />
                            )}

                            {isVisibleRoute(
                              selectedRole,
                              availableSystems,
                              selectedSystem,
                              CompanySystem.HMS,
                            ) && (
                              <>
                                <Route
                                  path={getLocaleUrl(
                                    routes.companies.instructions,
                                    selectedLocale,
                                  )}
                                  element={<CompanyInstructions />}
                                />
                                <Route
                                  path={getLocaleUrl(
                                    routes.companies.actionPlans,
                                    selectedLocale,
                                  )}
                                  element={<CompanyMeetings />}
                                />
                                <Route
                                  path={getLocaleUrl(
                                    routes.companies.risks,
                                    selectedLocale,
                                  )}
                                  element={<CompanyRisks />}
                                />
                                <Route
                                  path={getLocaleUrl(
                                    routes.companies.actionPlanProtocol,
                                    selectedLocale,
                                  )}
                                  element={<CompanyMeetingProtocol />}
                                />
                                <Route
                                  path={getLocaleUrl(
                                    routes.accidents.list,
                                    selectedLocale,
                                  )}
                                  element={<Accidents />}
                                />
                              </>
                            )}
                          </>
                        )}

                      {selectedRole === Roles.OWNER &&
                        isVisibleRoute(
                          selectedRole,
                          availableSystems,
                          selectedSystem,
                          CompanySystem.COURSES,
                        ) && (
                          <>
                            <Route
                              path={getLocaleUrl(
                                routes.courses.shop,
                                selectedLocale,
                              )}
                              element={<CourseShop />}
                            />
                            <Route
                              path={getLocaleUrl(
                                routes.courses.details,
                                selectedLocale,
                              )}
                              element={<CourseDetails />}
                            />
                            <Route
                              path={getLocaleUrl(
                                routes.courses.myDetails,
                                selectedLocale,
                              )}
                              element={<CourseMyDetails />}
                            />
                            <Route
                              path={getLocaleUrl(
                                routes.courses.test,
                                selectedLocale,
                              )}
                              element={<CourseTest />}
                            />
                          </>
                        )}

                      {selectedRole === Roles.OWNER &&
                        isVisibleRoute(
                          selectedRole,
                          availableSystems,
                          selectedSystem,
                          CompanySystem.TIME_TRACKING,
                        ) && (
                          <>
                            <Route
                              path={getLocaleUrl(
                                routes.clients.list,
                                selectedLocale,
                              )}
                              element={<Clients />}
                            />
                            <Route
                              path={getLocaleUrl(
                                routes.projects.list,
                                selectedLocale,
                              )}
                              element={<Projects />}
                            />
                          </>
                        )}

                      {selectedRole === Roles.ADMIN && (
                        <>
                          <Route
                            path="*"
                            element={
                              <Navigate
                                replace
                                to={getLocaleUrl(
                                  selectedCompany
                                    ? routes.employees.list
                                    : routes.users.list,
                                  selectedLocale,
                                )}
                              />
                            }
                          />
                          <Route
                            path={getLocaleUrl(
                              routes.companies.list,
                              selectedLocale,
                            )}
                            element={<Companies />}
                          />
                          <Route
                            path={getLocaleUrl(
                              routes.companies.create,
                              selectedLocale,
                            )}
                            element={<CompanyCreate />}
                          />
                          <Route
                            path={getLocaleUrl(
                              routes.companies.edit,
                              selectedLocale,
                            )}
                            element={<CompanyEdit />}
                          />
                          <Route
                            path={getLocaleUrl(
                              routes.courses.list,
                              selectedLocale,
                            )}
                            element={<Courses />}
                          />
                          <Route
                            path={getLocaleUrl(
                              routes.courses.create,
                              selectedLocale,
                            )}
                            element={<CourseCreate />}
                          />
                          <Route
                            path={getLocaleUrl(
                              routes.courses.edit,
                              selectedLocale,
                            )}
                            element={<CourseEdit />}
                          />
                          <Route
                            path={getLocaleUrl(
                              routes.users.create,
                              selectedLocale,
                            )}
                            element={<UserCreatePage />}
                          />
                          <Route
                            path={getLocaleUrl(
                              routes.users.edit,
                              selectedLocale,
                            )}
                            element={<UserEditPage />}
                          />
                          <Route
                            path={getLocaleUrl(
                              routes.users.list,
                              selectedLocale,
                            )}
                            element={<UsersListPage />}
                          />
                          <Route
                            path={getLocaleUrl(
                              routes.translations,
                              selectedLocale,
                            )}
                            element={<TranslationsPage />}
                          />
                          <Route
                            path={getLocaleUrl(
                              routes.companyTypes.list,
                              selectedLocale,
                            )}
                            element={<CompanyTypes />}
                          />
                          <Route
                            path={getLocaleUrl(
                              routes.instructions.list,
                              selectedLocale,
                            )}
                            element={<Instructions />}
                          />
                          <Route
                            path={getLocaleUrl(
                              routes.meetings.list,
                              selectedLocale,
                            )}
                            element={<Meetings />}
                          />
                          <Route
                            path={getLocaleUrl(
                              routes.risks.list,
                              selectedLocale,
                            )}
                            element={<Risks />}
                          />
                          <Route
                            path={getLocaleUrl(
                              routes.clients.list,
                              selectedLocale,
                            )}
                            element={<Clients />}
                          />
                          <Route
                            path={getLocaleUrl(
                              routes.projects.list,
                              selectedLocale,
                            )}
                            element={<Projects />}
                          />
                          <Route
                            path={getLocaleUrl(
                              routes.workLogs.list,
                              selectedLocale,
                            )}
                            element={<WorkLogs />}
                          />
                        </>
                      )}

                      {selectedRole === Roles.USER &&
                        !!companyOptions?.length && (
                          <>
                            <Route
                              path="*"
                              element={
                                <Navigate
                                  replace
                                  to={getLocaleUrl(
                                    routes.employees.instructions,
                                    selectedLocale,
                                  )}
                                />
                              }
                            />
                            <Route
                              path={getLocaleUrl(
                                routes.employees.instructions,
                                selectedLocale,
                              )}
                              element={<EmployeeInstructions />}
                            />
                            <Route
                              path={getLocaleUrl(
                                routes.employees.actionPlans,
                                selectedLocale,
                              )}
                              element={<EmployeeMeetings />}
                            />
                            <Route
                              path={getLocaleUrl(
                                routes.employees.accidents,
                                selectedLocale,
                              )}
                              element={<EmployeeAccidents />}
                            />
                            <Route
                              path={getLocaleUrl(
                                routes.workLogs.list,
                                selectedLocale,
                              )}
                              element={<EmployeeWorkLog />}
                            />
                          </>
                        )}

                      {selectedRole !== Roles.ADMIN &&
                        !isCompanyOptionsLoading &&
                        !companyOptions?.length && (
                          <Route
                            path="*"
                            element={
                              <Navigate
                                replace
                                to={getLocaleUrl(routes.admin, selectedLocale)}
                              />
                            }
                          />
                        )}

                      {selectedRole !== Roles.ADMIN && (
                        <Route
                          path={getLocaleUrl(routes.admin, selectedLocale)}
                          element={<></>}
                        />
                      )}
                    </>
                  )}
                </Routes>
              </Layout>
            </Suspense>
          </ErrorBoundary>
        </IntlProvider>
      ) : (
        <Loader isLoading isFullScreen />
      )}
    </BrowserRouter>
  );
};

const mapStateToProps = (state: StoreState) => ({
  isInitCompleted: state.auth.isInitCompleted,
  isAuthenticated: state.auth.isAuthenticated,
  isCurrentUserLoading: state.user.currentUserLoading,
  refreshTokenLoading: state.auth.refreshTokenLoading,
  jwtToken: state.auth.jwtToken,
  lastActionAt: state.auth.lastStoreActionAt,
  language: state.language.language,
  selectedLocale: state.auth.selectedLocale,
  languages: state.language.languages,
  currentUser: state.user.currentUser,
  selectedCompany: state.company.selectedCompany,
  availableSystems: state.company.availableSystems,
  selectedSystem: state.company.selectedSystem,
  selectedRole: state.auth.selectedRole,
  companyOptions: state.company.companyOptions,
  isCompanyOptionsLoading: state.company.companyOptionsLoading,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onTryAutoSignup: () => dispatch(authService.authCheckState()),
  onFetchCurrentUser: () => dispatch(userService.fetchCurrentUser()),
  onRefreshToken: () => dispatch(authService.refreshToken()),
  onLanguageFetch: (locale: string) =>
    dispatch(languageService.fetchLanguage(locale)),
  onLanguagesInit: () => dispatch(languageService.fetchLanguages()),
  onLogout: () => dispatch(logout()),
  onSelectLocale: (locale: Locale) => dispatch(selectLocale(locale)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Router);
