import * as actionTypes from './actionTypes';
import { EmployeeActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { ListResults } from '../../common/List/List';
import { Employee } from '../../domain/Employee';

export type EmployeeStateType = {
  employeesList: ListResults<Employee> | null;
  employeesLoading: boolean;
  employeesError: HttpError;
  employeesListUpdateNeeded: boolean;
  employeeCreateLoading: boolean;
  employeeCreateError: HttpError;
  employeeCreateSuccess: boolean;
  createdEmployee: Employee | null;
  employeeUpdateLoading: boolean;
  employeeUpdateError: HttpError;
  employeeUpdateSuccess: boolean;
  employee: Employee | null;
  employeeLoading: boolean;
  employeeError: HttpError;
  employeeDeleteLoading: boolean;
  employeeDeleteError: HttpError;
  employeeDeleteSuccess: boolean;
  employeeHierarchyLoading: boolean;
  employeeHierarchySuccess: boolean;
  employeeHierarchyError: HttpError;
};

export type EmployeeActionType = EmployeeStateType & {
  type: EmployeeActionTypes;
};

export const initialState: EmployeeStateType = {
  employeesList: null,
  employeesLoading: false,
  employeesError: null,
  employeesListUpdateNeeded: false,
  employeeCreateLoading: false,
  employeeCreateError: null,
  employeeCreateSuccess: false,
  createdEmployee: null,
  employeeUpdateLoading: false,
  employeeUpdateError: null,
  employeeUpdateSuccess: false,
  employee: null,
  employeeLoading: false,
  employeeError: null,
  employeeDeleteLoading: false,
  employeeDeleteError: null,
  employeeDeleteSuccess: false,
  employeeHierarchyLoading: false,
  employeeHierarchySuccess: false,
  employeeHierarchyError: null,
};

const fetchEmployeesStart = (state: EmployeeStateType): EmployeeStateType => ({
  ...state,
  employeesLoading: true,
});

const fetchEmployeesSuccess = (
  state: EmployeeStateType,
  action: EmployeeActionType,
): EmployeeStateType => ({
  ...state,
  employeesList: action.employeesList,
  employeesLoading: false,
  employeesError: null,
  employeesListUpdateNeeded: false,
  employeeHierarchySuccess: false,
  employeeCreateSuccess: false,
});

const fetchEmployeesFail = (
  state: EmployeeStateType,
  action: EmployeeActionType,
): EmployeeStateType => ({
  ...state,
  employeesError: action.employeesError,
  employeesLoading: false,
});

const fetchEmployeeStart = (state: EmployeeStateType): EmployeeStateType => ({
  ...state,
  employeeLoading: true,
  employeeUpdateSuccess: false,
  employeeCreateSuccess: false,
  employeeCreateError: null,
  employeeUpdateError: null,
});

const fetchEmployeeSuccess = (
  state: EmployeeStateType,
  action: EmployeeActionType,
): EmployeeStateType => ({
  ...state,
  employee: action.employee,
  employeeLoading: false,
  employeeError: null,
});

const fetchEmployeeFail = (
  state: EmployeeStateType,
  action: EmployeeActionType,
): EmployeeStateType => ({
  ...state,
  employeeError: action.employeeError,
  employeeLoading: false,
});

const createEmployeeStart = (state: EmployeeStateType): EmployeeStateType => ({
  ...state,
  employeeCreateLoading: true,
});

const createEmployeeSuccess = (
  state: EmployeeStateType,
  action: EmployeeActionType,
): EmployeeStateType => ({
  ...state,
  employeeCreateLoading: false,
  employeeCreateError: null,
  employeeCreateSuccess: true,
  createdEmployee: action.createdEmployee,
  employeesList: state.employeesList
    ? {
        total: state.employeesList.total + 1,
        result: [
          ...state.employeesList.result,
          action.createdEmployee,
        ] as Employee[],
      }
    : null,
});

const createEmployeeFail = (
  state: EmployeeStateType,
  action: EmployeeActionType,
): EmployeeStateType => ({
  ...state,
  employeeCreateLoading: false,
  employeeCreateError: action.employeeCreateError,
});

const updateEmployeeStart = (state: EmployeeStateType): EmployeeStateType => ({
  ...state,
  employeeUpdateLoading: true,
});

const updateEmployeeSuccess = (
  state: EmployeeStateType,
): EmployeeStateType => ({
  ...state,
  employeeUpdateLoading: false,
  employeeUpdateError: null,
  employeeUpdateSuccess: true,
});

const updateEmployeeFail = (
  state: EmployeeStateType,
  action: EmployeeActionType,
): EmployeeStateType => ({
  ...state,
  employeeUpdateLoading: false,
  employeeUpdateError: action.employeeUpdateError,
});

const updateEmployeeHierarchyStart = (
  state: EmployeeStateType,
): EmployeeStateType => ({
  ...state,
  employeeHierarchyLoading: true,
});

const updateEmployeeHierarchySuccess = (
  state: EmployeeStateType,
): EmployeeStateType => ({
  ...state,
  employeeHierarchyLoading: false,
  employeeHierarchySuccess: true,
  employeeHierarchyError: null,
});

const updateEmployeeHierarchyFail = (
  state: EmployeeStateType,
  action: EmployeeActionType,
): EmployeeStateType => ({
  ...state,
  employeeHierarchyLoading: false,
  employeeHierarchyError: action.employeeHierarchyError,
});

const deleteEmployeeStart = (state: EmployeeStateType): EmployeeStateType => ({
  ...state,
  employeeDeleteLoading: true,
});

const deleteEmployeeSuccess = (
  state: EmployeeStateType,
): EmployeeStateType => ({
  ...state,
  employeeDeleteLoading: false,
  employeeDeleteError: null,
  employeeDeleteSuccess: true,
  employeesListUpdateNeeded: true,
});

const deleteEmployeeFail = (
  state: EmployeeStateType,
  action: EmployeeActionType,
): EmployeeStateType => ({
  ...state,
  employeeDeleteLoading: false,
  employeeDeleteError: action.employeeDeleteError,
});

const resetCreatedEmployee = (state: EmployeeStateType): EmployeeStateType => ({
  ...state,
  createdEmployee: null,
});

const resetEmployeeStore = (): EmployeeStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: EmployeeActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_EMPLOYEES_START:
      return fetchEmployeesStart(state);
    case actionTypes.FETCH_EMPLOYEES_SUCCESS:
      return fetchEmployeesSuccess(state, action);
    case actionTypes.FETCH_EMPLOYEES_FAIL:
      return fetchEmployeesFail(state, action);
    case actionTypes.FETCH_EMPLOYEE_START:
      return fetchEmployeeStart(state);
    case actionTypes.FETCH_EMPLOYEE_SUCCESS:
      return fetchEmployeeSuccess(state, action);
    case actionTypes.FETCH_EMPLOYEE_FAIL:
      return fetchEmployeeFail(state, action);
    case actionTypes.CREATE_EMPLOYEE_START:
      return createEmployeeStart(state);
    case actionTypes.CREATE_EMPLOYEE_SUCCESS:
      return createEmployeeSuccess(state, action);
    case actionTypes.CREATE_EMPLOYEE_FAIL:
      return createEmployeeFail(state, action);
    case actionTypes.UPDATE_EMPLOYEE_START:
      return updateEmployeeStart(state);
    case actionTypes.UPDATE_EMPLOYEE_SUCCESS:
      return updateEmployeeSuccess(state);
    case actionTypes.UPDATE_EMPLOYEE_FAIL:
      return updateEmployeeFail(state, action);
    case actionTypes.DELETE_EMPLOYEE_START:
      return deleteEmployeeStart(state);
    case actionTypes.DELETE_EMPLOYEE_SUCCESS:
      return deleteEmployeeSuccess(state);
    case actionTypes.DELETE_EMPLOYEE_FAIL:
      return deleteEmployeeFail(state, action);
    case actionTypes.UPDATE_EMPLOYEE_HIERARCHY_START:
      return updateEmployeeHierarchyStart(state);
    case actionTypes.UPDATE_EMPLOYEE_HIERARCHY_SUCCESS:
      return updateEmployeeHierarchySuccess(state);
    case actionTypes.UPDATE_EMPLOYEE_HIERARCHY_FAIL:
      return updateEmployeeHierarchyFail(state, action);
    case actionTypes.RESET_EMPLOYEE_STORE:
      return resetEmployeeStore();
    case actionTypes.RESET_CREATED_EMPLOYEE:
      return resetCreatedEmployee(state);
    default:
      return state;
  }
};

export default reducer;
