import * as actionTypes from './actionTypes';
import { ClientActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { ListResults } from '../../common/List/List';
import { Client } from '../../domain/Client';

export type ClientStateType = {
  clientsList: ListResults<Client> | null;
  clientsLoading: boolean;
  clientsError: HttpError;
  clientsListUpdateNeeded: boolean;
  clientCreateLoading: boolean;
  clientCreateError: HttpError;
  clientCreateSuccess: boolean;
  createdClient: Client | null;
  clientUpdateLoading: boolean;
  clientUpdateError: HttpError;
  clientUpdateSuccess: boolean;
  client: Client | null;
  clientLoading: boolean;
  clientError: HttpError;
  clientDeleteLoading: boolean;
  clientDeleteError: HttpError;
  clientDeleteSuccess: boolean;
};

export type ClientActionType = ClientStateType & {
  type: ClientActionTypes;
};

export const initialState: ClientStateType = {
  clientsList: null,
  clientsLoading: false,
  clientsError: null,
  clientsListUpdateNeeded: false,
  clientCreateLoading: false,
  clientCreateError: null,
  clientCreateSuccess: false,
  createdClient: null,
  clientUpdateLoading: false,
  clientUpdateError: null,
  clientUpdateSuccess: false,
  client: null,
  clientLoading: false,
  clientError: null,
  clientDeleteLoading: false,
  clientDeleteError: null,
  clientDeleteSuccess: false,
};

const fetchClientsStart = (state: ClientStateType): ClientStateType => ({
  ...state,
  clientsLoading: true,
});

const fetchClientsSuccess = (
  state: ClientStateType,
  action: ClientActionType,
): ClientStateType => ({
  ...state,
  clientsList: action.clientsList,
  clientsLoading: false,
  clientsError: null,
  clientsListUpdateNeeded: false,
  clientCreateSuccess: false,
  clientUpdateSuccess: false,
  clientDeleteSuccess: false,
});

const fetchClientsFail = (
  state: ClientStateType,
  action: ClientActionType,
): ClientStateType => ({
  ...state,
  clientsError: action.clientsError,
  clientsLoading: false,
});

const fetchClientStart = (state: ClientStateType): ClientStateType => ({
  ...state,
  clientLoading: true,
  clientUpdateSuccess: false,
  clientCreateSuccess: false,
  clientCreateError: null,
  clientUpdateError: null,
});

const fetchClientSuccess = (
  state: ClientStateType,
  action: ClientActionType,
): ClientStateType => ({
  ...state,
  client: action.client,
  clientLoading: false,
  clientError: null,
});

const fetchClientFail = (
  state: ClientStateType,
  action: ClientActionType,
): ClientStateType => ({
  ...state,
  clientError: action.clientError,
  clientLoading: false,
});

const createClientStart = (state: ClientStateType): ClientStateType => ({
  ...state,
  clientCreateLoading: true,
});

const createClientSuccess = (
  state: ClientStateType,
  action: ClientActionType,
): ClientStateType => ({
  ...state,
  clientCreateLoading: false,
  clientCreateError: null,
  clientCreateSuccess: true,
  createdClient: action.createdClient,
  clientsListUpdateNeeded: true,
  clientsList: state.clientsList
    ? {
        total: state.clientsList.total + 1,
        result: [...state.clientsList.result, action.createdClient] as Client[],
      }
    : null,
});

const createClientFail = (
  state: ClientStateType,
  action: ClientActionType,
): ClientStateType => ({
  ...state,
  clientCreateLoading: false,
  clientCreateError: action.clientCreateError,
});

const updateClientStart = (state: ClientStateType): ClientStateType => ({
  ...state,
  clientUpdateLoading: true,
});

const updateClientSuccess = (state: ClientStateType): ClientStateType => ({
  ...state,
  clientUpdateLoading: false,
  clientUpdateError: null,
  clientUpdateSuccess: true,
  clientsListUpdateNeeded: true,
});

const updateClientFail = (
  state: ClientStateType,
  action: ClientActionType,
): ClientStateType => ({
  ...state,
  clientUpdateLoading: false,
  clientUpdateError: action.clientUpdateError,
});

const deleteClientStart = (state: ClientStateType): ClientStateType => ({
  ...state,
  clientDeleteLoading: true,
});

const deleteClientSuccess = (state: ClientStateType): ClientStateType => ({
  ...state,
  clientDeleteLoading: false,
  clientDeleteError: null,
  clientDeleteSuccess: true,
  clientsListUpdateNeeded: true,
});

const deleteClientFail = (
  state: ClientStateType,
  action: ClientActionType,
): ClientStateType => ({
  ...state,
  clientDeleteLoading: false,
  clientDeleteError: action.clientDeleteError,
});

const resetCreatedClient = (state: ClientStateType): ClientStateType => ({
  ...state,
  createdClient: null,
});

const resetClientStore = (): ClientStateType => ({
  clientsList: null,
  clientsLoading: true,
  clientsError: null,
  clientsListUpdateNeeded: false,
  clientCreateLoading: false,
  clientCreateError: null,
  clientCreateSuccess: false,
  createdClient: null,
  clientUpdateLoading: false,
  clientUpdateError: null,
  clientUpdateSuccess: false,
  client: null,
  clientLoading: false,
  clientError: null,
  clientDeleteLoading: false,
  clientDeleteError: null,
  clientDeleteSuccess: false,
});

const reducer = (state = initialState, action: ClientActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_CLIENTS_START:
      return fetchClientsStart(state);
    case actionTypes.FETCH_CLIENTS_SUCCESS:
      return fetchClientsSuccess(state, action);
    case actionTypes.FETCH_CLIENTS_FAIL:
      return fetchClientsFail(state, action);
    case actionTypes.FETCH_CLIENT_START:
      return fetchClientStart(state);
    case actionTypes.FETCH_CLIENT_SUCCESS:
      return fetchClientSuccess(state, action);
    case actionTypes.FETCH_CLIENT_FAIL:
      return fetchClientFail(state, action);
    case actionTypes.CREATE_CLIENT_START:
      return createClientStart(state);
    case actionTypes.CREATE_CLIENT_SUCCESS:
      return createClientSuccess(state, action);
    case actionTypes.CREATE_CLIENT_FAIL:
      return createClientFail(state, action);
    case actionTypes.UPDATE_CLIENT_START:
      return updateClientStart(state);
    case actionTypes.UPDATE_CLIENT_SUCCESS:
      return updateClientSuccess(state);
    case actionTypes.UPDATE_CLIENT_FAIL:
      return updateClientFail(state, action);
    case actionTypes.DELETE_CLIENT_START:
      return deleteClientStart(state);
    case actionTypes.DELETE_CLIENT_SUCCESS:
      return deleteClientSuccess(state);
    case actionTypes.DELETE_CLIENT_FAIL:
      return deleteClientFail(state, action);
    case actionTypes.RESET_CLIENT_STORE:
      return resetClientStore();
    case actionTypes.RESET_CREATED_CLIENT:
      return resetCreatedClient(state);
    default:
      return state;
  }
};

export default reducer;
