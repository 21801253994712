export const CREATE_RISK_START = 'CREATE_RISK_START';
export const CREATE_RISK_SUCCESS = 'CREATE_RISK_SUCCESS';
export const CREATE_RISK_FAIL = 'CREATE_RISK_FAIL';

export const UPDATE_RISK_START = 'UPDATE_RISK_START';
export const UPDATE_RISK_SUCCESS = 'UPDATE_RISK_SUCCESS';
export const UPDATE_RISK_FAIL = 'UPDATE_RISK_FAIL';

export const DELETE_RISK_START = 'DELETE_RISK_START';
export const DELETE_RISK_SUCCESS = 'DELETE_RISK_SUCCESS';
export const DELETE_RISK_FAIL = 'DELETE_RISK_FAIL';

export const RESET_RISK_STORE = 'RESET_RISK_STORE';

export type RiskActionTypes =
  | typeof CREATE_RISK_START
  | typeof CREATE_RISK_SUCCESS
  | typeof CREATE_RISK_FAIL
  | typeof UPDATE_RISK_START
  | typeof UPDATE_RISK_SUCCESS
  | typeof UPDATE_RISK_FAIL
  | typeof DELETE_RISK_START
  | typeof DELETE_RISK_SUCCESS
  | typeof DELETE_RISK_FAIL
  | typeof RESET_RISK_STORE;
