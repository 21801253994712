import * as actionTypes from './actionTypes';
import { AccidentActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { ListResults } from '../../common/List/List';
import { Accident } from '../../domain/Accident';

export type AccidentStateType = {
  accidentsList: ListResults<Accident> | null;
  accidentsLoading: boolean;
  accidentsError: HttpError;
  accidentsListUpdateNeeded: boolean;
  accidentCreateLoading: boolean;
  accidentCreateError: HttpError;
  accidentUpdateLoading: boolean;
  accidentUpdateError: HttpError;
  accidentDeleteLoading: boolean;
  accidentDeleteError: HttpError;
};

export type AccidentActionType = AccidentStateType & {
  type: AccidentActionTypes;
};

export const initialState: AccidentStateType = {
  accidentsList: null,
  accidentsLoading: false,
  accidentsListUpdateNeeded: false,
  accidentsError: null,
  accidentCreateLoading: false,
  accidentCreateError: null,
  accidentUpdateLoading: false,
  accidentUpdateError: null,
  accidentDeleteLoading: false,
  accidentDeleteError: null,
};

const fetchAccidentsStart = (state: AccidentStateType): AccidentStateType => ({
  ...state,
  accidentsLoading: true,
});

const fetchAccidentsSuccess = (
  state: AccidentStateType,
  action: AccidentActionType,
): AccidentStateType => ({
  ...state,
  accidentsList: action.accidentsList,
  accidentsLoading: false,
  accidentsError: null,
  accidentsListUpdateNeeded: false,
});

const fetchAccidentsFail = (
  state: AccidentStateType,
  action: AccidentActionType,
): AccidentStateType => ({
  ...state,
  accidentsError: action.accidentsError,
  accidentsLoading: false,
});

const createAccidentStart = (state: AccidentStateType): AccidentStateType => ({
  ...state,
  accidentCreateLoading: true,
});

const createAccidentSuccess = (
  state: AccidentStateType,
): AccidentStateType => ({
  ...state,
  accidentCreateLoading: false,
  accidentCreateError: null,
  accidentsListUpdateNeeded: true,
});

const createAccidentFail = (
  state: AccidentStateType,
  action: AccidentActionType,
): AccidentStateType => ({
  ...state,
  accidentCreateLoading: false,
  accidentCreateError: action.accidentCreateError,
});

const updateAccidentStart = (state: AccidentStateType): AccidentStateType => ({
  ...state,
  accidentUpdateLoading: true,
});

const updateAccidentSuccess = (
  state: AccidentStateType,
): AccidentStateType => ({
  ...state,
  accidentUpdateLoading: false,
  accidentUpdateError: null,
  accidentsListUpdateNeeded: true,
});

const updateAccidentFail = (
  state: AccidentStateType,
  action: AccidentActionType,
): AccidentStateType => ({
  ...state,
  accidentUpdateLoading: false,
  accidentUpdateError: action.accidentUpdateError,
});

const deleteAccidentStart = (state: AccidentStateType): AccidentStateType => ({
  ...state,
  accidentDeleteLoading: true,
});

const deleteAccidentSuccess = (
  state: AccidentStateType,
): AccidentStateType => ({
  ...state,
  accidentDeleteLoading: false,
  accidentDeleteError: null,
  accidentsListUpdateNeeded: true,
});

const deleteAccidentFail = (
  state: AccidentStateType,
  action: AccidentActionType,
): AccidentStateType => ({
  ...state,
  accidentDeleteLoading: false,
  accidentDeleteError: action.accidentDeleteError,
});

const resetAccidentStore = (): AccidentStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: AccidentActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_ACCIDENTS_START:
      return fetchAccidentsStart(state);
    case actionTypes.FETCH_ACCIDENTS_SUCCESS:
      return fetchAccidentsSuccess(state, action);
    case actionTypes.FETCH_ACCIDENTS_FAIL:
      return fetchAccidentsFail(state, action);
    case actionTypes.CREATE_ACCIDENT_START:
      return createAccidentStart(state);
    case actionTypes.CREATE_ACCIDENT_SUCCESS:
      return createAccidentSuccess(state);
    case actionTypes.CREATE_ACCIDENT_FAIL:
      return createAccidentFail(state, action);
    case actionTypes.UPDATE_ACCIDENT_START:
      return updateAccidentStart(state);
    case actionTypes.UPDATE_ACCIDENT_SUCCESS:
      return updateAccidentSuccess(state);
    case actionTypes.UPDATE_ACCIDENT_FAIL:
      return updateAccidentFail(state, action);
    case actionTypes.DELETE_ACCIDENT_START:
      return deleteAccidentStart(state);
    case actionTypes.DELETE_ACCIDENT_SUCCESS:
      return deleteAccidentSuccess(state);
    case actionTypes.DELETE_ACCIDENT_FAIL:
      return deleteAccidentFail(state, action);
    case actionTypes.RESET_ACCIDENT_STORE:
      return resetAccidentStore();
    default:
      return state;
  }
};

export default reducer;
