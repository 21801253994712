export const CREATE_INSTRUCTION_START = 'CREATE_INSTRUCTION_START';
export const CREATE_INSTRUCTION_SUCCESS = 'CREATE_INSTRUCTION_SUCCESS';
export const CREATE_INSTRUCTION_FAIL = 'CREATE_INSTRUCTION_FAIL';

export const UPDATE_INSTRUCTION_START = 'UPDATE_INSTRUCTION_START';
export const UPDATE_INSTRUCTION_SUCCESS = 'UPDATE_INSTRUCTION_SUCCESS';
export const UPDATE_INSTRUCTION_FAIL = 'UPDATE_INSTRUCTION_FAIL';

export const DELETE_INSTRUCTION_START = 'DELETE_INSTRUCTION_START';
export const DELETE_INSTRUCTION_SUCCESS = 'DELETE_INSTRUCTION_SUCCESS';
export const DELETE_INSTRUCTION_FAIL = 'DELETE_INSTRUCTION_FAIL';

export const RESET_INSTRUCTION_STORE = 'RESET_INSTRUCTION_STORE';

export type InstructionActionTypes =
  | typeof CREATE_INSTRUCTION_START
  | typeof CREATE_INSTRUCTION_SUCCESS
  | typeof CREATE_INSTRUCTION_FAIL
  | typeof UPDATE_INSTRUCTION_START
  | typeof UPDATE_INSTRUCTION_SUCCESS
  | typeof UPDATE_INSTRUCTION_FAIL
  | typeof DELETE_INSTRUCTION_START
  | typeof DELETE_INSTRUCTION_SUCCESS
  | typeof DELETE_INSTRUCTION_FAIL
  | typeof RESET_INSTRUCTION_STORE;
