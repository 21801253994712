export const FETCH_COMPANY_MEETINGS_START = 'FETCH_COMPANY_MEETINGS_START';
export const FETCH_COMPANY_MEETINGS_SUCCESS = 'FETCH_COMPANY_MEETINGS_SUCCESS';
export const FETCH_COMPANY_MEETINGS_FAIL = 'FETCH_COMPANY_MEETINGS_FAIL';

export const FETCH_COMPANY_MEETING_START = 'FETCH_COMPANY_MEETING_START';
export const FETCH_COMPANY_MEETING_SUCCESS = 'FETCH_COMPANY_MEETING_SUCCESS';
export const FETCH_COMPANY_MEETING_FAIL = 'FETCH_COMPANY_MEETING_FAIL';

export const CREATE_COMPANY_MEETING_START = 'CREATE_COMPANY_MEETING_START';
export const CREATE_COMPANY_MEETING_SUCCESS = 'CREATE_COMPANY_MEETING_SUCCESS';
export const CREATE_COMPANY_MEETING_FAIL = 'CREATE_COMPANY_MEETING_FAIL';

export const UPDATE_COMPANY_MEETING_START = 'UPDATE_COMPANY_MEETING_START';
export const UPDATE_COMPANY_MEETING_SUCCESS = 'UPDATE_COMPANY_MEETING_SUCCESS';
export const UPDATE_COMPANY_MEETING_FAIL = 'UPDATE_COMPANY_MEETING_FAIL';

export const DELETE_COMPANY_MEETING_START = 'DELETE_COMPANY_MEETING_START';
export const DELETE_COMPANY_MEETING_SUCCESS = 'DELETE_COMPANY_MEETING_SUCCESS';
export const DELETE_COMPANY_MEETING_FAIL = 'DELETE_COMPANY_MEETING_FAIL';

export const CREATE_COMPANY_MEETING_PROTOCOL_START =
  'CREATE_COMPANY_MEETING_PROTOCOL_START';
export const CREATE_COMPANY_MEETING_PROTOCOL_SUCCESS =
  'CREATE_COMPANY_MEETING_PROTOCOL_SUCCESS';
export const CREATE_COMPANY_MEETING_PROTOCOL_FAIL =
  'CREATE_COMPANY_MEETING_PROTOCOL_FAIL';

export const UPDATE_COMPANY_MEETING_PROTOCOL_START =
  'UPDATE_COMPANY_MEETING_PROTOCOL_START';
export const UPDATE_COMPANY_MEETING_PROTOCOL_SUCCESS =
  'UPDATE_COMPANY_MEETING_PROTOCOL_SUCCESS';
export const UPDATE_COMPANY_MEETING_PROTOCOL_FAIL =
  'UPDATE_COMPANY_MEETING_PROTOCOL_FAIL';

export const FETCH_SIGNED_EMPLOYEES_START = 'FETCH_SIGNED_EMPLOYEES_START';
export const FETCH_SIGNED_EMPLOYEES_SUCCESS = 'FETCH_SIGNED_EMPLOYEES_SUCCESS';
export const FETCH_SIGNED_EMPLOYEES_FAIL = 'FETCH_SIGNED_EMPLOYEES_FAIL';

export const ASK_TO_SIGN_START = 'ASK_TO_SIGN_START';
export const ASK_TO_SIGN_SUCCESS = 'ASK_TO_SIGN_SUCCESS';
export const ASK_TO_SIGN_FAIL = 'ASK_TO_SIGN_FAIL';

export const RESET_COMPANY_MEETING_STORE = 'RESET_COMPANY_MEETING_STORE';

export type CompanyMeetingActionTypes =
  | typeof FETCH_COMPANY_MEETINGS_START
  | typeof FETCH_COMPANY_MEETINGS_SUCCESS
  | typeof FETCH_COMPANY_MEETINGS_FAIL
  | typeof FETCH_COMPANY_MEETING_START
  | typeof FETCH_COMPANY_MEETING_SUCCESS
  | typeof FETCH_COMPANY_MEETING_FAIL
  | typeof CREATE_COMPANY_MEETING_START
  | typeof CREATE_COMPANY_MEETING_SUCCESS
  | typeof CREATE_COMPANY_MEETING_FAIL
  | typeof UPDATE_COMPANY_MEETING_START
  | typeof UPDATE_COMPANY_MEETING_SUCCESS
  | typeof UPDATE_COMPANY_MEETING_FAIL
  | typeof DELETE_COMPANY_MEETING_START
  | typeof DELETE_COMPANY_MEETING_SUCCESS
  | typeof DELETE_COMPANY_MEETING_FAIL
  | typeof CREATE_COMPANY_MEETING_PROTOCOL_START
  | typeof CREATE_COMPANY_MEETING_PROTOCOL_SUCCESS
  | typeof CREATE_COMPANY_MEETING_PROTOCOL_FAIL
  | typeof UPDATE_COMPANY_MEETING_PROTOCOL_START
  | typeof UPDATE_COMPANY_MEETING_PROTOCOL_SUCCESS
  | typeof UPDATE_COMPANY_MEETING_PROTOCOL_FAIL
  | typeof ASK_TO_SIGN_START
  | typeof ASK_TO_SIGN_SUCCESS
  | typeof ASK_TO_SIGN_FAIL
  | typeof FETCH_SIGNED_EMPLOYEES_START
  | typeof FETCH_SIGNED_EMPLOYEES_SUCCESS
  | typeof FETCH_SIGNED_EMPLOYEES_FAIL
  | typeof RESET_COMPANY_MEETING_STORE;
