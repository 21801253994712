import * as actionTypes from './actionTypes';
import { AuthActionTypes } from './actionTypes';
import moment from 'moment';
import { HttpError } from '../../config/Axios/axios-instance';
import { Locale } from '../../domain/Translation';
import { DEFAULT_LOCALE } from '../../config/constants';
import { User } from '../../domain/User';
import { Roles } from '../../domain/Role';

export type AuthStateType = {
  jwtToken: string | null;
  isAuthenticated: boolean;
  isInitCompleted: boolean;
  loginLoading: boolean;
  loginError: HttpError;
  registrationLoading: boolean;
  registrationError: HttpError;
  registrationSuccess: boolean;
  lastStoreActionAt: moment.Moment | null;
  refreshTokenLoading: boolean;
  refreshTokenError: HttpError;
  selectedLocale: Locale;
  remindLoading: boolean;
  remindError: HttpError;
  isRemindSuccess: boolean;
  resetPasswordLoading: boolean;
  resetPasswordError: HttpError;
  validateCodeLoading: boolean;
  validateCodeError: HttpError;
  validatedUser: User | null;
  registerConfirmLoading: boolean;
  registerConfirmError: HttpError;
  validateInvitationCodeLoading: boolean;
  validateInvitationCodeError: HttpError;
  confirmInvitationLoading: boolean;
  confirmInvitationError: HttpError;
  confirmInvitationSuccess: boolean;
  selectedRole: Roles | null;
};

export type AuthActionType = AuthStateType & {
  type: AuthActionTypes;
};

export const initialState: AuthStateType = {
  isAuthenticated: false,
  isInitCompleted: false,
  loginLoading: false,
  loginError: null,
  registrationLoading: false,
  registrationError: null,
  registrationSuccess: false,
  lastStoreActionAt: null,
  refreshTokenLoading: false,
  refreshTokenError: null,
  remindLoading: false,
  remindError: null,
  isRemindSuccess: false,
  resetPasswordLoading: false,
  resetPasswordError: null,
  validateCodeLoading: false,
  validateCodeError: null,
  validatedUser: null,
  registerConfirmLoading: false,
  registerConfirmError: null,
  validateInvitationCodeError: null,
  validateInvitationCodeLoading: false,
  confirmInvitationError: null,
  confirmInvitationLoading: false,
  confirmInvitationSuccess: false,
  jwtToken: localStorage.getItem('token'),
  selectedLocale: DEFAULT_LOCALE as Locale,
  selectedRole: null,
};

const loginStart = (state: AuthStateType): AuthStateType => ({
  ...state,
  loginLoading: true,
});

const loginSuccess = (
  state: AuthStateType,
  action: AuthActionType,
): AuthStateType => ({
  ...state,
  isAuthenticated: true,
  loginError: null,
  loginLoading: false,
  jwtToken: action.jwtToken,
});

const loginFail = (
  state: AuthStateType,
  action: AuthActionType,
): AuthStateType => ({
  ...state,
  loginError: action.loginError,
  loginLoading: false,
});

const registrationStart = (state: AuthStateType): AuthStateType => ({
  ...state,
  registrationLoading: true,
});

const registrationSuccess = (state: AuthStateType): AuthStateType => ({
  ...state,
  registrationError: null,
  registrationLoading: false,
  registrationSuccess: true,
});

const registrationFail = (
  state: AuthStateType,
  action: AuthActionType,
): AuthStateType => ({
  ...state,
  registrationError: action.registrationError,
  registrationLoading: false,
});

const resetRegistration = (state: AuthStateType): AuthStateType => ({
  ...state,
  registrationError: null,
  registrationLoading: false,
  registrationSuccess: false,
  confirmInvitationLoading: false,
  confirmInvitationError: null,
});

const remindStart = (state: AuthStateType): AuthStateType => ({
  ...state,
  remindLoading: true,
  validatedUser: null,
  validateCodeError: null,
});

const remindSuccess = (state: AuthStateType): AuthStateType => ({
  ...state,
  remindError: null,
  remindLoading: false,
  isRemindSuccess: true,
});

const remindFail = (
  state: AuthStateType,
  action: AuthActionType,
): AuthStateType => ({
  ...state,
  remindError: action.remindError,
  remindLoading: false,
});

const resetPasswordStart = (state: AuthStateType): AuthStateType => ({
  ...state,
  resetPasswordLoading: true,
});

const resetPasswordSuccess = (state: AuthStateType): AuthStateType => ({
  ...state,
  isAuthenticated: true,
  isInitCompleted: true,
  resetPasswordError: null,
  resetPasswordLoading: false,
});

const resetPasswordFail = (
  state: AuthStateType,
  action: AuthActionType,
): AuthStateType => ({
  ...state,
  resetPasswordError: action.resetPasswordError,
  resetPasswordLoading: false,
  isInitCompleted: true,
});

const validateCodeStart = (state: AuthStateType): AuthStateType => ({
  ...state,
  validateCodeLoading: true,
});

const validateCodeSuccess = (
  state: AuthStateType,
  action: AuthActionType,
): AuthStateType => ({
  ...state,
  validateCodeError: null,
  validateCodeLoading: false,
  validatedUser: action.validatedUser,
});

const validateCodeFail = (
  state: AuthStateType,
  action: AuthActionType,
): AuthStateType => ({
  ...state,
  validateCodeError: action.validateCodeError,
  validateCodeLoading: false,
});

const validateInvitationCodeStart = (state: AuthStateType): AuthStateType => ({
  ...state,
  validateInvitationCodeLoading: true,
});

const validateInvitationCodeSuccess = (
  state: AuthStateType,
  action: AuthActionType,
): AuthStateType => ({
  ...state,
  validateInvitationCodeError: null,
  validateInvitationCodeLoading: false,
  validatedUser: action.validatedUser,
});

const validateInvitationCodeFail = (
  state: AuthStateType,
  action: AuthActionType,
): AuthStateType => ({
  ...state,
  validateInvitationCodeError: action.validateInvitationCodeError,
  validateInvitationCodeLoading: false,
});

const confirmInvitationCodeStart = (state: AuthStateType): AuthStateType => ({
  ...state,
  confirmInvitationLoading: true,
});

const confirmInvitationCodeSuccess = (
  state: AuthStateType,
  action: AuthActionType,
): AuthStateType => ({
  ...state,
  confirmInvitationError: null,
  confirmInvitationLoading: false,
  confirmInvitationSuccess: true,
  isAuthenticated: true,
  jwtToken: action.jwtToken,
});

const confirmInvitationCodeFail = (
  state: AuthStateType,
  action: AuthActionType,
): AuthStateType => ({
  ...state,
  confirmInvitationError: action.confirmInvitationError,
  confirmInvitationLoading: false,
});

const registerConfirmStart = (state: AuthStateType): AuthStateType => ({
  ...state,
  registerConfirmLoading: true,
});

const registerConfirmSuccess = (
  state: AuthStateType,
  action: AuthActionType,
): AuthStateType => ({
  ...state,
  isAuthenticated: true,
  isInitCompleted: true,
  registerConfirmError: null,
  registerConfirmLoading: false,
  jwtToken: action.jwtToken,
});

const registerConfirmFail = (
  state: AuthStateType,
  action: AuthActionType,
): AuthStateType => ({
  ...state,
  registerConfirmError: action.registerConfirmError,
  registerConfirmLoading: false,
  isInitCompleted: true,
});

const refreshTokenStart = (state: AuthStateType): AuthStateType => ({
  ...state,
  refreshTokenLoading: true,
});

const refreshTokenSuccess = (
  state: AuthStateType,
  action: AuthActionType,
): AuthStateType => ({
  ...state,
  refreshTokenLoading: false,
  refreshTokenError: null,
  isInitCompleted: true,
  isAuthenticated: true,
  jwtToken: action.jwtToken,
});

const refreshTokenFail = (
  state: AuthStateType,
  action: AuthActionType,
): AuthStateType => ({
  ...state,
  refreshTokenError: action.refreshTokenError,
  refreshTokenLoading: false,
});

const logout = (state: AuthStateType): AuthStateType => ({
  ...state,
  jwtToken: null,
  isAuthenticated: false,
  lastStoreActionAt: null,
  refreshTokenError: null,
  refreshTokenLoading: false,
  isInitCompleted: true,
  loginError: null,
  loginLoading: false,
  selectedRole: null,
});

const selectLocale = (
  state: AuthStateType,
  action: AuthActionType,
): AuthStateType => ({
  ...state,
  selectedLocale: action.selectedLocale,
});

const selectRole = (
  state: AuthStateType,
  action: AuthActionType,
): AuthStateType => ({
  ...state,
  selectedRole: action.selectedRole,
});

const updateLastAction = (state: AuthStateType): AuthStateType => ({
  ...state,
  lastStoreActionAt: moment(),
});

const reducer = (state = initialState, action: AuthActionType) => {
  switch (action.type) {
    case actionTypes.LOGIN_START:
      return loginStart(state);
    case actionTypes.LOGIN_SUCCESS:
      return loginSuccess(state, action);
    case actionTypes.LOGIN_FAIL:
      return loginFail(state, action);
    case actionTypes.REGISTRATION_START:
      return registrationStart(state);
    case actionTypes.REGISTRATION_SUCCESS:
      return registrationSuccess(state);
    case actionTypes.REGISTRATION_FAIL:
      return registrationFail(state, action);
    case actionTypes.RESET_REGISTRATION:
      return resetRegistration(state);
    case actionTypes.REFRESH_TOKEN_START:
      return refreshTokenStart(state);
    case actionTypes.REFRESH_TOKEN_SUCCESS:
      return refreshTokenSuccess(state, action);
    case actionTypes.REFRESH_TOKEN_FAIL:
      return refreshTokenFail(state, action);
    case actionTypes.SELECT_LOCALE:
      return selectLocale(state, action);
    case actionTypes.REMIND_START:
      return remindStart(state);
    case actionTypes.REMIND_SUCCESS:
      return remindSuccess(state);
    case actionTypes.REMIND_FAIL:
      return remindFail(state, action);
    case actionTypes.RESET_PASSWORD_START:
      return resetPasswordStart(state);
    case actionTypes.RESET_PASSWORD_SUCCESS:
      return resetPasswordSuccess(state);
    case actionTypes.RESET_PASSWORD_FAIL:
      return resetPasswordFail(state, action);
    case actionTypes.VALIDATE_CODE_START:
      return validateCodeStart(state);
    case actionTypes.VALIDATE_CODE_SUCCESS:
      return validateCodeSuccess(state, action);
    case actionTypes.VALIDATE_CODE_FAIL:
      return validateCodeFail(state, action);
    case actionTypes.REGISTER_CONFIRM_START:
      return registerConfirmStart(state);
    case actionTypes.REGISTER_CONFIRM_SUCCESS:
      return registerConfirmSuccess(state, action);
    case actionTypes.VALIDATE_INVITATION_CODE_START:
      return validateInvitationCodeStart(state);
    case actionTypes.VALIDATE_INVITATION_CODE_SUCCESS:
      return validateInvitationCodeSuccess(state, action);
    case actionTypes.VALIDATE_INVITATION_CODE_FAIL:
      return validateInvitationCodeFail(state, action);
    case actionTypes.CONFIRM_INVITATION_START:
      return confirmInvitationCodeStart(state);
    case actionTypes.CONFIRM_INVITATION_SUCCESS:
      return confirmInvitationCodeSuccess(state, action);
    case actionTypes.CONFIRM_INVITATION_FAIL:
      return confirmInvitationCodeFail(state, action);
    case actionTypes.REGISTER_CONFIRM_FAIL:
      return registerConfirmFail(state, action);
    case actionTypes.SELECT_ROLE:
      return selectRole(state, action);
    case actionTypes.LOGOUT:
      return logout(state);
    default:
      return updateLastAction(state);
  }
};

export default reducer;
