export const FETCH_WORK_LOGS_START = 'FETCH_WORK_LOGS_START';
export const FETCH_WORK_LOGS_SUCCESS = 'FETCH_WORK_LOGS_SUCCESS';
export const FETCH_WORK_LOGS_FAIL = 'FETCH_WORK_LOGS_FAIL';

export const FETCH_WORK_LOG_START = 'FETCH_WORK_LOG_START';
export const FETCH_WORK_LOG_SUCCESS = 'FETCH_WORK_LOG_SUCCESS';
export const FETCH_WORK_LOG_FAIL = 'FETCH_WORK_LOG_FAIL';

export const CREATE_WORK_LOG_START = 'CREATE_WORK_LOG_START';
export const CREATE_WORK_LOG_SUCCESS = 'CREATE_WORK_LOG_SUCCESS';
export const CREATE_WORK_LOG_FAIL = 'CREATE_WORK_LOG_FAIL';

export const UPDATE_WORK_LOG_START = 'UPDATE_WORK_LOG_START';
export const UPDATE_WORK_LOG_SUCCESS = 'UPDATE_WORK_LOG_SUCCESS';
export const UPDATE_WORK_LOG_FAIL = 'UPDATE_WORK_LOG_FAIL';

export const DELETE_WORK_LOG_START = 'DELETE_WORK_LOG_START';
export const DELETE_WORK_LOG_SUCCESS = 'DELETE_WORK_LOG_SUCCESS';
export const DELETE_WORK_LOG_FAIL = 'DELETE_WORK_LOG_FAIL';

export const RESET_WORK_LOG_STORE = 'RESET_WORK_LOG_STORE';
export const RESET_CREATED_WORK_LOG = 'RESET_CREATED_WORK_LOG';

export type WorkLogActionTypes =
  | typeof FETCH_WORK_LOGS_START
  | typeof FETCH_WORK_LOGS_SUCCESS
  | typeof FETCH_WORK_LOGS_FAIL
  | typeof FETCH_WORK_LOG_START
  | typeof FETCH_WORK_LOG_SUCCESS
  | typeof FETCH_WORK_LOG_FAIL
  | typeof CREATE_WORK_LOG_START
  | typeof CREATE_WORK_LOG_SUCCESS
  | typeof CREATE_WORK_LOG_FAIL
  | typeof UPDATE_WORK_LOG_START
  | typeof UPDATE_WORK_LOG_SUCCESS
  | typeof UPDATE_WORK_LOG_FAIL
  | typeof RESET_WORK_LOG_STORE
  | typeof RESET_CREATED_WORK_LOG
  | typeof DELETE_WORK_LOG_START
  | typeof DELETE_WORK_LOG_SUCCESS
  | typeof DELETE_WORK_LOG_FAIL;
