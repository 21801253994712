export const CREATE_COMPANY_RISK_START = 'CREATE_COMPANY_RISK_START';
export const CREATE_COMPANY_RISK_SUCCESS = 'CREATE_COMPANY_RISK_SUCCESS';
export const CREATE_COMPANY_RISK_FAIL = 'CREATE_COMPANY_RISK_FAIL';

export const UPDATE_COMPANY_RISK_START = 'UPDATE_COMPANY_RISK_START';
export const UPDATE_COMPANY_RISK_SUCCESS = 'UPDATE_COMPANY_RISK_SUCCESS';
export const UPDATE_COMPANY_RISK_FAIL = 'UPDATE_COMPANY_RISK_FAIL';

export const DELETE_COMPANY_RISK_START = 'DELETE_COMPANY_RISK_START';
export const DELETE_COMPANY_RISK_SUCCESS = 'DELETE_COMPANY_RISK_SUCCESS';
export const DELETE_COMPANY_RISK_FAIL = 'DELETE_COMPANY_RISK_FAIL';

export const RESET_COMPANY_RISK_STORE = 'RESET_COMPANY_RISK_STORE';

export type CompanyRiskActionTypes =
  | typeof CREATE_COMPANY_RISK_START
  | typeof CREATE_COMPANY_RISK_SUCCESS
  | typeof CREATE_COMPANY_RISK_FAIL
  | typeof UPDATE_COMPANY_RISK_START
  | typeof UPDATE_COMPANY_RISK_SUCCESS
  | typeof UPDATE_COMPANY_RISK_FAIL
  | typeof DELETE_COMPANY_RISK_START
  | typeof DELETE_COMPANY_RISK_SUCCESS
  | typeof DELETE_COMPANY_RISK_FAIL
  | typeof RESET_COMPANY_RISK_STORE;
