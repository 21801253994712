import * as actionTypes from './actionTypes';
import { CompanyTypeActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { ListResults } from '../../common/List/List';
import { CompanyType } from '../../domain/CompanyType';

export type CompanyTypeStateType = {
  companyTypesList: ListResults<CompanyType> | null;
  companyTypesLoading: boolean;
  companyTypesError: HttpError;
  companyTypesListUpdateNeeded: boolean;
  companyTypeCreateLoading: boolean;
  companyTypeCreateError: HttpError;
  companyTypeCreateSuccess: boolean;
  createdCompanyType: CompanyType | null;
  companyTypeUpdateLoading: boolean;
  companyTypeUpdateError: HttpError;
  companyTypeUpdateSuccess: boolean;
  companyType: CompanyType | null;
  companyTypeLoading: boolean;
  companyTypeError: HttpError;
  companyTypeDeleteLoading: boolean;
  companyTypeDeleteError: HttpError;
  companyTypeDeleteSuccess: boolean;
};

export type CompanyTypeActionType = CompanyTypeStateType & {
  type: CompanyTypeActionTypes;
};

export const initialState: CompanyTypeStateType = {
  companyTypesList: null,
  companyTypesLoading: true,
  companyTypesError: null,
  companyTypesListUpdateNeeded: false,
  companyTypeCreateLoading: false,
  companyTypeCreateError: null,
  companyTypeCreateSuccess: false,
  createdCompanyType: null,
  companyTypeUpdateLoading: false,
  companyTypeUpdateError: null,
  companyTypeUpdateSuccess: false,
  companyType: null,
  companyTypeLoading: false,
  companyTypeError: null,
  companyTypeDeleteLoading: false,
  companyTypeDeleteError: null,
  companyTypeDeleteSuccess: false,
};

const fetchCompanyTypesStart = (
  state: CompanyTypeStateType,
): CompanyTypeStateType => ({
  ...state,
  companyTypesLoading: true,
});

const fetchCompanyTypesSuccess = (
  state: CompanyTypeStateType,
  action: CompanyTypeActionType,
): CompanyTypeStateType => ({
  ...state,
  companyTypesList: action.companyTypesList,
  companyTypesLoading: false,
  companyTypesError: null,
  companyTypesListUpdateNeeded: false,
});

const fetchCompanyTypesFail = (
  state: CompanyTypeStateType,
  action: CompanyTypeActionType,
): CompanyTypeStateType => ({
  ...state,
  companyTypesError: action.companyTypesError,
  companyTypesLoading: false,
});

const createCompanyTypeStart = (
  state: CompanyTypeStateType,
): CompanyTypeStateType => ({
  ...state,
  companyTypeCreateLoading: true,
});

const createCompanyTypeSuccess = (
  state: CompanyTypeStateType,
  action: CompanyTypeActionType,
): CompanyTypeStateType => ({
  ...state,
  companyTypeCreateLoading: false,
  companyTypeCreateError: null,
  companyTypeCreateSuccess: true,
  companyTypesListUpdateNeeded: true,
  createdCompanyType: action.createdCompanyType,
  companyTypesList: state.companyTypesList
    ? {
        total: state.companyTypesList.total + 1,
        result: [
          ...state.companyTypesList.result,
          action.createdCompanyType,
        ] as CompanyType[],
      }
    : null,
});

const createCompanyTypeFail = (
  state: CompanyTypeStateType,
  action: CompanyTypeActionType,
): CompanyTypeStateType => ({
  ...state,
  companyTypeCreateLoading: false,
  companyTypeCreateError: action.companyTypeCreateError,
});

const updateCompanyTypeStart = (
  state: CompanyTypeStateType,
): CompanyTypeStateType => ({
  ...state,
  companyTypeUpdateLoading: true,
});

const updateCompanyTypeSuccess = (
  state: CompanyTypeStateType,
): CompanyTypeStateType => ({
  ...state,
  companyTypeUpdateLoading: false,
  companyTypeUpdateError: null,
  companyTypeUpdateSuccess: true,
  companyTypesListUpdateNeeded: true,
});

const updateCompanyTypeFail = (
  state: CompanyTypeStateType,
  action: CompanyTypeActionType,
): CompanyTypeStateType => ({
  ...state,
  companyTypeUpdateLoading: false,
  companyTypeUpdateError: action.companyTypeUpdateError,
});

const deleteCompanyTypeStart = (
  state: CompanyTypeStateType,
): CompanyTypeStateType => ({
  ...state,
  companyTypeDeleteLoading: true,
});

const deleteCompanyTypeSuccess = (
  state: CompanyTypeStateType,
): CompanyTypeStateType => ({
  ...state,
  companyTypeDeleteLoading: false,
  companyTypeDeleteError: null,
  companyTypeDeleteSuccess: true,
  companyTypesListUpdateNeeded: true,
});

const deleteCompanyTypeFail = (
  state: CompanyTypeStateType,
  action: CompanyTypeActionType,
): CompanyTypeStateType => ({
  ...state,
  companyTypeDeleteLoading: false,
  companyTypeDeleteError: action.companyTypeDeleteError,
});

const resetCreatedCompanyType = (
  state: CompanyTypeStateType,
): CompanyTypeStateType => ({
  ...state,
  createdCompanyType: null,
});

const resetCompanyTypeStore = (): CompanyTypeStateType => ({
  companyTypesList: null,
  companyTypesLoading: false,
  companyTypesError: null,
  companyTypesListUpdateNeeded: false,
  companyTypeCreateLoading: false,
  companyTypeCreateError: null,
  companyTypeCreateSuccess: false,
  createdCompanyType: null,
  companyTypeUpdateLoading: false,
  companyTypeUpdateError: null,
  companyTypeUpdateSuccess: false,
  companyType: null,
  companyTypeLoading: false,
  companyTypeError: null,
  companyTypeDeleteLoading: false,
  companyTypeDeleteError: null,
  companyTypeDeleteSuccess: false,
});

const reducer = (state = initialState, action: CompanyTypeActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_COMPANY_TYPES_START:
      return fetchCompanyTypesStart(state);
    case actionTypes.FETCH_COMPANY_TYPES_SUCCESS:
      return fetchCompanyTypesSuccess(state, action);
    case actionTypes.FETCH_COMPANY_TYPES_FAIL:
      return fetchCompanyTypesFail(state, action);
    case actionTypes.CREATE_COMPANY_TYPE_START:
      return createCompanyTypeStart(state);
    case actionTypes.CREATE_COMPANY_TYPE_SUCCESS:
      return createCompanyTypeSuccess(state, action);
    case actionTypes.CREATE_COMPANY_TYPE_FAIL:
      return createCompanyTypeFail(state, action);
    case actionTypes.UPDATE_COMPANY_TYPE_START:
      return updateCompanyTypeStart(state);
    case actionTypes.UPDATE_COMPANY_TYPE_SUCCESS:
      return updateCompanyTypeSuccess(state);
    case actionTypes.UPDATE_COMPANY_TYPE_FAIL:
      return updateCompanyTypeFail(state, action);
    case actionTypes.DELETE_COMPANY_TYPE_START:
      return deleteCompanyTypeStart(state);
    case actionTypes.DELETE_COMPANY_TYPE_SUCCESS:
      return deleteCompanyTypeSuccess(state);
    case actionTypes.DELETE_COMPANY_TYPE_FAIL:
      return deleteCompanyTypeFail(state, action);
    case actionTypes.RESET_COMPANY_TYPE_STORE:
      return resetCompanyTypeStore();
    case actionTypes.RESET_CREATED_COMPANY_TYPE:
      return resetCreatedCompanyType(state);
    default:
      return state;
  }
};

export default reducer;
