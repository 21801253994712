export const SET_USER_SETTING_START = 'SET_USER_SETTING_START';
export const SET_USER_SETTING_SUCCESS = 'SET_USER_SETTING_SUCCESS';
export const SET_USER_SETTING_FAIL = 'SET_USER_SETTING_FAIL';

export const RESET_USER_SETTING_STORE = 'RESET_USER_SETTING_STORE';

export type UserSettingActionTypes =
  | typeof SET_USER_SETTING_START
  | typeof SET_USER_SETTING_SUCCESS
  | typeof SET_USER_SETTING_FAIL
  | typeof RESET_USER_SETTING_STORE;
