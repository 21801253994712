export const CREATE_COMPANY_INSTRUCTION_START =
  'CREATE_COMPANY_INSTRUCTION_START';
export const CREATE_COMPANY_INSTRUCTION_SUCCESS =
  'CREATE_COMPANY_INSTRUCTION_SUCCESS';
export const CREATE_COMPANY_INSTRUCTION_FAIL =
  'CREATE_COMPANY_INSTRUCTION_FAIL';

export const UPDATE_COMPANY_INSTRUCTION_START =
  'UPDATE_COMPANY_INSTRUCTION_START';
export const UPDATE_COMPANY_INSTRUCTION_SUCCESS =
  'UPDATE_COMPANY_INSTRUCTION_SUCCESS';
export const UPDATE_COMPANY_INSTRUCTION_FAIL =
  'UPDATE_COMPANY_INSTRUCTION_FAIL';

export const DELETE_COMPANY_INSTRUCTION_START =
  'DELETE_COMPANY_INSTRUCTION_START';
export const DELETE_COMPANY_INSTRUCTION_SUCCESS =
  'DELETE_COMPANY_INSTRUCTION_SUCCESS';
export const DELETE_COMPANY_INSTRUCTION_FAIL =
  'DELETE_COMPANY_INSTRUCTION_FAIL';

export const TOGGLE_COMPANY_INSTRUCTION_STATUS_START =
  'TOGGLE_COMPANY_INSTRUCTION_STATUS_START';
export const TOGGLE_COMPANY_INSTRUCTION_STATUS_SUCCESS =
  'TOGGLE_COMPANY_INSTRUCTION_STATUS_SUCCESS';
export const TOGGLE_COMPANY_INSTRUCTION_STATUS_FAIL =
  'TOGGLE_COMPANY_INSTRUCTION_STATUS_FAIL';

export const FETCH_SIGNED_EMPLOYEES_START = 'FETCH_SIGNED_EMPLOYEES_START';
export const FETCH_SIGNED_EMPLOYEES_SUCCESS = 'FETCH_SIGNED_EMPLOYEES_SUCCESS';
export const FETCH_SIGNED_EMPLOYEES_FAIL = 'FETCH_SIGNED_EMPLOYEES_FAIL';

export const ASK_TO_SIGN_START = 'ASK_TO_SIGN_START';
export const ASK_TO_SIGN_SUCCESS = 'ASK_TO_SIGN_SUCCESS';
export const ASK_TO_SIGN_FAIL = 'ASK_TO_SIGN_FAIL';

export const RESET_COMPANY_INSTRUCTION_STORE =
  'RESET_COMPANY_INSTRUCTION_STORE';

export type CompanyInstructionActionTypes =
  | typeof CREATE_COMPANY_INSTRUCTION_START
  | typeof CREATE_COMPANY_INSTRUCTION_SUCCESS
  | typeof CREATE_COMPANY_INSTRUCTION_FAIL
  | typeof UPDATE_COMPANY_INSTRUCTION_START
  | typeof UPDATE_COMPANY_INSTRUCTION_SUCCESS
  | typeof UPDATE_COMPANY_INSTRUCTION_FAIL
  | typeof DELETE_COMPANY_INSTRUCTION_START
  | typeof DELETE_COMPANY_INSTRUCTION_SUCCESS
  | typeof DELETE_COMPANY_INSTRUCTION_FAIL
  | typeof TOGGLE_COMPANY_INSTRUCTION_STATUS_START
  | typeof TOGGLE_COMPANY_INSTRUCTION_STATUS_SUCCESS
  | typeof TOGGLE_COMPANY_INSTRUCTION_STATUS_FAIL
  | typeof ASK_TO_SIGN_START
  | typeof ASK_TO_SIGN_SUCCESS
  | typeof ASK_TO_SIGN_FAIL
  | typeof FETCH_SIGNED_EMPLOYEES_START
  | typeof FETCH_SIGNED_EMPLOYEES_SUCCESS
  | typeof FETCH_SIGNED_EMPLOYEES_FAIL
  | typeof RESET_COMPANY_INSTRUCTION_STORE;
