import * as actionTypes from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { ListResults } from '../../common/List/List';
import { CompanyInstructionGroup } from '../../domain/CompanyInstructionGroup';
import { CompanyInstructionGroupActionTypes } from './actionTypes';

export type CompanyInstructionGroupStateType = {
  companyInstructionGroupsList: ListResults<CompanyInstructionGroup> | null;
  companyInstructionGroupsLoading: boolean;
  companyInstructionGroupsError: HttpError;
  companyInstructionGroupsListSuccess: boolean;
  companyInstructionGroupsListUpdateNeeded: boolean;
  companyInstructionGroupCreateLoading: boolean;
  companyInstructionGroupCreateError: HttpError;
  companyInstructionGroupUpdateLoading: boolean;
  companyInstructionGroupUpdateError: HttpError;
  companyInstructionGroupDeleteLoading: boolean;
  companyInstructionGroupDeleteError: HttpError;
};

export type CompanyInstructionGroupActionType =
  CompanyInstructionGroupStateType & {
    type: CompanyInstructionGroupActionTypes;
  };

export const initialState: CompanyInstructionGroupStateType = {
  companyInstructionGroupsList: null,
  companyInstructionGroupsLoading: false,
  companyInstructionGroupsListSuccess: false,
  companyInstructionGroupsListUpdateNeeded: false,
  companyInstructionGroupsError: null,
  companyInstructionGroupCreateLoading: false,
  companyInstructionGroupCreateError: null,
  companyInstructionGroupUpdateLoading: false,
  companyInstructionGroupUpdateError: null,
  companyInstructionGroupDeleteLoading: false,
  companyInstructionGroupDeleteError: null,
};

const fetchCompanyInstructionGroupsStart = (
  state: CompanyInstructionGroupStateType,
): CompanyInstructionGroupStateType => ({
  ...state,
  companyInstructionGroupsLoading: true,
  companyInstructionGroupsListSuccess: false,
});

const fetchCompanyInstructionGroupsSuccess = (
  state: CompanyInstructionGroupStateType,
  action: CompanyInstructionGroupActionType,
): CompanyInstructionGroupStateType => ({
  ...state,
  companyInstructionGroupsList: action.companyInstructionGroupsList,
  companyInstructionGroupsLoading: false,
  companyInstructionGroupsError: null,
  companyInstructionGroupsListUpdateNeeded: false,
  companyInstructionGroupsListSuccess: true,
});

const fetchCompanyInstructionGroupsFail = (
  state: CompanyInstructionGroupStateType,
  action: CompanyInstructionGroupActionType,
): CompanyInstructionGroupStateType => ({
  ...state,
  companyInstructionGroupsError: action.companyInstructionGroupsError,
  companyInstructionGroupsLoading: false,
});

const createCompanyInstructionGroupStart = (
  state: CompanyInstructionGroupStateType,
): CompanyInstructionGroupStateType => ({
  ...state,
  companyInstructionGroupCreateLoading: true,
});

const createCompanyInstructionGroupSuccess = (
  state: CompanyInstructionGroupStateType,
): CompanyInstructionGroupStateType => ({
  ...state,
  companyInstructionGroupCreateLoading: false,
  companyInstructionGroupCreateError: null,
  companyInstructionGroupsListUpdateNeeded: true,
});

const createCompanyInstructionGroupFail = (
  state: CompanyInstructionGroupStateType,
  action: CompanyInstructionGroupActionType,
): CompanyInstructionGroupStateType => ({
  ...state,
  companyInstructionGroupCreateLoading: false,
  companyInstructionGroupCreateError: action.companyInstructionGroupCreateError,
});

const updateCompanyInstructionGroupStart = (
  state: CompanyInstructionGroupStateType,
): CompanyInstructionGroupStateType => ({
  ...state,
  companyInstructionGroupUpdateLoading: true,
});

const updateCompanyInstructionGroupSuccess = (
  state: CompanyInstructionGroupStateType,
): CompanyInstructionGroupStateType => ({
  ...state,
  companyInstructionGroupUpdateLoading: false,
  companyInstructionGroupUpdateError: null,
  companyInstructionGroupsListUpdateNeeded: true,
});

const updateCompanyInstructionGroupFail = (
  state: CompanyInstructionGroupStateType,
  action: CompanyInstructionGroupActionType,
): CompanyInstructionGroupStateType => ({
  ...state,
  companyInstructionGroupUpdateLoading: false,
  companyInstructionGroupUpdateError: action.companyInstructionGroupUpdateError,
});

const deleteCompanyInstructionGroupStart = (
  state: CompanyInstructionGroupStateType,
): CompanyInstructionGroupStateType => ({
  ...state,
  companyInstructionGroupDeleteLoading: true,
});

const deleteCompanyInstructionGroupSuccess = (
  state: CompanyInstructionGroupStateType,
): CompanyInstructionGroupStateType => ({
  ...state,
  companyInstructionGroupDeleteLoading: false,
  companyInstructionGroupDeleteError: null,
  companyInstructionGroupsListUpdateNeeded: true,
});

const deleteCompanyInstructionGroupFail = (
  state: CompanyInstructionGroupStateType,
  action: CompanyInstructionGroupActionType,
): CompanyInstructionGroupStateType => ({
  ...state,
  companyInstructionGroupDeleteLoading: false,
  companyInstructionGroupDeleteError: action.companyInstructionGroupDeleteError,
});

const resetCompanyInstructionGroupStore =
  (): CompanyInstructionGroupStateType => ({
    ...initialState,
  });

const reducer = (
  state = initialState,
  action: CompanyInstructionGroupActionType,
) => {
  switch (action.type) {
    case actionTypes.FETCH_COMPANY_INSTRUCTION_GROUPS_START:
      return fetchCompanyInstructionGroupsStart(state);
    case actionTypes.FETCH_COMPANY_INSTRUCTION_GROUPS_SUCCESS:
      return fetchCompanyInstructionGroupsSuccess(state, action);
    case actionTypes.FETCH_COMPANY_INSTRUCTION_GROUPS_FAIL:
      return fetchCompanyInstructionGroupsFail(state, action);
    case actionTypes.CREATE_COMPANY_INSTRUCTION_GROUP_START:
      return createCompanyInstructionGroupStart(state);
    case actionTypes.CREATE_COMPANY_INSTRUCTION_GROUP_SUCCESS:
      return createCompanyInstructionGroupSuccess(state);
    case actionTypes.CREATE_COMPANY_INSTRUCTION_GROUP_FAIL:
      return createCompanyInstructionGroupFail(state, action);
    case actionTypes.UPDATE_COMPANY_INSTRUCTION_GROUP_START:
      return updateCompanyInstructionGroupStart(state);
    case actionTypes.UPDATE_COMPANY_INSTRUCTION_GROUP_SUCCESS:
      return updateCompanyInstructionGroupSuccess(state);
    case actionTypes.UPDATE_COMPANY_INSTRUCTION_GROUP_FAIL:
      return updateCompanyInstructionGroupFail(state, action);
    case actionTypes.DELETE_COMPANY_INSTRUCTION_GROUP_START:
      return deleteCompanyInstructionGroupStart(state);
    case actionTypes.DELETE_COMPANY_INSTRUCTION_GROUP_SUCCESS:
      return deleteCompanyInstructionGroupSuccess(state);
    case actionTypes.DELETE_COMPANY_INSTRUCTION_GROUP_FAIL:
      return deleteCompanyInstructionGroupFail(state, action);
    case actionTypes.RESET_COMPANY_INSTRUCTION_GROUP_STORE:
      return resetCompanyInstructionGroupStore();
    default:
      return state;
  }
};

export default reducer;
