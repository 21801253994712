import * as actionTypes from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { UserSetting } from '../../domain/UserSetting';

export const setUserSettingStart = () => ({
  type: actionTypes.SET_USER_SETTING_START,
});

export const setUserSettingSuccess = (createdUserSetting: UserSetting) => ({
  type: actionTypes.SET_USER_SETTING_SUCCESS,
  createdUserSetting,
});

export const setUserSettingFail = (setUserSettingError: HttpError) => ({
  type: actionTypes.SET_USER_SETTING_FAIL,
  setUserSettingError,
});

export const resetUserSettingStore = () => ({
  type: actionTypes.RESET_USER_SETTING_STORE,
});
