export const FETCH_JOB_TITLES_START = 'FETCH_JOB_TITLES_START';
export const FETCH_JOB_TITLES_SUCCESS = 'FETCH_JOB_TITLES_SUCCESS';
export const FETCH_JOB_TITLES_FAIL = 'FETCH_JOB_TITLES_FAIL';

export const FETCH_JOB_TITLE_START = 'FETCH_JOB_TITLE_START';
export const FETCH_JOB_TITLE_SUCCESS = 'FETCH_JOB_TITLE_SUCCESS';
export const FETCH_JOB_TITLE_FAIL = 'FETCH_JOB_TITLE_FAIL';

export const CREATE_JOB_TITLE_START = 'CREATE_JOB_TITLE_START';
export const CREATE_JOB_TITLE_SUCCESS = 'CREATE_JOB_TITLE_SUCCESS';
export const CREATE_JOB_TITLE_FAIL = 'CREATE_JOB_TITLE_FAIL';

export const UPDATE_JOB_TITLE_START = 'UPDATE_JOB_TITLE_START';
export const UPDATE_JOB_TITLE_SUCCESS = 'UPDATE_JOB_TITLE_SUCCESS';
export const UPDATE_JOB_TITLE_FAIL = 'UPDATE_JOB_TITLE_FAIL';

export const DELETE_JOB_TITLE_START = 'DELETE_JOB_TITLE_START';
export const DELETE_JOB_TITLE_SUCCESS = 'DELETE_JOB_TITLE_SUCCESS';
export const DELETE_JOB_TITLE_FAIL = 'DELETE_JOB_TITLE_FAIL';

export const RESET_JOB_TITLE_STORE = 'RESET_JOB_TITLE_STORE';
export const RESET_CREATED_JOB_TITLE = 'RESET_CREATED_JOB_TITLE';

export type JobTitleActionTypes =
  | typeof FETCH_JOB_TITLES_START
  | typeof FETCH_JOB_TITLES_SUCCESS
  | typeof FETCH_JOB_TITLES_FAIL
  | typeof FETCH_JOB_TITLE_START
  | typeof FETCH_JOB_TITLE_SUCCESS
  | typeof FETCH_JOB_TITLE_FAIL
  | typeof CREATE_JOB_TITLE_START
  | typeof CREATE_JOB_TITLE_SUCCESS
  | typeof CREATE_JOB_TITLE_FAIL
  | typeof UPDATE_JOB_TITLE_START
  | typeof UPDATE_JOB_TITLE_SUCCESS
  | typeof UPDATE_JOB_TITLE_FAIL
  | typeof RESET_JOB_TITLE_STORE
  | typeof RESET_CREATED_JOB_TITLE
  | typeof DELETE_JOB_TITLE_START
  | typeof DELETE_JOB_TITLE_SUCCESS
  | typeof DELETE_JOB_TITLE_FAIL;
