import Router from './config/Router/Router';
import StoreProvider from './config/StoreProvider/StoreProvider';
import React from 'react';
import { ToastContainer } from 'react-toastify';

const App = () => {
  return (
    <StoreProvider>
      <Router />
      <ToastContainer />
    </StoreProvider>
  );
};

export default App;
