import React, { ReactNode } from 'react';
import styles from './CurrentAccount.module.scss';

type Props = {
  logo?: string;
  title: string;
  subTitle: string;
  companyName: string | undefined;
  children: ReactNode;
  onClick: () => void;
};

export const CurrentAccount = ({
  logo,
  title,
  subTitle,
  companyName,
  children,
  onClick,
}: Props) => {
  return (
    <div className={styles.currentAccountContainer} onClick={onClick}>
      {logo && <img src={logo} alt="" />}
      <div className={styles.content}>
        {!companyName && <div className={styles.title}>{title}</div>}

        <div className={styles.subTitle}>{subTitle}</div>
        <div className={styles.companyName}>{companyName}</div>
      </div>
      {children}
    </div>
  );
};

export default CurrentAccount;
