import React, { ReactNode } from 'react';
import styles from './Sidebar.module.scss';
import { Link, NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logo from '../../assets/logo_full.png';
import ababaTechLogo from '../../assets/ababa_tech_logo.png';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { routes } from '../../config/Router/routes';
import cx from 'classnames';
import { NavigationGroup } from '../Layout/Layout';
import { User } from '../../domain/User';
import { Locale } from '../../domain/Translation';
import { getLocaleUrl } from '../../utility/url/urlHelper';
import { CompanySystem } from '../../domain/Company';
import { isVisibleToSystem } from '../../utility/system/system';
import { Roles } from '../../domain/Role';

type Props = {
  navigationGroups: NavigationGroup[];
  currentAccount?: ReactNode;
  currentUser: User | null;
  selectedLocale: Locale;
  selectedSystem: CompanySystem | null;
  selectedRole: Roles | null;
};

const Sidebar = ({
  navigationGroups,
  currentAccount,
  currentUser,
  selectedLocale,
  selectedSystem,
  selectedRole,
}: Props) => {
  return (
    <div className={styles.sidebarContainer}>
      <div className={styles.sidebarContent}>
        <div className={styles.mainContent}>
          <div className={styles.logoContainer}>
            <div className={styles.logo}>
              <Link to={getLocaleUrl(routes.login, selectedLocale)}>
                <img src={logo} alt="" />
              </Link>
            </div>
          </div>
          {currentAccount}
          <div className={styles.menuItemsContainer}>
            {navigationGroups.map((sidebarItem) => (
              <div className={styles.menuItems} key={sidebarItem.label}>
                <div className={styles.menuItemsTitle}>{sidebarItem.label}</div>
                <div className={styles.menuSubItemsContainer}>
                  <div className={styles.menuSubItems}>
                    {sidebarItem.items
                      .filter((navItem) =>
                        isVisibleToSystem(
                          selectedRole,
                          selectedSystem ?? 0,
                          navItem.roles,
                          navItem.systems,
                        ),
                      )
                      .map((navItem) => (
                        <div
                          key={navItem.to + navItem.label}
                          className={styles.subItemContainer}
                        >
                          <NavLink
                            to={getLocaleUrl(navItem.to, selectedLocale)}
                            className={({ isActive }) =>
                              cx(styles.subItem, {
                                [styles.activeSubItem]: isActive,
                              })
                            }
                          >
                            <FontAwesomeIcon
                              icon={navItem.icon as IconProp}
                              className={styles.navigationIcon}
                              fixedWidth
                            />
                            <div className={styles.navigationText}>
                              {navItem.label}
                            </div>
                          </NavLink>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className={styles.bottomLogoWrapper}>
            <hr className={styles.bottomLogoDivider} />
            <div className={styles.logoContainer}>
              <p className={styles.bottomLogoText}>POWERED BY</p>
              <div className={styles.bottomLogo}>
                <a
                  href="https://ababa.tech/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={ababaTechLogo} alt="ababa.tech logo" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.bottomScrollContainer}>
          <div className={styles.bottomScroll} />
        </div>
        <div className={styles.rightScrollContainer}>
          <div className={styles.rightScroll} />
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
