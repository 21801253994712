import React, { ReactNode, useEffect, useState } from 'react';
import styles from './Layout.module.scss';
import Sidebar from '../Sidebar/Sidebar';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import cx from 'classnames';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import useWindowSize from '../../hooks/useWindowSize/useWindowSize';
import Navigation from '../Navigation/Navigation';
import {
  faBriefcase,
  faBuilding,
  faChalkboardUser,
  faClock,
  faFileExport,
  faGlobe,
  faHelmetSafety,
  faListCheck,
  faPeopleGroup,
  faPerson,
  faPersonChalkboard,
  faPersonFallingBurst,
  faTag,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import { routes } from '../../config/Router/routes';
import { IntlShape, useIntl } from 'react-intl';
import { translate } from '../../utility/messageTranslator/translate';
import { Locale } from '../../domain/Translation';
import { StoreState } from '../../config/StoreProvider/StoreProvider';
import { connect } from 'react-redux';
import { Roles } from '../../domain/Role';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import * as companyService from '../../store/company/service';
import { Company, CompanySystem } from '../../domain/Company';
import { User } from '../../domain/User';
import CurrentAccount from '../CurrentAccount/CurrentAccount';
import Modal from '../Modal/Modal';
import CompanySelectForm from '../../component/CompanySelectForm/CompanySelectForm';
import { getLocaleUrl } from '../../utility/url/urlHelper';
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher';
import _ from 'lodash';
import {
  setAvailableSystems,
  setSelectedCompany,
  setSelectedSystem,
} from '../../store/company/actions';
import { isVisibleToSystem } from '../../utility/system/system';
import { Asset } from '../../domain/Asset';
import { UserSetting, UserSettingType } from '../../domain/UserSetting';
import * as userSettingService from '../../store/user-setting/service';

export type Props = {
  children: ReactNode;
  isAuthenticated: boolean;
  selectedLocale: Locale;
  companyOptions: Company[];
  onCompanyOptionsFetch: (intl: IntlShape) => void;
  selectedCompany: number | null;
  currentUser: User | null;
  onCompanySystemSelect: (selectedCompanySystem: number | null) => void;
  setAvailableSystems: (availableSystems: CompanySystem[]) => void;
  selectedSystem: CompanySystem | null;
  onCompanySelect: (
    companyId: number,
    availableSystems: CompanySystem[],
    companyLogo: Asset | null,
  ) => void;
  companyLogo: Asset | null;
  selectedRole: Roles | null;
  onSetSetting: (type: UserSettingType, value: string) => void;
  createdUserSetting: UserSetting | null;
};

export type NavigationItem = {
  label: string;
  to: string;
  icon?: IconProp;
  roles: Roles[];
  systems?: CompanySystem[];
};

export type NavigationGroup = {
  label: string;
  items: NavigationItem[];
  roles: Roles[];
  systems?: CompanySystem[];
};

const MOBILE_BREAK_POINT = 900;

const Layout = ({
  children,
  isAuthenticated,
  selectedLocale,
  companyOptions,
  onCompanyOptionsFetch,
  selectedCompany,
  currentUser,
  onCompanySystemSelect,
  setAvailableSystems,
  selectedSystem,
  onCompanySelect,
  companyLogo,
  selectedRole,
  createdUserSetting,
  onSetSetting,
}: Props) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isAutoSelectLoading, setIsAutoSelectLoading] =
    useState<boolean>(false);
  const [company, setCompany] = useState<Company | undefined>(undefined);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const routeLocation = useLocation();
  const intl = useIntl();
  const navigate = useNavigate();

  const { width } = useWindowSize();

  useEffect(() => {
    setIsMobileMenuOpen(false);
  }, [routeLocation.key]);

  const SIDEBAR_ITEMS = [
    {
      label: translate(intl, 'NAVIGATION.GROUP_GLOBAL'),
      roles: [Roles.ADMIN, Roles.OWNER, Roles.USER],
      items: [
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_COMPANIES'),
          to: getLocaleUrl(routes.companies.list, selectedLocale),
          icon: faBuilding,
          roles: [Roles.ADMIN],
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_COMPANY_TYPES'),
          to: getLocaleUrl(routes.companyTypes.list, selectedLocale),
          icon: faTag as IconProp,
          roles: [Roles.ADMIN],
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_INSTRUCTIONS'),
          to: getLocaleUrl(routes.instructions.list, selectedLocale),
          icon: faPersonChalkboard as IconProp,
          roles: [Roles.ADMIN],
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_MEETINGS'),
          to: getLocaleUrl(routes.meetings.list, selectedLocale),
          icon: faChalkboardUser as IconProp,
          roles: [Roles.ADMIN],
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_RISKS'),
          to: getLocaleUrl(routes.risks.list, selectedLocale),
          icon: faHelmetSafety as IconProp,
          roles: [Roles.ADMIN],
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_COURSES'),
          to: getLocaleUrl(routes.courses.list, selectedLocale),
          icon: faListCheck,
          roles: [Roles.ADMIN],
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_COURSES'),
          to: getLocaleUrl(routes.courses.shop, selectedLocale),
          icon: faListCheck,
          roles: [Roles.OWNER],
          systems: [CompanySystem.COURSES],
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_USERS'),
          to: getLocaleUrl(routes.users.list, selectedLocale),
          icon: faUsers as IconProp,
          roles: [Roles.ADMIN],
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_TRANSLATIONS'),
          to: getLocaleUrl(routes.translations, selectedLocale),
          icon: faGlobe as IconProp,
          roles: [Roles.ADMIN],
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_INSTRUCTIONS'),
          to: getLocaleUrl(routes.employees.instructions, selectedLocale),
          icon: faPersonChalkboard as IconProp,
          roles: [Roles.USER],
          systems: [CompanySystem.HMS],
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_COMPANY_MEETINGS'),
          to: getLocaleUrl(routes.employees.actionPlans, selectedLocale),
          icon: faChalkboardUser as IconProp,
          roles: [Roles.USER],
          systems: [CompanySystem.HMS],
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_ACCIDENTS'),
          to: getLocaleUrl(routes.employees.accidents, selectedLocale),
          icon: faPersonFallingBurst as IconProp,
          roles: [Roles.USER],
          systems: [CompanySystem.HMS],
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_WORK_LOGS'),
          to: getLocaleUrl(routes.workLogs.list, selectedLocale),
          icon: faClock as IconProp,
          roles: [Roles.USER],
          systems: [CompanySystem.TIME_TRACKING],
        },
      ],
    },
  ];

  useEffect(() => {
    if (isMobileMenuOpen) {
      window.scroll({ top: 0 });
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isMobileMenuOpen]);

  useEffect(() => {
    if (isAuthenticated) {
      onCompanyOptionsFetch(intl);
    }
  }, []);

  useEffect(() => {
    if (
      createdUserSetting?.type === UserSettingType.COMPANY &&
      isAutoSelectLoading
    ) {
      const companyOption = companyOptions[0];

      onCompanySelect(
        companyOption.id,
        companyOption.systems,
        companyOption.logo,
      );

      onCompanySelectSuccess();
      setIsAutoSelectLoading(false);
    }
  }, [createdUserSetting]);

  useEffect(() => {
    if (selectedCompany && companyOptions.length === 0) {
      return;
    }

    if (
      (selectedRole === Roles.USER || selectedRole === Roles.MANAGER) &&
      companyOptions.length === 1 &&
      !selectedCompany
    ) {
      const companyOption = companyOptions[0];

      setIsAutoSelectLoading(true);
      onSetSetting(UserSettingType.COMPANY, companyOption.id.toString());
    }

    const company = companyOptions.find(
      (companyOption) => companyOption.id === selectedCompany,
    );

    setCompany(company);

    if (company && company.systems) {
      const hasSelected = company.systems.includes(selectedSystem ?? 0);
      const hasSystems = !!company.systems.length;

      onCompanySystemSelect(
        hasSystems
          ? hasSelected
            ? selectedSystem
            : _.sortBy(company.systems)[0]
          : null,
      );
      setAvailableSystems(company.systems);
    } else {
      onCompanySystemSelect(null);
      setAvailableSystems([]);
    }
  }, [companyOptions, selectedCompany]);

  useEffect(() => {
    if (selectedCompany) {
      setIsModalOpen(false);
    }
  }, [selectedCompany]);

  const getNavigationGroups = () => {
    if (!companyOptions?.length && selectedRole === Roles.USER) {
      return [];
    }

    const sidebarItems = SIDEBAR_ITEMS.filter(
      (item) => currentUser && item.roles.find((role) => selectedRole === role),
    ).filter((item) =>
      item.items.find((route) =>
        isVisibleToSystem(
          selectedRole,
          selectedSystem ?? 0,
          route.roles,
          route.systems,
        ),
      ),
    );

    if (!company || selectedRole === Roles.USER) {
      return sidebarItems;
    }

    return [
      {
        label: translate(intl, 'SIDEBAR.COMPANY_SETTINGS'),
        roles: [Roles.ADMIN, Roles.OWNER, Roles.MANAGER],
        items: [
          {
            label: translate(intl, 'NAVIGATION.SIDEBAR_EMPLOYEES'),
            to: getLocaleUrl(routes.employees.list, selectedLocale),
            icon: faPerson as IconProp,
            roles: [Roles.ADMIN, Roles.OWNER, Roles.MANAGER],
          },
          {
            label: translate(intl, 'NAVIGATION.SIDEBAR_INSTRUCTIONS'),
            to: getLocaleUrl(routes.companies.instructions, selectedLocale),
            icon: faPersonChalkboard as IconProp,
            roles: [Roles.ADMIN, Roles.OWNER, Roles.MANAGER],
            systems: [CompanySystem.HMS],
          },
          {
            label: translate(intl, 'NAVIGATION.SIDEBAR_RISKS'),
            to: getLocaleUrl(routes.companies.risks, selectedLocale),
            icon: faHelmetSafety as IconProp,
            roles: [Roles.ADMIN, Roles.OWNER, Roles.MANAGER],
            systems: [CompanySystem.HMS],
          },
          {
            label: translate(intl, 'NAVIGATION.SIDEBAR_COMPANY_MEETINGS'),
            to: getLocaleUrl(routes.companies.actionPlans, selectedLocale),
            icon: faChalkboardUser as IconProp,
            roles: [Roles.ADMIN, Roles.OWNER, Roles.MANAGER],
            systems: [CompanySystem.HMS],
          },
          {
            label: translate(intl, 'NAVIGATION.SIDEBAR_JOB_TITLES'),
            to: getLocaleUrl(routes.jobTitles.list, selectedLocale),
            icon: faTag as IconProp,
            roles: [Roles.OWNER, Roles.ADMIN, Roles.MANAGER],
          },
          {
            label: translate(intl, 'NAVIGATION.SIDEBAR_CLIENTS'),
            to: getLocaleUrl(routes.clients.list, selectedLocale),
            icon: faPeopleGroup as IconProp,
            roles: [Roles.OWNER, Roles.ADMIN],
            systems: [CompanySystem.TIME_TRACKING],
          },
          {
            label: translate(intl, 'NAVIGATION.SIDEBAR_PROJECTS'),
            to: getLocaleUrl(routes.projects.list, selectedLocale),
            icon: faBriefcase as IconProp,
            roles: [Roles.OWNER, Roles.ADMIN],
            systems: [CompanySystem.TIME_TRACKING],
          },
          {
            label: translate(intl, 'NAVIGATION.SIDEBAR_ACCIDENTS'),
            to: getLocaleUrl(routes.accidents.list, selectedLocale),
            icon: faPersonFallingBurst as IconProp,
            roles: [Roles.OWNER, Roles.ADMIN, Roles.MANAGER],
            systems: [CompanySystem.HMS],
          },
          {
            label: translate(intl, 'NAVIGATION.SIDEBAR_WORK_LOGS'),
            to: getLocaleUrl(routes.workLogs.list, selectedLocale),
            icon: faClock as IconProp,
            roles: [Roles.ADMIN],
            systems: [CompanySystem.TIME_TRACKING],
          },
          {
            label: translate(intl, 'NAVIGATION.SIDEBAR_EXPORT'),
            to: getLocaleUrl(routes.export, selectedLocale),
            icon: faFileExport as IconProp,
            roles: [Roles.OWNER, Roles.ADMIN],
          },
        ],
      },
      ...sidebarItems,
    ];
  };

  if (
    !routeLocation.pathname.includes(getLocaleUrl(routes.admin, selectedLocale))
  ) {
    return (
      <>
        <div className={styles.publicContainer}>
          <div className={styles.navigationContainer}>
            <LanguageSwitcher />
          </div>
          {children}
        </div>
      </>
    );
  }

  const onCompanySelectSuccess = () => {
    setIsModalOpen(false);

    if (routeLocation.pathname === getLocaleUrl(routes.admin, selectedLocale)) {
      navigate(getLocaleUrl(routes.employees.list, selectedLocale));
    }
  };

  const getCurrentCompany = () => {
    if (
      selectedRole &&
      (selectedRole === Roles.USER || selectedRole === Roles.MANAGER) &&
      companyOptions.length <= 1
    ) {
      return null;
    }

    return (
      <CurrentAccount
        logo={companyLogo?.thumbLocation ?? ''}
        title={translate(intl, 'COMPANIES.NO_COMPANY_SELECTED')}
        subTitle={
          company
            ? `${translate(intl, 'COMPANIES.SELECTED_COMPANY')}:`
            : translate(intl, 'COMPANIES.CLICK_TO_SELECT')
        }
        companyName={company?.name}
        onClick={() => !isModalOpen && setIsModalOpen(true)}
      >
        <Modal
          onClose={() => setIsModalOpen(false)}
          isOpen={isModalOpen}
          title={translate(intl, 'COMPANIES.SELECT_COMPANY_TITLE')}
          className={styles.companySelectModal}
        >
          {isModalOpen && (
            <CompanySelectForm onSuccessSelect={onCompanySelectSuccess} />
          )}
        </Modal>
      </CurrentAccount>
    );
  };

  if (
    routeLocation.pathname.includes(getLocaleUrl(routes.login, selectedLocale))
  ) {
    return <>{children}</>;
  }

  return (
    <>
      <div className={styles.container}>
        {width && width >= MOBILE_BREAK_POINT && (
          <Sidebar
            navigationGroups={getNavigationGroups()}
            currentAccount={getCurrentCompany()}
            currentUser={currentUser}
            selectedLocale={selectedLocale}
            selectedSystem={selectedSystem}
            selectedRole={selectedRole}
          />
        )}
        <div
          className={cx(styles.rightSection, {
            [styles.noScroll]: isMobileMenuOpen,
          })}
        >
          <Navigation
            onDrawerClick={() => setIsMobileMenuOpen((prev) => !prev)}
            isMobileMenuOpen={isMobileMenuOpen}
          />
          <div className={styles.content}>{children}</div>
        </div>
      </div>
      {isMobileMenuOpen && width && width < MOBILE_BREAK_POINT && (
        <div className={styles.mobileDrawer}>
          {getNavigationGroups().map((navigationGroup) => (
            <div className={styles.navigationGroup} key={navigationGroup.label}>
              <div className={styles.groupName}>{navigationGroup.label}</div>
              {navigationGroup.items
                .filter((navItem) =>
                  isVisibleToSystem(
                    selectedRole,
                    selectedSystem ?? 0,
                    navItem.roles,
                    navItem.systems,
                  ),
                )
                .map((item) => (
                  <NavLink
                    key={item.label}
                    to={item.to}
                    className={({ isActive }) =>
                      cx(styles.navigationItem, {
                        [styles.activeSubItem]: isActive,
                      })
                    }
                  >
                    {item.label}
                  </NavLink>
                ))}
            </div>
          ))}
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state: StoreState) => ({
  selectedCompany: state.company.selectedCompany,
  companyOptions: state.company.companyOptions,
  selectedLocale: state.auth.selectedLocale,
  currentUser: state.user.currentUser,
  selectedSystem: state.company.selectedSystem,
  companyLogo: state.company.companyLogo,
  selectedRole: state.auth.selectedRole,
  createdUserSetting: state.userSetting.createdUserSetting,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onCompanyOptionsFetch: (intl: IntlShape) =>
    dispatch(companyService.fetchCompanyOptions(intl)),
  onCompanySystemSelect: (selectedCompanySystem: number | null) =>
    dispatch(setSelectedSystem(selectedCompanySystem)),
  setAvailableSystems: (availableSystems: CompanySystem[]) =>
    dispatch(setAvailableSystems(availableSystems)),
  onCompanySelect: (
    selectCompanyId: number,
    availableSystems: CompanySystem[],
    companyLogo: Asset | null,
  ) =>
    dispatch(
      setSelectedCompany(selectCompanyId, availableSystems, companyLogo),
    ),
  onSetSetting: (type: UserSettingType, value: string) =>
    dispatch(
      userSettingService.setUserSetting({
        type,
        value,
      }),
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
