import * as actionTypes from './actionTypes';
import { EmployeeAccidentActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { ListResults } from '../../common/List/List';
import { EmployeeAccident } from '../../domain/EmployeeAccident';

export type EmployeeAccidentsStateType = {
  employeeAccidents: ListResults<EmployeeAccident> | null;
  employeeAccidentsLoading: boolean;
  employeeAccidentsError: HttpError;
  pendingAccident: EmployeeAccident | null;
  pendingAccidentLoading: boolean;
  pendingAccidentError: HttpError;
  employeeAccidentsListUpdateNeeded: boolean;
  acknowledgeAccidentsLoading: boolean;
  acknowledgeAccidentsSuccess: boolean;
  acknowledgeAccidentsError: HttpError;
  prepareToSignAccidentsLoading: boolean;
  prepareToSignAccidentsSuccess: boolean;
  prepareToSignAccidentsError: HttpError;
  signAccidentsLoading: boolean;
  signAccidentsSuccess: boolean;
  signAccidentsError: HttpError;
};

export type EmployeeAccidentsActionType = EmployeeAccidentsStateType & {
  type: EmployeeAccidentActionTypes;
};

export const initialState: EmployeeAccidentsStateType = {
  employeeAccidents: null,
  employeeAccidentsLoading: false,
  employeeAccidentsError: null,
  pendingAccident: null,
  pendingAccidentLoading: false,
  pendingAccidentError: null,
  employeeAccidentsListUpdateNeeded: false,
  acknowledgeAccidentsError: null,
  acknowledgeAccidentsLoading: false,
  acknowledgeAccidentsSuccess: false,
  prepareToSignAccidentsSuccess: false,
  prepareToSignAccidentsError: null,
  prepareToSignAccidentsLoading: false,
  signAccidentsSuccess: false,
  signAccidentsLoading: false,
  signAccidentsError: null,
};

const fetchAccidentsStart = (
  state: EmployeeAccidentsStateType,
): EmployeeAccidentsStateType => ({
  ...state,
  employeeAccidentsLoading: true,
});

const fetchAccidentsSuccess = (
  state: EmployeeAccidentsStateType,
  action: EmployeeAccidentsActionType,
): EmployeeAccidentsStateType => ({
  ...state,
  employeeAccidents: action.employeeAccidents,
  employeeAccidentsLoading: false,
  employeeAccidentsError: null,
  employeeAccidentsListUpdateNeeded: false,
});

const fetchAccidentsFail = (
  state: EmployeeAccidentsStateType,
  action: EmployeeAccidentsActionType,
): EmployeeAccidentsStateType => ({
  ...state,
  employeeAccidentsError: action.employeeAccidentsError,
  employeeAccidentsLoading: false,
});

const fetchPendingAccidentStart = (
  state: EmployeeAccidentsStateType,
): EmployeeAccidentsStateType => ({
  ...state,
  pendingAccidentLoading: true,
  acknowledgeAccidentsLoading: false,
  signAccidentsLoading: false,
});

const fetchPendingAccidentSuccess = (
  state: EmployeeAccidentsStateType,
  action: EmployeeAccidentsActionType,
): EmployeeAccidentsStateType => ({
  ...state,
  pendingAccident: action.pendingAccident,
  pendingAccidentLoading: false,
  pendingAccidentError: null,
});

const fetchPendingAccidentFail = (
  state: EmployeeAccidentsStateType,
  action: EmployeeAccidentsActionType,
): EmployeeAccidentsStateType => ({
  ...state,
  pendingAccidentError: action.pendingAccidentError,
  pendingAccidentLoading: false,
});

const acknowledgeAccidentsStart = (
  state: EmployeeAccidentsStateType,
): EmployeeAccidentsStateType => ({
  ...state,
  acknowledgeAccidentsLoading: true,
  acknowledgeAccidentsSuccess: false,
});

const acknowledgeAccidentsSuccess = (
  state: EmployeeAccidentsStateType,
): EmployeeAccidentsStateType => ({
  ...state,
  acknowledgeAccidentsSuccess: true,
  acknowledgeAccidentsLoading: false,
  acknowledgeAccidentsError: null,
});

const acknowledgeAccidentsFail = (
  state: EmployeeAccidentsStateType,
  action: EmployeeAccidentsActionType,
): EmployeeAccidentsStateType => ({
  ...state,
  acknowledgeAccidentsError: action.acknowledgeAccidentsError,
  acknowledgeAccidentsLoading: false,
});

const prepareToSignAccidentsStart = (
  state: EmployeeAccidentsStateType,
): EmployeeAccidentsStateType => ({
  ...state,
  prepareToSignAccidentsLoading: true,
  prepareToSignAccidentsSuccess: false,
});

const prepareToSignAccidentsSuccess = (
  state: EmployeeAccidentsStateType,
): EmployeeAccidentsStateType => ({
  ...state,
  prepareToSignAccidentsLoading: false,
  prepareToSignAccidentsError: null,
  prepareToSignAccidentsSuccess: true,
});

const prepareToSignAccidentsFail = (
  state: EmployeeAccidentsStateType,
  action: EmployeeAccidentsActionType,
): EmployeeAccidentsStateType => ({
  ...state,
  prepareToSignAccidentsLoading: false,
  prepareToSignAccidentsError: action.prepareToSignAccidentsError,
});

const signAccidentsStart = (
  state: EmployeeAccidentsStateType,
): EmployeeAccidentsStateType => ({
  ...state,
  signAccidentsLoading: true,
  signAccidentsSuccess: false,
});

const signAccidentsSuccess = (
  state: EmployeeAccidentsStateType,
): EmployeeAccidentsStateType => ({
  ...state,
  signAccidentsLoading: false,
  signAccidentsError: null,
  signAccidentsSuccess: true,
  employeeAccidentsListUpdateNeeded: true,
});

const signAccidentsFail = (
  state: EmployeeAccidentsStateType,
  action: EmployeeAccidentsActionType,
): EmployeeAccidentsStateType => ({
  ...state,
  signAccidentsLoading: false,
  signAccidentsError: action.signAccidentsError,
});

const resetAccidentsStore = (): EmployeeAccidentsStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: EmployeeAccidentsActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_ACCIDENTS_START:
      return fetchAccidentsStart(state);
    case actionTypes.FETCH_ACCIDENTS_SUCCESS:
      return fetchAccidentsSuccess(state, action);
    case actionTypes.FETCH_ACCIDENTS_FAIL:
      return fetchAccidentsFail(state, action);
    case actionTypes.FETCH_PENDING_ACCIDENT_START:
      return fetchPendingAccidentStart(state);
    case actionTypes.FETCH_PENDING_ACCIDENT_SUCCESS:
      return fetchPendingAccidentSuccess(state, action);
    case actionTypes.FETCH_PENDING_ACCIDENT_FAIL:
      return fetchPendingAccidentFail(state, action);
    case actionTypes.ACKNOWLEDGE_ACCIDENTS_START:
      return acknowledgeAccidentsStart(state);
    case actionTypes.ACKNOWLEDGE_ACCIDENTS_SUCCESS:
      return acknowledgeAccidentsSuccess(state);
    case actionTypes.ACKNOWLEDGE_ACCIDENTS_FAIL:
      return acknowledgeAccidentsFail(state, action);
    case actionTypes.PREPARE_TO_SIGN_ACCIDENTS_START:
      return prepareToSignAccidentsStart(state);
    case actionTypes.PREPARE_TO_SIGN_ACCIDENTS_SUCCESS:
      return prepareToSignAccidentsSuccess(state);
    case actionTypes.PREPARE_TO_SIGN_ACCIDENTS_FAIL:
      return prepareToSignAccidentsFail(state, action);
    case actionTypes.SIGN_ACCIDENTS_START:
      return signAccidentsStart(state);
    case actionTypes.SIGN_ACCIDENTS_SUCCESS:
      return signAccidentsSuccess(state);
    case actionTypes.SIGN_ACCIDENTS_FAIL:
      return signAccidentsFail(state, action);
    case actionTypes.RESET_ACCIDENTS_STORE:
      return resetAccidentsStore();
    default:
      return state;
  }
};

export default reducer;
