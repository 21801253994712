export const FETCH_LANGUAGE_START = 'FETCH_LANGUAGE_START';
export const FETCH_LANGUAGE_SUCCESS = 'FETCH_LANGUAGE_SUCCESS';
export const FETCH_LANGUAGE_FAIL = 'FETCH_LANGUAGE_FAIL';

export const FETCH_LANGUAGES_START = 'FETCH_LANGUAGES_START';
export const FETCH_LANGUAGES_SUCCESS = 'FETCH_LANGUAGES_SUCCESS';
export const FETCH_LANGUAGES_FAIL = 'FETCH_LANGUAGES_FAIL';

export type LanguageActionTypes =
  | typeof FETCH_LANGUAGE_START
  | typeof FETCH_LANGUAGE_SUCCESS
  | typeof FETCH_LANGUAGE_FAIL
  | typeof FETCH_LANGUAGES_START
  | typeof FETCH_LANGUAGES_SUCCESS
  | typeof FETCH_LANGUAGES_FAIL;
