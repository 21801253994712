import * as actionTypes from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { ListResults } from '../../common/List/List';
import { CompanyRiskGroup } from '../../domain/CompanyRiskGroup';
import { CompanyRiskGroupActionTypes } from './actionTypes';

export type CompanyRiskGroupStateType = {
  companyRiskGroupsList: ListResults<CompanyRiskGroup> | null;
  companyRiskGroupsLoading: boolean;
  companyRiskGroupsError: HttpError;
  companyRiskGroupsListUpdateNeeded: boolean;
  companyRiskGroupCreateLoading: boolean;
  companyRiskGroupCreateError: HttpError;
  companyRiskGroupUpdateLoading: boolean;
  companyRiskGroupUpdateError: HttpError;
  companyRiskGroupDeleteLoading: boolean;
  companyRiskGroupDeleteError: HttpError;
};

export type CompanyRiskGroupActionType = CompanyRiskGroupStateType & {
  type: CompanyRiskGroupActionTypes;
};

export const initialState: CompanyRiskGroupStateType = {
  companyRiskGroupsList: null,
  companyRiskGroupsLoading: false,
  companyRiskGroupsListUpdateNeeded: false,
  companyRiskGroupsError: null,
  companyRiskGroupCreateLoading: false,
  companyRiskGroupCreateError: null,
  companyRiskGroupUpdateLoading: false,
  companyRiskGroupUpdateError: null,
  companyRiskGroupDeleteLoading: false,
  companyRiskGroupDeleteError: null,
};

const fetchCompanyRiskGroupsStart = (
  state: CompanyRiskGroupStateType,
): CompanyRiskGroupStateType => ({
  ...state,
  companyRiskGroupsLoading: true,
});

const fetchCompanyRiskGroupsSuccess = (
  state: CompanyRiskGroupStateType,
  action: CompanyRiskGroupActionType,
): CompanyRiskGroupStateType => ({
  ...state,
  companyRiskGroupsList: action.companyRiskGroupsList,
  companyRiskGroupsLoading: false,
  companyRiskGroupsError: null,
  companyRiskGroupsListUpdateNeeded: false,
});

const fetchCompanyRiskGroupsFail = (
  state: CompanyRiskGroupStateType,
  action: CompanyRiskGroupActionType,
): CompanyRiskGroupStateType => ({
  ...state,
  companyRiskGroupsError: action.companyRiskGroupsError,
  companyRiskGroupsLoading: false,
});

const createCompanyRiskGroupStart = (
  state: CompanyRiskGroupStateType,
): CompanyRiskGroupStateType => ({
  ...state,
  companyRiskGroupCreateLoading: true,
});

const createCompanyRiskGroupSuccess = (
  state: CompanyRiskGroupStateType,
): CompanyRiskGroupStateType => ({
  ...state,
  companyRiskGroupCreateLoading: false,
  companyRiskGroupCreateError: null,
  companyRiskGroupsListUpdateNeeded: true,
});

const createCompanyRiskGroupFail = (
  state: CompanyRiskGroupStateType,
  action: CompanyRiskGroupActionType,
): CompanyRiskGroupStateType => ({
  ...state,
  companyRiskGroupCreateLoading: false,
  companyRiskGroupCreateError: action.companyRiskGroupCreateError,
});

const updateCompanyRiskGroupStart = (
  state: CompanyRiskGroupStateType,
): CompanyRiskGroupStateType => ({
  ...state,
  companyRiskGroupUpdateLoading: true,
});

const updateCompanyRiskGroupSuccess = (
  state: CompanyRiskGroupStateType,
): CompanyRiskGroupStateType => ({
  ...state,
  companyRiskGroupUpdateLoading: false,
  companyRiskGroupUpdateError: null,
  companyRiskGroupsListUpdateNeeded: true,
});

const updateCompanyRiskGroupFail = (
  state: CompanyRiskGroupStateType,
  action: CompanyRiskGroupActionType,
): CompanyRiskGroupStateType => ({
  ...state,
  companyRiskGroupUpdateLoading: false,
  companyRiskGroupUpdateError: action.companyRiskGroupUpdateError,
});

const deleteCompanyRiskGroupStart = (
  state: CompanyRiskGroupStateType,
): CompanyRiskGroupStateType => ({
  ...state,
  companyRiskGroupDeleteLoading: true,
});

const deleteCompanyRiskGroupSuccess = (
  state: CompanyRiskGroupStateType,
): CompanyRiskGroupStateType => ({
  ...state,
  companyRiskGroupDeleteLoading: false,
  companyRiskGroupDeleteError: null,
  companyRiskGroupsListUpdateNeeded: true,
});

const deleteCompanyRiskGroupFail = (
  state: CompanyRiskGroupStateType,
  action: CompanyRiskGroupActionType,
): CompanyRiskGroupStateType => ({
  ...state,
  companyRiskGroupDeleteLoading: false,
  companyRiskGroupDeleteError: action.companyRiskGroupDeleteError,
});

const resetCompanyRiskGroupStore = (): CompanyRiskGroupStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: CompanyRiskGroupActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_COMPANY_RISK_GROUPS_START:
      return fetchCompanyRiskGroupsStart(state);
    case actionTypes.FETCH_COMPANY_RISK_GROUPS_SUCCESS:
      return fetchCompanyRiskGroupsSuccess(state, action);
    case actionTypes.FETCH_COMPANY_RISK_GROUPS_FAIL:
      return fetchCompanyRiskGroupsFail(state, action);
    case actionTypes.CREATE_COMPANY_RISK_GROUP_START:
      return createCompanyRiskGroupStart(state);
    case actionTypes.CREATE_COMPANY_RISK_GROUP_SUCCESS:
      return createCompanyRiskGroupSuccess(state);
    case actionTypes.CREATE_COMPANY_RISK_GROUP_FAIL:
      return createCompanyRiskGroupFail(state, action);
    case actionTypes.UPDATE_COMPANY_RISK_GROUP_START:
      return updateCompanyRiskGroupStart(state);
    case actionTypes.UPDATE_COMPANY_RISK_GROUP_SUCCESS:
      return updateCompanyRiskGroupSuccess(state);
    case actionTypes.UPDATE_COMPANY_RISK_GROUP_FAIL:
      return updateCompanyRiskGroupFail(state, action);
    case actionTypes.DELETE_COMPANY_RISK_GROUP_START:
      return deleteCompanyRiskGroupStart(state);
    case actionTypes.DELETE_COMPANY_RISK_GROUP_SUCCESS:
      return deleteCompanyRiskGroupSuccess(state);
    case actionTypes.DELETE_COMPANY_RISK_GROUP_FAIL:
      return deleteCompanyRiskGroupFail(state, action);
    case actionTypes.RESET_COMPANY_RISK_GROUP_STORE:
      return resetCompanyRiskGroupStore();
    default:
      return state;
  }
};

export default reducer;
