import React, { ReactNode } from 'react';
import styles from './FormCard.module.scss';

type Props = {
  children: ReactNode;
};

export const FormCard = ({ children }: Props) => {
  return <div className={styles.formCard}>{children}</div>;
};

export default FormCard;
