import * as actionTypes from './actionTypes';
import { MeetingActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { ListResults } from '../../common/List/List';
import { Meeting } from '../../domain/Meeting';

export type MeetingStateType = {
  meetingsList: ListResults<Meeting> | null;
  meetingsLoading: boolean;
  meetingsError: HttpError;
  meetingsListUpdateNeeded: boolean;
  meetingCreateLoading: boolean;
  meetingCreateError: HttpError;
  meetingUpdateLoading: boolean;
  meetingUpdateError: HttpError;
  meetingDeleteLoading: boolean;
  meetingDeleteError: HttpError;
};

export type MeetingActionType = MeetingStateType & {
  type: MeetingActionTypes;
};

export const initialState: MeetingStateType = {
  meetingsList: null,
  meetingsLoading: false,
  meetingsListUpdateNeeded: false,
  meetingsError: null,
  meetingCreateLoading: false,
  meetingCreateError: null,
  meetingUpdateLoading: false,
  meetingUpdateError: null,
  meetingDeleteLoading: false,
  meetingDeleteError: null,
};

const fetchMeetingsStart = (state: MeetingStateType): MeetingStateType => ({
  ...state,
  meetingsLoading: true,
});

const fetchMeetingsSuccess = (
  state: MeetingStateType,
  action: MeetingActionType,
): MeetingStateType => ({
  ...state,
  meetingsList: action.meetingsList,
  meetingsLoading: false,
  meetingsError: null,
  meetingsListUpdateNeeded: false,
});

const fetchMeetingsFail = (
  state: MeetingStateType,
  action: MeetingActionType,
): MeetingStateType => ({
  ...state,
  meetingsError: action.meetingsError,
  meetingsLoading: false,
});

const createMeetingStart = (state: MeetingStateType): MeetingStateType => ({
  ...state,
  meetingCreateLoading: true,
});

const createMeetingSuccess = (state: MeetingStateType): MeetingStateType => ({
  ...state,
  meetingCreateLoading: false,
  meetingCreateError: null,
  meetingsListUpdateNeeded: true,
});

const createMeetingFail = (
  state: MeetingStateType,
  action: MeetingActionType,
): MeetingStateType => ({
  ...state,
  meetingCreateLoading: false,
  meetingCreateError: action.meetingCreateError,
});

const updateMeetingStart = (state: MeetingStateType): MeetingStateType => ({
  ...state,
  meetingUpdateLoading: true,
});

const updateMeetingSuccess = (state: MeetingStateType): MeetingStateType => ({
  ...state,
  meetingUpdateLoading: false,
  meetingUpdateError: null,
  meetingsListUpdateNeeded: true,
});

const updateMeetingFail = (
  state: MeetingStateType,
  action: MeetingActionType,
): MeetingStateType => ({
  ...state,
  meetingUpdateLoading: false,
  meetingUpdateError: action.meetingUpdateError,
});

const deleteMeetingStart = (state: MeetingStateType): MeetingStateType => ({
  ...state,
  meetingDeleteLoading: true,
});

const deleteMeetingSuccess = (state: MeetingStateType): MeetingStateType => ({
  ...state,
  meetingDeleteLoading: false,
  meetingDeleteError: null,
  meetingsListUpdateNeeded: true,
});

const deleteMeetingFail = (
  state: MeetingStateType,
  action: MeetingActionType,
): MeetingStateType => ({
  ...state,
  meetingDeleteLoading: false,
  meetingDeleteError: action.meetingDeleteError,
});

const resetMeetingStore = (): MeetingStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: MeetingActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_MEETINGS_START:
      return fetchMeetingsStart(state);
    case actionTypes.FETCH_MEETINGS_SUCCESS:
      return fetchMeetingsSuccess(state, action);
    case actionTypes.FETCH_MEETINGS_FAIL:
      return fetchMeetingsFail(state, action);
    case actionTypes.CREATE_MEETING_START:
      return createMeetingStart(state);
    case actionTypes.CREATE_MEETING_SUCCESS:
      return createMeetingSuccess(state);
    case actionTypes.CREATE_MEETING_FAIL:
      return createMeetingFail(state, action);
    case actionTypes.UPDATE_MEETING_START:
      return updateMeetingStart(state);
    case actionTypes.UPDATE_MEETING_SUCCESS:
      return updateMeetingSuccess(state);
    case actionTypes.UPDATE_MEETING_FAIL:
      return updateMeetingFail(state, action);
    case actionTypes.DELETE_MEETING_START:
      return deleteMeetingStart(state);
    case actionTypes.DELETE_MEETING_SUCCESS:
      return deleteMeetingSuccess(state);
    case actionTypes.DELETE_MEETING_FAIL:
      return deleteMeetingFail(state, action);
    case actionTypes.RESET_MEETING_STORE:
      return resetMeetingStore();
    default:
      return state;
  }
};

export default reducer;
