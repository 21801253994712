export const FETCH_RISK_GROUPS_START = 'FETCH_RISK_GROUPS_START';
export const FETCH_RISK_GROUPS_SUCCESS = 'FETCH_RISK_GROUPS_SUCCESS';
export const FETCH_RISK_GROUPS_FAIL = 'FETCH_RISK_GROUPS_FAIL';

export const CREATE_RISK_GROUP_START = 'CREATE_RISK_GROUP_START';
export const CREATE_RISK_GROUP_SUCCESS = 'CREATE_RISK_GROUP_SUCCESS';
export const CREATE_RISK_GROUP_FAIL = 'CREATE_RISK_GROUP_FAIL';

export const UPDATE_RISK_GROUP_START = 'UPDATE_RISK_GROUP_START';
export const UPDATE_RISK_GROUP_SUCCESS = 'UPDATE_RISK_GROUP_SUCCESS';
export const UPDATE_RISK_GROUP_FAIL = 'UPDATE_RISK_GROUP_FAIL';

export const DELETE_RISK_GROUP_START = 'DELETE_RISK_GROUP_START';
export const DELETE_RISK_GROUP_SUCCESS = 'DELETE_RISK_GROUP_SUCCESS';
export const DELETE_RISK_GROUP_FAIL = 'DELETE_RISK_GROUP_FAIL';

export const RESET_RISK_GROUP_STORE = 'RESET_RISK_GROUP_STORE';

export type RiskGroupActionTypes =
  | typeof FETCH_RISK_GROUPS_START
  | typeof FETCH_RISK_GROUPS_SUCCESS
  | typeof FETCH_RISK_GROUPS_FAIL
  | typeof CREATE_RISK_GROUP_START
  | typeof CREATE_RISK_GROUP_SUCCESS
  | typeof CREATE_RISK_GROUP_FAIL
  | typeof UPDATE_RISK_GROUP_START
  | typeof UPDATE_RISK_GROUP_SUCCESS
  | typeof UPDATE_RISK_GROUP_FAIL
  | typeof DELETE_RISK_GROUP_START
  | typeof DELETE_RISK_GROUP_SUCCESS
  | typeof DELETE_RISK_GROUP_FAIL
  | typeof RESET_RISK_GROUP_STORE;
