import * as actionTypes from './actionTypes';
import { QuestionActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { ListResults } from '../../common/List/List';
import { Question } from '../../domain/Question';

export type QuestionStateType = {
  questionsList: ListResults<Question> | null;
  questionsLoading: boolean;
  questionsError: HttpError;
  questionsListUpdateNeeded: boolean;
  questionCreateLoading: boolean;
  questionCreateError: HttpError;
  questionUpdateLoading: boolean;
  questionUpdateError: HttpError;
  questionDeleteLoading: boolean;
  questionDeleteError: HttpError;
  questionsPriorityUpdateLoading: boolean;
  questionsPriorityUpdateError: HttpError;
  questionsPriorityUpdateSuccess: boolean;
};

export type QuestionActionType = QuestionStateType & {
  type: QuestionActionTypes;
};

export const initialState: QuestionStateType = {
  questionsList: null,
  questionsLoading: false,
  questionsListUpdateNeeded: false,
  questionsError: null,
  questionCreateLoading: false,
  questionCreateError: null,
  questionUpdateLoading: false,
  questionUpdateError: null,
  questionDeleteLoading: false,
  questionDeleteError: null,
  questionsPriorityUpdateLoading: false,
  questionsPriorityUpdateError: null,
  questionsPriorityUpdateSuccess: false,
};

const fetchQuestionsStart = (state: QuestionStateType): QuestionStateType => ({
  ...state,
  questionsLoading: true,
});

const fetchQuestionsSuccess = (
  state: QuestionStateType,
  action: QuestionActionType,
): QuestionStateType => ({
  ...state,
  questionsList: action.questionsList,
  questionsLoading: false,
  questionsError: null,
  questionsListUpdateNeeded: false,
});

const fetchQuestionsFail = (
  state: QuestionStateType,
  action: QuestionActionType,
): QuestionStateType => ({
  ...state,
  questionsError: action.questionsError,
  questionsLoading: false,
});

const createQuestionStart = (state: QuestionStateType): QuestionStateType => ({
  ...state,
  questionCreateLoading: true,
  questionsPriorityUpdateSuccess: false,
});

const createQuestionSuccess = (
  state: QuestionStateType,
): QuestionStateType => ({
  ...state,
  questionCreateLoading: false,
  questionCreateError: null,
  questionsListUpdateNeeded: true,
});

const createQuestionFail = (
  state: QuestionStateType,
  action: QuestionActionType,
): QuestionStateType => ({
  ...state,
  questionCreateLoading: false,
  questionCreateError: action.questionCreateError,
});

const updateQuestionStart = (state: QuestionStateType): QuestionStateType => ({
  ...state,
  questionUpdateLoading: true,
  questionsPriorityUpdateSuccess: false,
});

const updateQuestionSuccess = (
  state: QuestionStateType,
): QuestionStateType => ({
  ...state,
  questionUpdateLoading: false,
  questionUpdateError: null,
  questionsListUpdateNeeded: true,
});

const updateQuestionFail = (
  state: QuestionStateType,
  action: QuestionActionType,
): QuestionStateType => ({
  ...state,
  questionUpdateLoading: false,
  questionUpdateError: action.questionUpdateError,
});

const deleteQuestionStart = (state: QuestionStateType): QuestionStateType => ({
  ...state,
  questionDeleteLoading: true,
  questionsPriorityUpdateSuccess: false,
});

const deleteQuestionSuccess = (
  state: QuestionStateType,
): QuestionStateType => ({
  ...state,
  questionDeleteLoading: false,
  questionDeleteError: null,
  questionsListUpdateNeeded: true,
});

const deleteQuestionFail = (
  state: QuestionStateType,
  action: QuestionActionType,
): QuestionStateType => ({
  ...state,
  questionDeleteLoading: false,
  questionDeleteError: action.questionDeleteError,
});

const updateQuestionsPriorityStart = (
  state: QuestionStateType,
): QuestionStateType => ({
  ...state,
  questionsPriorityUpdateLoading: true,
  questionsPriorityUpdateSuccess: false,
});

const updateQuestionsPrioritySuccess = (
  state: QuestionStateType,
): QuestionStateType => ({
  ...state,
  questionsPriorityUpdateError: null,
  questionsPriorityUpdateSuccess: true,
  questionsListUpdateNeeded: true,
});

const updateQuestionsPriorityFail = (
  state: QuestionStateType,
  action: QuestionActionType,
): QuestionStateType => ({
  ...state,
  questionsPriorityUpdateLoading: false,
  questionsPriorityUpdateError: action.questionsPriorityUpdateError,
});

const resetQuestionStore = (): QuestionStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: QuestionActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_QUESTIONS_START:
      return fetchQuestionsStart(state);
    case actionTypes.FETCH_QUESTIONS_SUCCESS:
      return fetchQuestionsSuccess(state, action);
    case actionTypes.FETCH_QUESTIONS_FAIL:
      return fetchQuestionsFail(state, action);
    case actionTypes.CREATE_QUESTION_START:
      return createQuestionStart(state);
    case actionTypes.CREATE_QUESTION_SUCCESS:
      return createQuestionSuccess(state);
    case actionTypes.CREATE_QUESTION_FAIL:
      return createQuestionFail(state, action);
    case actionTypes.UPDATE_QUESTION_START:
      return updateQuestionStart(state);
    case actionTypes.UPDATE_QUESTION_SUCCESS:
      return updateQuestionSuccess(state);
    case actionTypes.UPDATE_QUESTION_FAIL:
      return updateQuestionFail(state, action);
    case actionTypes.DELETE_QUESTION_START:
      return deleteQuestionStart(state);
    case actionTypes.DELETE_QUESTION_SUCCESS:
      return deleteQuestionSuccess(state);
    case actionTypes.DELETE_QUESTION_FAIL:
      return deleteQuestionFail(state, action);
    case actionTypes.RESET_QUESTION_STORE:
      return resetQuestionStore();
    case actionTypes.UPDATE_QUESTIONS_PRIORITY_START:
      return updateQuestionsPriorityStart(state);
    case actionTypes.UPDATE_QUESTIONS_PRIORITY_SUCCESS:
      return updateQuestionsPrioritySuccess(state);
    case actionTypes.UPDATE_QUESTIONS_PRIORITY_FAIL:
      return updateQuestionsPriorityFail(state, action);
    default:
      return state;
  }
};

export default reducer;
