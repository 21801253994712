import * as actionTypes from './actionTypes';
import { InstructionActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';

export type InstructionStateType = {
  instructionsListUpdateNeeded: boolean;
  instructionCreateLoading: boolean;
  instructionCreateError: HttpError;
  instructionUpdateLoading: boolean;
  instructionUpdateError: HttpError;
  instructionDeleteLoading: boolean;
  instructionDeleteError: HttpError;
};

export type InstructionActionType = InstructionStateType & {
  type: InstructionActionTypes;
};

export const initialState: InstructionStateType = {
  instructionsListUpdateNeeded: false,
  instructionCreateLoading: false,
  instructionCreateError: null,
  instructionUpdateLoading: false,
  instructionUpdateError: null,
  instructionDeleteLoading: false,
  instructionDeleteError: null,
};

const createInstructionStart = (
  state: InstructionStateType,
): InstructionStateType => ({
  ...state,
  instructionCreateLoading: true,
});

const createInstructionSuccess = (
  state: InstructionStateType,
): InstructionStateType => ({
  ...state,
  instructionCreateLoading: false,
  instructionCreateError: null,
  instructionsListUpdateNeeded: true,
});

const createInstructionFail = (
  state: InstructionStateType,
  action: InstructionActionType,
): InstructionStateType => ({
  ...state,
  instructionCreateLoading: false,
  instructionCreateError: action.instructionCreateError,
});

const updateInstructionStart = (
  state: InstructionStateType,
): InstructionStateType => ({
  ...state,
  instructionUpdateLoading: true,
});

const updateInstructionSuccess = (
  state: InstructionStateType,
): InstructionStateType => ({
  ...state,
  instructionUpdateLoading: false,
  instructionUpdateError: null,
  instructionsListUpdateNeeded: true,
});

const updateInstructionFail = (
  state: InstructionStateType,
  action: InstructionActionType,
): InstructionStateType => ({
  ...state,
  instructionUpdateLoading: false,
  instructionUpdateError: action.instructionUpdateError,
});

const deleteInstructionStart = (
  state: InstructionStateType,
): InstructionStateType => ({
  ...state,
  instructionDeleteLoading: true,
});

const deleteInstructionSuccess = (
  state: InstructionStateType,
): InstructionStateType => ({
  ...state,
  instructionDeleteLoading: false,
  instructionDeleteError: null,
  instructionsListUpdateNeeded: true,
});

const deleteInstructionFail = (
  state: InstructionStateType,
  action: InstructionActionType,
): InstructionStateType => ({
  ...state,
  instructionDeleteLoading: false,
  instructionDeleteError: action.instructionDeleteError,
});

const resetInstructionStore = (): InstructionStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: InstructionActionType) => {
  switch (action.type) {
    case actionTypes.CREATE_INSTRUCTION_START:
      return createInstructionStart(state);
    case actionTypes.CREATE_INSTRUCTION_SUCCESS:
      return createInstructionSuccess(state);
    case actionTypes.CREATE_INSTRUCTION_FAIL:
      return createInstructionFail(state, action);
    case actionTypes.UPDATE_INSTRUCTION_START:
      return updateInstructionStart(state);
    case actionTypes.UPDATE_INSTRUCTION_SUCCESS:
      return updateInstructionSuccess(state);
    case actionTypes.UPDATE_INSTRUCTION_FAIL:
      return updateInstructionFail(state, action);
    case actionTypes.DELETE_INSTRUCTION_START:
      return deleteInstructionStart(state);
    case actionTypes.DELETE_INSTRUCTION_SUCCESS:
      return deleteInstructionSuccess(state);
    case actionTypes.DELETE_INSTRUCTION_FAIL:
      return deleteInstructionFail(state, action);
    case actionTypes.RESET_INSTRUCTION_STORE:
      return resetInstructionStore();
    default:
      return state;
  }
};

export default reducer;
