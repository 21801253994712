import * as actionTypes from './actionTypes';
import { CompanyActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { Company, CompanySystem, ExportData } from '../../domain/Company';
import { ListResults } from '../../common/List/List';
import { Asset } from '../../domain/Asset';

export type CompanyStateType = {
  companiesList: ListResults<Company> | null;
  companiesLoading: boolean;
  companiesError: HttpError;
  companyOptions: Company[];
  companyOptionsLoading: boolean;
  companyOptionsError: HttpError;
  companiesListUpdateNeeded: boolean;
  companyCreateLoading: boolean;
  companyCreateError: HttpError;
  companyCreateSuccess: boolean;
  createdCompany: Company | null;
  companyUpdateLoading: boolean;
  companyUpdateError: HttpError;
  companyUpdateSuccess: boolean;
  company: Company | null;
  companyLoading: boolean;
  companyError: HttpError;
  selectedCompany: number | null;
  companyLogo: Asset | null;
  availableSystems: CompanySystem[] | null;
  selectedSystem: CompanySystem | null;
  exportData: ExportData | null;
  exportDataLoading: boolean;
  exportDataError: HttpError;
};

export type CompanyActionType = CompanyStateType & {
  type: CompanyActionTypes;
};

export const initialState: CompanyStateType = {
  companiesList: null,
  companiesLoading: true,
  companiesError: null,
  companyOptions: [],
  companyOptionsLoading: true,
  companyOptionsError: null,
  companiesListUpdateNeeded: false,
  companyCreateLoading: false,
  companyCreateError: null,
  companyCreateSuccess: false,
  createdCompany: null,
  companyUpdateLoading: false,
  companyUpdateError: null,
  companyUpdateSuccess: false,
  company: null,
  companyLoading: false,
  companyError: null,
  availableSystems: localStorage.getItem('availableSystems')
    ? (JSON.parse(
        localStorage.getItem('availableSystems') ?? '',
      ) as CompanySystem[])
    : null,
  selectedCompany: null,
  selectedSystem: localStorage.getItem('selectedSystem')
    ? Number(localStorage.getItem('selectedSystem'))
    : null,
  companyLogo: localStorage.getItem('companyLogo')
    ? (JSON.parse(localStorage.getItem('companyLogo') ?? '') as Asset)
    : null,
  exportData: null,
  exportDataError: null,
  exportDataLoading: false,
};

const fetchCompaniesStart = (state: CompanyStateType): CompanyStateType => ({
  ...state,
  companiesLoading: true,
});

const fetchCompaniesSuccess = (
  state: CompanyStateType,
  action: CompanyActionType,
): CompanyStateType => ({
  ...state,
  companiesList: action.companiesList,
  companiesLoading: false,
  companiesError: null,
  companiesListUpdateNeeded: false,
});

const fetchCompaniesFail = (
  state: CompanyStateType,
  action: CompanyActionType,
): CompanyStateType => ({
  ...state,
  companiesError: action.companiesError,
  companiesLoading: false,
});

const fetchCompanyOptionsStart = (
  state: CompanyStateType,
): CompanyStateType => ({
  ...state,
  companyOptionsLoading: true,
});

const fetchCompanyOptionsSuccess = (
  state: CompanyStateType,
  action: CompanyActionType,
): CompanyStateType => ({
  ...state,
  companyOptions: action.companyOptions,
  companyOptionsLoading: false,
  companyOptionsError: null,
});

const fetchCompanyOptionsFail = (
  state: CompanyStateType,
  action: CompanyActionType,
): CompanyStateType => ({
  ...state,
  companyOptionsError: action.companyOptionsError,
  companyOptionsLoading: false,
});

const fetchCompanyStart = (state: CompanyStateType): CompanyStateType => ({
  ...state,
  companyLoading: true,
  companyUpdateSuccess: false,
  companyCreateSuccess: false,
  companyCreateError: null,
  companyUpdateError: null,
});

const fetchCompanySuccess = (
  state: CompanyStateType,
  action: CompanyActionType,
): CompanyStateType => ({
  ...state,
  company: action.company,
  companyLoading: false,
  companyError: null,
});

const fetchCompanyFail = (
  state: CompanyStateType,
  action: CompanyActionType,
): CompanyStateType => ({
  ...state,
  companyError: action.companyError,
  companyLoading: false,
});

const createCompanyStart = (state: CompanyStateType): CompanyStateType => ({
  ...state,
  companyCreateLoading: true,
});

const createCompanySuccess = (
  state: CompanyStateType,
  action: CompanyActionType,
): CompanyStateType => ({
  ...state,
  companyCreateLoading: false,
  companyCreateError: null,
  companyCreateSuccess: true,
  createdCompany: action.createdCompany,
  companiesListUpdateNeeded: true,
});

const createCompanyFail = (
  state: CompanyStateType,
  action: CompanyActionType,
): CompanyStateType => ({
  ...state,
  companyCreateLoading: false,
  companyCreateError: action.companyCreateError,
});

const updateCompanyStart = (state: CompanyStateType): CompanyStateType => ({
  ...state,
  companyUpdateLoading: true,
});

const updateCompanySuccess = (state: CompanyStateType): CompanyStateType => ({
  ...state,
  companyUpdateLoading: false,
  companyUpdateError: null,
  companyUpdateSuccess: true,
  companiesListUpdateNeeded: true,
});

const updateCompanyFail = (
  state: CompanyStateType,
  action: CompanyActionType,
): CompanyStateType => ({
  ...state,
  companyUpdateLoading: false,
  companyUpdateError: action.companyUpdateError,
});

const exportDataStart = (state: CompanyStateType): CompanyStateType => ({
  ...state,
  exportDataLoading: true,
  exportDataError: null,
});

const exportDataSuccess = (
  state: CompanyStateType,
  action: CompanyActionType,
): CompanyStateType => ({
  ...state,
  exportDataLoading: false,
  exportDataError: null,
  exportData: action.exportData,
});

const exportDataFail = (
  state: CompanyStateType,
  action: CompanyActionType,
): CompanyStateType => ({
  ...state,
  exportDataError: action.exportDataError,
  exportDataLoading: false,
});

const setSelectedCompany = (
  state: CompanyStateType,
  action: CompanyActionType,
): CompanyStateType => ({
  ...state,
  selectedCompany: action.selectedCompany,
  availableSystems: action.availableSystems,
  companyLogo: action.companyLogo,
});

const setSelectedCompanyId = (
  state: CompanyStateType,
  action: CompanyActionType,
): CompanyStateType => ({
  ...state,
  selectedCompany: action.selectedCompany,
  companyLogo: null,
});

const setSelectedSystem = (
  state: CompanyStateType,
  action: CompanyActionType,
): CompanyStateType => ({
  ...state,
  selectedSystem: action.selectedSystem,
});

const setAvailableSystems = (
  state: CompanyStateType,
  action: CompanyActionType,
): CompanyStateType => ({
  ...state,
  availableSystems: action.availableSystems,
});

const resetExportStore = (state: CompanyStateType): CompanyStateType => ({
  ...state,
  exportDataError: null,
  exportDataLoading: false,
  exportData: null,
});

const resetCompanyStore = (state: CompanyStateType): CompanyStateType => {
  return {
    ...initialState,
    selectedCompany: state.selectedCompany,
  };
};

const reducer = (state = initialState, action: CompanyActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_COMPANIES_START:
      return fetchCompaniesStart(state);
    case actionTypes.FETCH_COMPANIES_SUCCESS:
      return fetchCompaniesSuccess(state, action);
    case actionTypes.FETCH_COMPANIES_FAIL:
      return fetchCompaniesFail(state, action);
    case actionTypes.FETCH_COMPANY_OPTIONS_START:
      return fetchCompanyOptionsStart(state);
    case actionTypes.FETCH_COMPANY_OPTIONS_SUCCESS:
      return fetchCompanyOptionsSuccess(state, action);
    case actionTypes.FETCH_COMPANY_OPTIONS_FAIL:
      return fetchCompanyOptionsFail(state, action);
    case actionTypes.FETCH_COMPANY_START:
      return fetchCompanyStart(state);
    case actionTypes.FETCH_COMPANY_SUCCESS:
      return fetchCompanySuccess(state, action);
    case actionTypes.FETCH_COMPANY_FAIL:
      return fetchCompanyFail(state, action);
    case actionTypes.CREATE_COMPANY_START:
      return createCompanyStart(state);
    case actionTypes.CREATE_COMPANY_SUCCESS:
      return createCompanySuccess(state, action);
    case actionTypes.CREATE_COMPANY_FAIL:
      return createCompanyFail(state, action);
    case actionTypes.UPDATE_COMPANY_START:
      return updateCompanyStart(state);
    case actionTypes.UPDATE_COMPANY_SUCCESS:
      return updateCompanySuccess(state);
    case actionTypes.UPDATE_COMPANY_FAIL:
      return updateCompanyFail(state, action);
    case actionTypes.FETCH_EXPORT_DATA_START:
      return exportDataStart(state);
    case actionTypes.FETCH_EXPORT_DATA_SUCCESS:
      return exportDataSuccess(state, action);
    case actionTypes.FETCH_EXPORT_DATA_FAIL:
      return exportDataFail(state, action);
    case actionTypes.SET_COMPANY_ID:
      return setSelectedCompanyId(state, action);
    case actionTypes.SET_SELECTED_COMPANY:
      return setSelectedCompany(state, action);
    case actionTypes.SET_SELECTED_SYSTEM:
      return setSelectedSystem(state, action);
    case actionTypes.SET_AVAILABLE_SYSTEMS:
      return setAvailableSystems(state, action);
    case actionTypes.RESET_EXPORT_STORE:
      return resetExportStore(state);
    case actionTypes.RESET_COMPANY_STORE:
      return resetCompanyStore(state);
    default:
      return state;
  }
};

export default reducer;
