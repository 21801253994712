import * as actionTypes from './actionTypes';
import { EmployeeInstructionActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { ListResults } from '../../common/List/List';
import { EmployeeInstruction } from '../../domain/EmployeeInstruction';

export type EmployeeInstructionsStateType = {
  employeeInstructions: ListResults<EmployeeInstruction> | null;
  employeeInstructionsLoading: boolean;
  employeeInstructionsError: HttpError;
  pendingInstructions: ListResults<EmployeeInstruction> | null;
  pendingInstructionsLoading: boolean;
  pendingInstructionsError: HttpError;
  employeeInstructionsListUpdateNeeded: boolean;
  acknowledgeInstructionsLoading: boolean;
  acknowledgeInstructionsSuccess: boolean;
  acknowledgeInstructionsError: HttpError;
  prepareToSignInstructionsLoading: boolean;
  prepareToSignInstructionsSuccess: boolean;
  prepareToSignInstructionsError: HttpError;
  signInstructionsLoading: boolean;
  signInstructionsSuccess: boolean;
  signInstructionsError: HttpError;
};

export type EmployeeInstructionsActionType = EmployeeInstructionsStateType & {
  type: EmployeeInstructionActionTypes;
};

export const initialState: EmployeeInstructionsStateType = {
  employeeInstructions: null,
  employeeInstructionsLoading: false,
  employeeInstructionsError: null,
  pendingInstructions: null,
  pendingInstructionsLoading: false,
  pendingInstructionsError: null,
  employeeInstructionsListUpdateNeeded: false,
  acknowledgeInstructionsError: null,
  acknowledgeInstructionsLoading: false,
  acknowledgeInstructionsSuccess: false,
  prepareToSignInstructionsSuccess: false,
  prepareToSignInstructionsError: null,
  prepareToSignInstructionsLoading: false,
  signInstructionsSuccess: false,
  signInstructionsLoading: false,
  signInstructionsError: null,
};

const fetchInstructionsStart = (
  state: EmployeeInstructionsStateType,
): EmployeeInstructionsStateType => ({
  ...state,
  employeeInstructionsLoading: true,
});

const fetchInstructionsSuccess = (
  state: EmployeeInstructionsStateType,
  action: EmployeeInstructionsActionType,
): EmployeeInstructionsStateType => ({
  ...state,
  employeeInstructions: action.employeeInstructions,
  employeeInstructionsLoading: false,
  employeeInstructionsError: null,
  employeeInstructionsListUpdateNeeded: false,
});

const fetchInstructionsFail = (
  state: EmployeeInstructionsStateType,
  action: EmployeeInstructionsActionType,
): EmployeeInstructionsStateType => ({
  ...state,
  employeeInstructionsError: action.employeeInstructionsError,
  employeeInstructionsLoading: false,
});

const fetchPendingInstructionsStart = (
  state: EmployeeInstructionsStateType,
): EmployeeInstructionsStateType => ({
  ...state,
  pendingInstructionsLoading: true,
  acknowledgeInstructionsLoading: false,
  signInstructionsLoading: false,
});

const fetchPendingInstructionsSuccess = (
  state: EmployeeInstructionsStateType,
  action: EmployeeInstructionsActionType,
): EmployeeInstructionsStateType => ({
  ...state,
  pendingInstructions: action.pendingInstructions,
  pendingInstructionsLoading: false,
  pendingInstructionsError: null,
});

const fetchPendingInstructionsFail = (
  state: EmployeeInstructionsStateType,
  action: EmployeeInstructionsActionType,
): EmployeeInstructionsStateType => ({
  ...state,
  pendingInstructionsError: action.pendingInstructionsError,
  pendingInstructionsLoading: false,
});

const acknowledgeInstructionsStart = (
  state: EmployeeInstructionsStateType,
): EmployeeInstructionsStateType => ({
  ...state,
  acknowledgeInstructionsLoading: true,
  acknowledgeInstructionsSuccess: false,
});

const acknowledgeInstructionsSuccess = (
  state: EmployeeInstructionsStateType,
): EmployeeInstructionsStateType => ({
  ...state,
  acknowledgeInstructionsSuccess: true,
  acknowledgeInstructionsLoading: false,
  acknowledgeInstructionsError: null,
});

const acknowledgeInstructionsFail = (
  state: EmployeeInstructionsStateType,
  action: EmployeeInstructionsActionType,
): EmployeeInstructionsStateType => ({
  ...state,
  acknowledgeInstructionsError: action.acknowledgeInstructionsError,
  acknowledgeInstructionsLoading: false,
});

const prepareToSignInstructionsStart = (
  state: EmployeeInstructionsStateType,
): EmployeeInstructionsStateType => ({
  ...state,
  prepareToSignInstructionsLoading: true,
  prepareToSignInstructionsSuccess: false,
});

const prepareToSignInstructionsSuccess = (
  state: EmployeeInstructionsStateType,
): EmployeeInstructionsStateType => ({
  ...state,
  prepareToSignInstructionsLoading: false,
  prepareToSignInstructionsError: null,
  prepareToSignInstructionsSuccess: true,
});

const prepareToSignInstructionsFail = (
  state: EmployeeInstructionsStateType,
  action: EmployeeInstructionsActionType,
): EmployeeInstructionsStateType => ({
  ...state,
  prepareToSignInstructionsLoading: false,
  prepareToSignInstructionsError: action.prepareToSignInstructionsError,
});

const signInstructionsStart = (
  state: EmployeeInstructionsStateType,
): EmployeeInstructionsStateType => ({
  ...state,
  signInstructionsLoading: true,
  signInstructionsSuccess: false,
});

const signInstructionsSuccess = (
  state: EmployeeInstructionsStateType,
): EmployeeInstructionsStateType => ({
  ...state,
  signInstructionsLoading: false,
  signInstructionsError: null,
  signInstructionsSuccess: true,
  employeeInstructionsListUpdateNeeded: true,
});

const signInstructionsFail = (
  state: EmployeeInstructionsStateType,
  action: EmployeeInstructionsActionType,
): EmployeeInstructionsStateType => ({
  ...state,
  signInstructionsLoading: false,
  signInstructionsError: action.signInstructionsError,
});

const resetSecondaryInstructionsStore = (
  state: EmployeeInstructionsStateType,
): EmployeeInstructionsStateType => ({
  ...state,
  pendingInstructions: null,
  pendingInstructionsLoading: false,
  pendingInstructionsError: null,
  employeeInstructionsListUpdateNeeded: false,
  acknowledgeInstructionsError: null,
  acknowledgeInstructionsLoading: false,
  acknowledgeInstructionsSuccess: false,
  prepareToSignInstructionsSuccess: false,
  prepareToSignInstructionsError: null,
  prepareToSignInstructionsLoading: false,
  signInstructionsSuccess: false,
  signInstructionsLoading: false,
  signInstructionsError: null,
});

const resetInstructionsStore = (): EmployeeInstructionsStateType => ({
  ...initialState,
});

const reducer = (
  state = initialState,
  action: EmployeeInstructionsActionType,
) => {
  switch (action.type) {
    case actionTypes.FETCH_INSTRUCTIONS_START:
      return fetchInstructionsStart(state);
    case actionTypes.FETCH_INSTRUCTIONS_SUCCESS:
      return fetchInstructionsSuccess(state, action);
    case actionTypes.FETCH_INSTRUCTIONS_FAIL:
      return fetchInstructionsFail(state, action);
    case actionTypes.FETCH_PENDING_INSTRUCTIONS_START:
      return fetchPendingInstructionsStart(state);
    case actionTypes.FETCH_PENDING_INSTRUCTIONS_SUCCESS:
      return fetchPendingInstructionsSuccess(state, action);
    case actionTypes.FETCH_PENDING_INSTRUCTIONS_FAIL:
      return fetchPendingInstructionsFail(state, action);
    case actionTypes.ACKNOWLEDGE_INSTRUCTIONS_START:
      return acknowledgeInstructionsStart(state);
    case actionTypes.ACKNOWLEDGE_INSTRUCTIONS_SUCCESS:
      return acknowledgeInstructionsSuccess(state);
    case actionTypes.ACKNOWLEDGE_INSTRUCTIONS_FAIL:
      return acknowledgeInstructionsFail(state, action);
    case actionTypes.PREPARE_TO_SIGN_INSTRUCTIONS_START:
      return prepareToSignInstructionsStart(state);
    case actionTypes.PREPARE_TO_SIGN_INSTRUCTIONS_SUCCESS:
      return prepareToSignInstructionsSuccess(state);
    case actionTypes.PREPARE_TO_SIGN_INSTRUCTIONS_FAIL:
      return prepareToSignInstructionsFail(state, action);
    case actionTypes.SIGN_INSTRUCTIONS_START:
      return signInstructionsStart(state);
    case actionTypes.SIGN_INSTRUCTIONS_SUCCESS:
      return signInstructionsSuccess(state);
    case actionTypes.SIGN_INSTRUCTIONS_FAIL:
      return signInstructionsFail(state, action);
    case actionTypes.RESET_SECONDARY_INSTRUCTIONS_STORE:
      return resetSecondaryInstructionsStore(state);
    case actionTypes.RESET_INSTRUCTIONS_STORE:
      return resetInstructionsStore();
    default:
      return state;
  }
};

export default reducer;
