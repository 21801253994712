export const FETCH_ACCIDENTS_START = 'FETCH_ACCIDENTS_START';
export const FETCH_ACCIDENTS_SUCCESS = 'FETCH_ACCIDENTS_SUCCESS';
export const FETCH_ACCIDENTS_FAIL = 'FETCH_ACCIDENTS_FAIL';

export const CREATE_ACCIDENT_START = 'CREATE_ACCIDENT_START';
export const CREATE_ACCIDENT_SUCCESS = 'CREATE_ACCIDENT_SUCCESS';
export const CREATE_ACCIDENT_FAIL = 'CREATE_ACCIDENT_FAIL';

export const UPDATE_ACCIDENT_START = 'UPDATE_ACCIDENT_START';
export const UPDATE_ACCIDENT_SUCCESS = 'UPDATE_ACCIDENT_SUCCESS';
export const UPDATE_ACCIDENT_FAIL = 'UPDATE_ACCIDENT_FAIL';

export const DELETE_ACCIDENT_START = 'DELETE_ACCIDENT_START';
export const DELETE_ACCIDENT_SUCCESS = 'DELETE_ACCIDENT_SUCCESS';
export const DELETE_ACCIDENT_FAIL = 'DELETE_ACCIDENT_FAIL';

export const RESET_ACCIDENT_STORE = 'RESET_ACCIDENT_STORE';

export type AccidentActionTypes =
  | typeof FETCH_ACCIDENTS_START
  | typeof FETCH_ACCIDENTS_SUCCESS
  | typeof FETCH_ACCIDENTS_FAIL
  | typeof CREATE_ACCIDENT_START
  | typeof CREATE_ACCIDENT_SUCCESS
  | typeof CREATE_ACCIDENT_FAIL
  | typeof UPDATE_ACCIDENT_START
  | typeof UPDATE_ACCIDENT_SUCCESS
  | typeof UPDATE_ACCIDENT_FAIL
  | typeof DELETE_ACCIDENT_START
  | typeof DELETE_ACCIDENT_SUCCESS
  | typeof DELETE_ACCIDENT_FAIL
  | typeof RESET_ACCIDENT_STORE;
