export const FETCH_COMPANY_INSTRUCTION_GROUPS_START =
  'FETCH_COMPANY_INSTRUCTION_GROUPS_START';
export const FETCH_COMPANY_INSTRUCTION_GROUPS_SUCCESS =
  'FETCH_COMPANY_INSTRUCTION_GROUPS_SUCCESS';
export const FETCH_COMPANY_INSTRUCTION_GROUPS_FAIL =
  'FETCH_COMPANY_INSTRUCTION_GROUPS_FAIL';

export const CREATE_COMPANY_INSTRUCTION_GROUP_START =
  'CREATE_COMPANY_INSTRUCTION_GROUP_START';
export const CREATE_COMPANY_INSTRUCTION_GROUP_SUCCESS =
  'CREATE_COMPANY_INSTRUCTION_GROUP_SUCCESS';
export const CREATE_COMPANY_INSTRUCTION_GROUP_FAIL =
  'CREATE_COMPANY_INSTRUCTION_GROUP_FAIL';

export const UPDATE_COMPANY_INSTRUCTION_GROUP_START =
  'UPDATE_COMPANY_INSTRUCTION_GROUP_START';
export const UPDATE_COMPANY_INSTRUCTION_GROUP_SUCCESS =
  'UPDATE_COMPANY_INSTRUCTION_GROUP_SUCCESS';
export const UPDATE_COMPANY_INSTRUCTION_GROUP_FAIL =
  'UPDATE_COMPANY_INSTRUCTION_GROUP_FAIL';

export const DELETE_COMPANY_INSTRUCTION_GROUP_START =
  'DELETE_COMPANY_INSTRUCTION_GROUP_START';
export const DELETE_COMPANY_INSTRUCTION_GROUP_SUCCESS =
  'DELETE_COMPANY_INSTRUCTION_GROUP_SUCCESS';
export const DELETE_COMPANY_INSTRUCTION_GROUP_FAIL =
  'DELETE_COMPANY_INSTRUCTION_GROUP_FAIL';

export const RESET_COMPANY_INSTRUCTION_GROUP_STORE =
  'RESET_COMPANY_INSTRUCTION_GROUP_STORE';

export type CompanyInstructionGroupActionTypes =
  | typeof FETCH_COMPANY_INSTRUCTION_GROUPS_START
  | typeof FETCH_COMPANY_INSTRUCTION_GROUPS_SUCCESS
  | typeof FETCH_COMPANY_INSTRUCTION_GROUPS_FAIL
  | typeof CREATE_COMPANY_INSTRUCTION_GROUP_START
  | typeof CREATE_COMPANY_INSTRUCTION_GROUP_SUCCESS
  | typeof CREATE_COMPANY_INSTRUCTION_GROUP_FAIL
  | typeof UPDATE_COMPANY_INSTRUCTION_GROUP_START
  | typeof UPDATE_COMPANY_INSTRUCTION_GROUP_SUCCESS
  | typeof UPDATE_COMPANY_INSTRUCTION_GROUP_FAIL
  | typeof DELETE_COMPANY_INSTRUCTION_GROUP_START
  | typeof DELETE_COMPANY_INSTRUCTION_GROUP_SUCCESS
  | typeof DELETE_COMPANY_INSTRUCTION_GROUP_FAIL
  | typeof RESET_COMPANY_INSTRUCTION_GROUP_STORE;
