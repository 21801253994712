export const FETCH_ACCIDENTS_START = 'FETCH_ACCIDENTS_START';
export const FETCH_ACCIDENTS_SUCCESS = 'FETCH_ACCIDENTS_SUCCESS';
export const FETCH_ACCIDENTS_FAIL = 'FETCH_ACCIDENTS_FAIL';

export const FETCH_PENDING_ACCIDENT_START = 'FETCH_PENDING_ACCIDENT_START';
export const FETCH_PENDING_ACCIDENT_SUCCESS = 'FETCH_PENDING_ACCIDENT_SUCCESS';
export const FETCH_PENDING_ACCIDENT_FAIL = 'FETCH_PENDING_ACCIDENT_FAIL';

export const ACKNOWLEDGE_ACCIDENTS_START = 'ACKNOWLEDGE_ACCIDENTS_START';
export const ACKNOWLEDGE_ACCIDENTS_SUCCESS = 'ACKNOWLEDGE_ACCIDENTS_SUCCESS';
export const ACKNOWLEDGE_ACCIDENTS_FAIL = 'ACKNOWLEDGE_ACCIDENTS_FAIL';

export const PREPARE_TO_SIGN_ACCIDENTS_START =
  'PREPARE_TO_SIGN_ACCIDENTS_START';
export const PREPARE_TO_SIGN_ACCIDENTS_SUCCESS =
  'PREPARE_TO_SIGN_ACCIDENTS_SUCCESS';
export const PREPARE_TO_SIGN_ACCIDENTS_FAIL = 'PREPARE_TO_SIGN_ACCIDENTS_FAIL';

export const SIGN_ACCIDENTS_START = 'SIGN_ACCIDENTS_START';
export const SIGN_ACCIDENTS_SUCCESS = 'SIGN_ACCIDENTS_SUCCESS';
export const SIGN_ACCIDENTS_FAIL = 'SIGN_ACCIDENTS_FAIL';

export const RESET_ACCIDENTS_STORE = 'RESET_ACCIDENTS_STORE';

export type EmployeeAccidentActionTypes =
  | typeof FETCH_ACCIDENTS_START
  | typeof FETCH_ACCIDENTS_SUCCESS
  | typeof FETCH_ACCIDENTS_FAIL
  | typeof FETCH_PENDING_ACCIDENT_START
  | typeof FETCH_PENDING_ACCIDENT_SUCCESS
  | typeof FETCH_PENDING_ACCIDENT_FAIL
  | typeof ACKNOWLEDGE_ACCIDENTS_START
  | typeof ACKNOWLEDGE_ACCIDENTS_SUCCESS
  | typeof ACKNOWLEDGE_ACCIDENTS_FAIL
  | typeof PREPARE_TO_SIGN_ACCIDENTS_START
  | typeof PREPARE_TO_SIGN_ACCIDENTS_SUCCESS
  | typeof PREPARE_TO_SIGN_ACCIDENTS_FAIL
  | typeof SIGN_ACCIDENTS_START
  | typeof SIGN_ACCIDENTS_SUCCESS
  | typeof SIGN_ACCIDENTS_FAIL
  | typeof RESET_ACCIDENTS_STORE;
