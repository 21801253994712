import * as actionTypes from './actionTypes';
import { CompanyRiskActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';

export type CompanyRiskStateType = {
  companyRisksListUpdateNeeded: boolean;
  companyRiskCreateLoading: boolean;
  companyRiskCreateError: HttpError;
  companyRiskUpdateLoading: boolean;
  companyRiskUpdateError: HttpError;
  companyRiskDeleteLoading: boolean;
  companyRiskDeleteError: HttpError;
};

export type CompanyRiskActionType = CompanyRiskStateType & {
  type: CompanyRiskActionTypes;
};

export const initialState: CompanyRiskStateType = {
  companyRisksListUpdateNeeded: false,
  companyRiskCreateLoading: false,
  companyRiskCreateError: null,
  companyRiskUpdateLoading: false,
  companyRiskUpdateError: null,
  companyRiskDeleteLoading: false,
  companyRiskDeleteError: null,
};

const createCompanyRiskStart = (
  state: CompanyRiskStateType,
): CompanyRiskStateType => ({
  ...state,
  companyRiskCreateLoading: true,
});

const createCompanyRiskSuccess = (
  state: CompanyRiskStateType,
): CompanyRiskStateType => ({
  ...state,
  companyRiskCreateLoading: false,
  companyRiskCreateError: null,
  companyRisksListUpdateNeeded: true,
});

const createCompanyRiskFail = (
  state: CompanyRiskStateType,
  action: CompanyRiskActionType,
): CompanyRiskStateType => ({
  ...state,
  companyRiskCreateLoading: false,
  companyRiskCreateError: action.companyRiskCreateError,
});

const updateCompanyRiskStart = (
  state: CompanyRiskStateType,
): CompanyRiskStateType => ({
  ...state,
  companyRiskUpdateLoading: true,
});

const updateCompanyRiskSuccess = (
  state: CompanyRiskStateType,
): CompanyRiskStateType => ({
  ...state,
  companyRiskUpdateLoading: false,
  companyRiskUpdateError: null,
  companyRisksListUpdateNeeded: true,
});

const updateCompanyRiskFail = (
  state: CompanyRiskStateType,
  action: CompanyRiskActionType,
): CompanyRiskStateType => ({
  ...state,
  companyRiskUpdateLoading: false,
  companyRiskUpdateError: action.companyRiskUpdateError,
});

const deleteCompanyRiskStart = (
  state: CompanyRiskStateType,
): CompanyRiskStateType => ({
  ...state,
  companyRiskDeleteLoading: true,
});

const deleteCompanyRiskSuccess = (
  state: CompanyRiskStateType,
): CompanyRiskStateType => ({
  ...state,
  companyRiskDeleteLoading: false,
  companyRiskDeleteError: null,
  companyRisksListUpdateNeeded: true,
});

const deleteCompanyRiskFail = (
  state: CompanyRiskStateType,
  action: CompanyRiskActionType,
): CompanyRiskStateType => ({
  ...state,
  companyRiskDeleteLoading: false,
  companyRiskDeleteError: action.companyRiskDeleteError,
});

const resetCompanyRiskStore = (): CompanyRiskStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: CompanyRiskActionType) => {
  switch (action.type) {
    case actionTypes.CREATE_COMPANY_RISK_START:
      return createCompanyRiskStart(state);
    case actionTypes.CREATE_COMPANY_RISK_SUCCESS:
      return createCompanyRiskSuccess(state);
    case actionTypes.CREATE_COMPANY_RISK_FAIL:
      return createCompanyRiskFail(state, action);
    case actionTypes.UPDATE_COMPANY_RISK_START:
      return updateCompanyRiskStart(state);
    case actionTypes.UPDATE_COMPANY_RISK_SUCCESS:
      return updateCompanyRiskSuccess(state);
    case actionTypes.UPDATE_COMPANY_RISK_FAIL:
      return updateCompanyRiskFail(state, action);
    case actionTypes.DELETE_COMPANY_RISK_START:
      return deleteCompanyRiskStart(state);
    case actionTypes.DELETE_COMPANY_RISK_SUCCESS:
      return deleteCompanyRiskSuccess(state);
    case actionTypes.DELETE_COMPANY_RISK_FAIL:
      return deleteCompanyRiskFail(state, action);
    case actionTypes.RESET_COMPANY_RISK_STORE:
      return resetCompanyRiskStore();
    default:
      return state;
  }
};

export default reducer;
