import * as actionTypes from './actionTypes';
import { CourseActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { CompleteCourseResponse, Course } from '../../domain/Course';
import { ListResults } from '../../common/List/List';
import { Question } from '../../domain/Question';
import { SessionResponse } from '../../domain/Payment';

export type CourseStateType = {
  coursesList: ListResults<Course> | null;
  coursesLoading: boolean;
  coursesError: HttpError;
  coursesListUpdateNeeded: boolean;
  courseCreateLoading: boolean;
  courseCreateError: HttpError;
  courseCreateSuccess: boolean;
  createdCourse: Course | null;
  courseUpdateLoading: boolean;
  courseUpdateError: HttpError;
  courseUpdateSuccess: boolean;
  course: Course | null;
  courseLoading: boolean;
  courseError: HttpError;
  courseDeleteLoading: boolean;
  courseDeleteError: HttpError;
  courseDeleteSuccess: boolean;
  activeCoursesList: ListResults<Course> | null;
  activeCoursesLoading: boolean;
  activeCoursesError: HttpError;
  activeCourse: Course | null;
  activeCourseLoading: boolean;
  activeCourseError: HttpError;
  purchaseCourseLoading: boolean;
  purchaseCourseSuccess: boolean;
  purchaseCourseError: HttpError;
  myCoursesList: ListResults<Course> | null;
  myCoursesLoading: boolean;
  myCoursesError: HttpError;
  myCourse: Course | null;
  myCourseLoading: boolean;
  myCourseError: HttpError;
  courseQuestions: Question[] | null;
  courseQuestionsLoading: boolean;
  courseQuestionsError: HttpError;
  courseCompleteTestLoading: boolean;
  courseCompleteTestError: HttpError;
  courseCompleteTestResult: CompleteCourseResponse | null;
  sessionLoading: boolean;
  session: SessionResponse | null;
  sessionError: HttpError;
};

export type CourseActionType = CourseStateType & {
  type: CourseActionTypes;
};

export const initialState: CourseStateType = {
  coursesList: null,
  coursesLoading: true,
  coursesError: null,
  coursesListUpdateNeeded: false,
  courseCreateLoading: false,
  courseCreateError: null,
  courseCreateSuccess: false,
  createdCourse: null,
  courseUpdateLoading: false,
  courseUpdateError: null,
  courseUpdateSuccess: false,
  course: null,
  courseLoading: false,
  courseError: null,
  courseDeleteError: null,
  courseDeleteSuccess: false,
  courseDeleteLoading: false,
  activeCoursesError: null,
  activeCoursesList: null,
  activeCoursesLoading: false,
  activeCourse: null,
  activeCourseError: null,
  activeCourseLoading: false,
  purchaseCourseError: null,
  purchaseCourseLoading: false,
  purchaseCourseSuccess: false,
  myCoursesList: null,
  myCoursesError: null,
  myCoursesLoading: false,
  myCourse: null,
  myCourseError: null,
  myCourseLoading: false,
  courseQuestions: null,
  courseQuestionsError: null,
  courseQuestionsLoading: false,
  courseCompleteTestResult: null,
  courseCompleteTestError: null,
  courseCompleteTestLoading: false,
  session: null,
  sessionError: null,
  sessionLoading: false,
};

const fetchCoursesStart = (state: CourseStateType): CourseStateType => ({
  ...state,
  coursesLoading: true,
});

const fetchCoursesSuccess = (
  state: CourseStateType,
  action: CourseActionType,
): CourseStateType => ({
  ...state,
  coursesList: action.coursesList,
  coursesLoading: false,
  coursesError: null,
  coursesListUpdateNeeded: false,
});

const fetchCoursesFail = (
  state: CourseStateType,
  action: CourseActionType,
): CourseStateType => ({
  ...state,
  coursesError: action.coursesError,
  coursesLoading: false,
});

const fetchActiveCoursesStart = (state: CourseStateType): CourseStateType => ({
  ...state,
  activeCoursesLoading: true,
});

const fetchActiveCoursesSuccess = (
  state: CourseStateType,
  action: CourseActionType,
): CourseStateType => ({
  ...state,
  activeCoursesList: action.activeCoursesList,
  activeCoursesLoading: false,
  activeCoursesError: null,
  purchaseCourseSuccess: false,
});

const fetchActiveCoursesFail = (
  state: CourseStateType,
  action: CourseActionType,
): CourseStateType => ({
  ...state,
  activeCoursesError: action.activeCoursesError,
  activeCoursesLoading: false,
});

const fetchMyCoursesStart = (state: CourseStateType): CourseStateType => ({
  ...state,
  myCoursesLoading: true,
});

const fetchMyCoursesSuccess = (
  state: CourseStateType,
  action: CourseActionType,
): CourseStateType => ({
  ...state,
  myCoursesList: action.myCoursesList,
  myCoursesLoading: false,
  myCoursesError: null,
  purchaseCourseSuccess: false,
  courseCompleteTestResult: null,
});

const fetchMyCoursesFail = (
  state: CourseStateType,
  action: CourseActionType,
): CourseStateType => ({
  ...state,
  myCoursesError: action.myCoursesError,
  myCoursesLoading: false,
});

const fetchCourseStart = (state: CourseStateType): CourseStateType => ({
  ...state,
  courseLoading: true,
  courseUpdateSuccess: false,
  courseCreateSuccess: false,
  courseCreateError: null,
  courseUpdateError: null,
});

const fetchCourseSuccess = (
  state: CourseStateType,
  action: CourseActionType,
): CourseStateType => ({
  ...state,
  course: action.course,
  courseLoading: false,
  courseError: null,
});

const fetchCourseFail = (
  state: CourseStateType,
  action: CourseActionType,
): CourseStateType => ({
  ...state,
  courseError: action.courseError,
  courseLoading: false,
});

const fetchActiveCourseStart = (state: CourseStateType): CourseStateType => ({
  ...state,
  activeCourseLoading: true,
});

const fetchActiveCourseSuccess = (
  state: CourseStateType,
  action: CourseActionType,
): CourseStateType => ({
  ...state,
  activeCourse: action.activeCourse,
  activeCourseLoading: false,
  activeCourseError: null,
});

const fetchActiveCourseFail = (
  state: CourseStateType,
  action: CourseActionType,
): CourseStateType => ({
  ...state,
  activeCourseError: action.activeCourseError,
  activeCourseLoading: false,
});

const fetchMyCourseStart = (state: CourseStateType): CourseStateType => ({
  ...state,
  myCourseLoading: true,
});

const fetchMyCourseSuccess = (
  state: CourseStateType,
  action: CourseActionType,
): CourseStateType => ({
  ...state,
  myCourse: action.myCourse,
  myCourseLoading: false,
  myCoursesError: null,
  courseQuestions: null,
});

const fetchMyCourseFail = (
  state: CourseStateType,
  action: CourseActionType,
): CourseStateType => ({
  ...state,
  myCoursesError: action.myCoursesError,
  myCourseLoading: false,
});

const createCourseStart = (state: CourseStateType): CourseStateType => ({
  ...state,
  courseCreateLoading: true,
});

const createCourseSuccess = (
  state: CourseStateType,
  action: CourseActionType,
): CourseStateType => ({
  ...state,
  courseCreateLoading: false,
  courseCreateError: null,
  courseCreateSuccess: true,
  createdCourse: action.createdCourse,
  coursesListUpdateNeeded: true,
});

const createCourseFail = (
  state: CourseStateType,
  action: CourseActionType,
): CourseStateType => ({
  ...state,
  courseCreateLoading: false,
  courseCreateError: action.courseCreateError,
});

const updateCourseStart = (state: CourseStateType): CourseStateType => ({
  ...state,
  courseUpdateLoading: true,
});

const updateCourseSuccess = (state: CourseStateType): CourseStateType => ({
  ...state,
  courseUpdateLoading: false,
  courseUpdateError: null,
  courseUpdateSuccess: true,
  coursesListUpdateNeeded: true,
});

const updateCourseFail = (
  state: CourseStateType,
  action: CourseActionType,
): CourseStateType => ({
  ...state,
  courseUpdateLoading: false,
  courseUpdateError: action.courseUpdateError,
});

const deleteCourseStart = (state: CourseStateType): CourseStateType => ({
  ...state,
  courseDeleteLoading: true,
});

const deleteCourseSuccess = (state: CourseStateType): CourseStateType => ({
  ...state,
  courseDeleteLoading: false,
  courseDeleteError: null,
  courseDeleteSuccess: true,
  coursesListUpdateNeeded: true,
});

const deleteCourseFail = (
  state: CourseStateType,
  action: CourseActionType,
): CourseStateType => ({
  ...state,
  courseDeleteLoading: false,
  courseDeleteError: action.courseDeleteError,
});

const finalizePurchaseCourseStart = (
  state: CourseStateType,
): CourseStateType => ({
  ...state,
  purchaseCourseLoading: true,
  purchaseCourseSuccess: false,
});

const finalizePurchaseCourseSuccess = (
  state: CourseStateType,
): CourseStateType => ({
  ...state,
  purchaseCourseLoading: false,
  purchaseCourseError: null,
  purchaseCourseSuccess: true,
});

const finalizePurchaseCourseFail = (
  state: CourseStateType,
  action: CourseActionType,
): CourseStateType => ({
  ...state,
  purchaseCourseLoading: false,
  purchaseCourseSuccess: false,
  purchaseCourseError: action.purchaseCourseError,
});

const fetchCourseTestStart = (state: CourseStateType): CourseStateType => ({
  ...state,
  courseQuestionsLoading: true,
});

const fetchCourseTestSuccess = (
  state: CourseStateType,
  action: CourseActionType,
): CourseStateType => ({
  ...state,
  courseQuestions: action.courseQuestions,
  courseQuestionsLoading: false,
  courseQuestionsError: null,
});

const fetchCourseTestFail = (
  state: CourseStateType,
  action: CourseActionType,
): CourseStateType => ({
  ...state,
  courseQuestionsError: action.courseQuestionsError,
  courseQuestionsLoading: false,
});

const completeCourseTestStart = (state: CourseStateType): CourseStateType => ({
  ...state,
  courseCompleteTestResult: null,
  courseCompleteTestLoading: true,
});

const completeCourseTestSuccess = (
  state: CourseStateType,
  action: CourseActionType,
): CourseStateType => ({
  ...state,
  courseCompleteTestResult: action.courseCompleteTestResult,
  courseCompleteTestLoading: false,
  courseCompleteTestError: null,
});

const completeCourseTestFail = (
  state: CourseStateType,
  action: CourseActionType,
): CourseStateType => ({
  ...state,
  courseCompleteTestError: action.courseCompleteTestError,
  courseCompleteTestLoading: false,
});

const createPurchaseSessionStart = (
  state: CourseStateType,
): CourseStateType => ({
  ...state,
  session: null,
  sessionLoading: true,
  purchaseCourseSuccess: false,
});

const createPurchaseSessionSuccess = (
  state: CourseStateType,
  action: CourseActionType,
): CourseStateType => ({
  ...state,
  session: action.session,
  sessionLoading: false,
  sessionError: null,
  purchaseCourseSuccess: !action.session?.isPaymentRequired,
});

const createPurchaseSessionFail = (
  state: CourseStateType,
  action: CourseActionType,
): CourseStateType => ({
  ...state,
  sessionError: action.sessionError,
  sessionLoading: false,
});

const resetCourseStore = (): CourseStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: CourseActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_COURSES_START:
      return fetchCoursesStart(state);
    case actionTypes.FETCH_COURSES_SUCCESS:
      return fetchCoursesSuccess(state, action);
    case actionTypes.FETCH_COURSES_FAIL:
      return fetchCoursesFail(state, action);
    case actionTypes.FETCH_ACTIVE_COURSES_START:
      return fetchActiveCoursesStart(state);
    case actionTypes.FETCH_ACTIVE_COURSES_SUCCESS:
      return fetchActiveCoursesSuccess(state, action);
    case actionTypes.FETCH_ACTIVE_COURSES_FAIL:
      return fetchActiveCoursesFail(state, action);
    case actionTypes.FETCH_COURSE_START:
      return fetchCourseStart(state);
    case actionTypes.FETCH_COURSE_SUCCESS:
      return fetchCourseSuccess(state, action);
    case actionTypes.FETCH_COURSE_FAIL:
      return fetchCourseFail(state, action);
    case actionTypes.FETCH_ACTIVE_COURSE_START:
      return fetchActiveCourseStart(state);
    case actionTypes.FETCH_ACTIVE_COURSE_SUCCESS:
      return fetchActiveCourseSuccess(state, action);
    case actionTypes.FETCH_ACTIVE_COURSE_FAIL:
      return fetchActiveCourseFail(state, action);
    case actionTypes.CREATE_COURSE_START:
      return createCourseStart(state);
    case actionTypes.CREATE_COURSE_SUCCESS:
      return createCourseSuccess(state, action);
    case actionTypes.CREATE_COURSE_FAIL:
      return createCourseFail(state, action);
    case actionTypes.UPDATE_COURSE_START:
      return updateCourseStart(state);
    case actionTypes.UPDATE_COURSE_SUCCESS:
      return updateCourseSuccess(state);
    case actionTypes.UPDATE_COURSE_FAIL:
      return updateCourseFail(state, action);
    case actionTypes.DELETE_COURSE_START:
      return deleteCourseStart(state);
    case actionTypes.DELETE_COURSE_SUCCESS:
      return deleteCourseSuccess(state);
    case actionTypes.DELETE_COURSE_FAIL:
      return deleteCourseFail(state, action);
    case actionTypes.FINALIZE_PURCHASE_COURSE_START:
      return finalizePurchaseCourseStart(state);
    case actionTypes.FINALIZE_PURCHASE_COURSE_SUCCESS:
      return finalizePurchaseCourseSuccess(state);
    case actionTypes.FINALIZE_PURCHASE_COURSE_FAIL:
      return finalizePurchaseCourseFail(state, action);
    case actionTypes.FETCH_MY_COURSES_START:
      return fetchMyCoursesStart(state);
    case actionTypes.FETCH_MY_COURSES_SUCCESS:
      return fetchMyCoursesSuccess(state, action);
    case actionTypes.FETCH_MY_COURSES_FAIL:
      return fetchMyCoursesFail(state, action);
    case actionTypes.FETCH_MY_COURSE_START:
      return fetchMyCourseStart(state);
    case actionTypes.FETCH_MY_COURSE_SUCCESS:
      return fetchMyCourseSuccess(state, action);
    case actionTypes.FETCH_MY_COURSE_FAIL:
      return fetchMyCourseFail(state, action);
    case actionTypes.FETCH_COURSE_TEST_START:
      return fetchCourseTestStart(state);
    case actionTypes.FETCH_COURSE_TEST_SUCCESS:
      return fetchCourseTestSuccess(state, action);
    case actionTypes.FETCH_COURSE_TEST_FAIL:
      return fetchCourseTestFail(state, action);
    case actionTypes.CREATE_PURCHASE_SESSION_START:
      return createPurchaseSessionStart(state);
    case actionTypes.CREATE_PURCHASE_SESSION_SUCCESS:
      return createPurchaseSessionSuccess(state, action);
    case actionTypes.CREATE_PURCHASE_SESSION_FAIL:
      return createPurchaseSessionFail(state, action);
    case actionTypes.COMPLETE_COURSE_START:
      return completeCourseTestStart(state);
    case actionTypes.COMPLETE_COURSE_SUCCESS:
      return completeCourseTestSuccess(state, action);
    case actionTypes.COMPLETE_COURSE_FAIL:
      return completeCourseTestFail(state, action);
    case actionTypes.RESET_COURSE_STORE:
      return resetCourseStore();
    default:
      return state;
  }
};

export default reducer;
