export const FETCH_COMPANIES_START = 'FETCH_COMPANIES_START';
export const FETCH_COMPANIES_SUCCESS = 'FETCH_COMPANIES_SUCCESS';
export const FETCH_COMPANIES_FAIL = 'FETCH_COMPANIES_FAIL';

export const FETCH_COMPANY_OPTIONS_START = 'FETCH_COMPANY_OPTIONS_START';
export const FETCH_COMPANY_OPTIONS_SUCCESS = 'FETCH_COMPANY_OPTIONS_SUCCESS';
export const FETCH_COMPANY_OPTIONS_FAIL = 'FETCH_COMPANY_OPTIONS_FAIL';

export const FETCH_COMPANY_START = 'FETCH_COMPANY_START';
export const FETCH_COMPANY_SUCCESS = 'FETCH_COMPANY_SUCCESS';
export const FETCH_COMPANY_FAIL = 'FETCH_COMPANY_FAIL';

export const CREATE_COMPANY_START = 'CREATE_COMPANY_START';
export const CREATE_COMPANY_SUCCESS = 'CREATE_COMPANY_SUCCESS';
export const CREATE_COMPANY_FAIL = 'CREATE_COMPANY_FAIL';

export const UPDATE_COMPANY_START = 'UPDATE_COMPANY_START';
export const UPDATE_COMPANY_SUCCESS = 'UPDATE_COMPANY_SUCCESS';
export const UPDATE_COMPANY_FAIL = 'UPDATE_COMPANY_FAIL';

export const FETCH_EXPORT_DATA_START = 'FETCH_EXPORT_DATA_START';
export const FETCH_EXPORT_DATA_SUCCESS = 'FETCH_EXPORT_DATA_SUCCESS';
export const FETCH_EXPORT_DATA_FAIL = 'FETCH_EXPORT_DATA_FAIL';

export const RESET_COMPANY_STORE = 'RESET_COMPANY_STORE';
export const RESET_EXPORT_STORE = 'RESET_EXPORT_STORE';
export const SET_SELECTED_COMPANY = 'SET_SELECTED_COMPANY';
export const SET_SELECTED_SYSTEM = 'SET_SELECTED_SYSTEM';
export const SET_AVAILABLE_SYSTEMS = 'SET_AVAILABLE_SYSTEMS';
export const SET_COMPANY_ID = 'SET_COMPANY_ID';

export type CompanyActionTypes =
  | typeof FETCH_COMPANIES_START
  | typeof FETCH_COMPANIES_SUCCESS
  | typeof FETCH_COMPANIES_FAIL
  | typeof FETCH_COMPANY_OPTIONS_START
  | typeof FETCH_COMPANY_OPTIONS_SUCCESS
  | typeof FETCH_COMPANY_OPTIONS_FAIL
  | typeof FETCH_COMPANY_START
  | typeof FETCH_COMPANY_SUCCESS
  | typeof FETCH_COMPANY_FAIL
  | typeof CREATE_COMPANY_START
  | typeof CREATE_COMPANY_SUCCESS
  | typeof CREATE_COMPANY_FAIL
  | typeof UPDATE_COMPANY_START
  | typeof UPDATE_COMPANY_SUCCESS
  | typeof UPDATE_COMPANY_FAIL
  | typeof FETCH_EXPORT_DATA_START
  | typeof FETCH_EXPORT_DATA_SUCCESS
  | typeof FETCH_EXPORT_DATA_FAIL
  | typeof RESET_COMPANY_STORE
  | typeof RESET_EXPORT_STORE
  | typeof SET_SELECTED_SYSTEM
  | typeof SET_AVAILABLE_SYSTEMS
  | typeof SET_COMPANY_ID
  | typeof SET_SELECTED_COMPANY;
