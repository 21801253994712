import React, { useState } from 'react';
import styles from './SystemSwitcher.module.scss';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { StoreState } from '../../config/StoreProvider/StoreProvider';
import { Popover } from '@mui/material';
import { useIntl } from 'react-intl';
import { translate } from '../../utility/messageTranslator/translate';
import { setSelectedSystem } from '../../store/company/actions';
import { CompanySystem } from '../../domain/Company';

type Props = {
  onSystemSelect: (system: number) => void;
  selectedSystem: CompanySystem | null;
  availableSystems: CompanySystem[] | null;
  selectedCompany: number | null;
};

const SystemSwitcher = ({
  onSystemSelect,
  selectedSystem,
  availableSystems,
  selectedCompany,
}: Props) => {
  const intl = useIntl();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<any>(null);

  const handleClick = (system: CompanySystem) => {
    onSystemSelect(system);
    setIsPopoverOpen(false);
  };

  const renderActions = () => {
    return availableSystems?.map((system, index) => (
      <button
        className={styles.popoverListItem}
        key={index.toString()}
        onClick={() => handleClick(system)}
      >
        {translate(intl, `COMPANIES.SYSTEM_${CompanySystem[system]}`)}
      </button>
    ));
  };

  if (!selectedCompany || !availableSystems || !availableSystems.length) {
    return null;
  }

  return (
    <>
      <div
        className={styles.switcherButton}
        onClick={(event) => {
          setAnchorEl(event.currentTarget);
          setIsPopoverOpen(true);
        }}
      >
        {selectedSystem &&
          translate(intl, `COMPANIES.SYSTEM_${CompanySystem[selectedSystem]}`)}
      </div>
      <Popover
        className={styles.popover}
        open={isPopoverOpen}
        onClose={() => setIsPopoverOpen(false)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <div className={styles.popoverContent}>
          <ul className={styles.popoverList}>{renderActions()}</ul>
        </div>
      </Popover>
    </>
  );
};

const mapStateToProps = (state: StoreState) => ({
  selectedLocale: state.auth.selectedLocale,
  selectedSystem: state.company.selectedSystem,
  availableSystems: state.company.availableSystems,
  selectedCompany: state.company.selectedCompany,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onSystemSelect: (system: number) => dispatch(setSelectedSystem(system)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SystemSwitcher);
