import * as actionTypes from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { Company, CompanySystem, ExportData } from '../../domain/Company';
import { ListResults } from '../../common/List/List';
import { Asset } from '../../domain/Asset';

export const fetchCompaniesStart = () => ({
  type: actionTypes.FETCH_COMPANIES_START,
});

export const fetchCompaniesSuccess = (companiesList: ListResults<Company>) => ({
  type: actionTypes.FETCH_COMPANIES_SUCCESS,
  companiesList,
});

export const fetchCompaniesFail = (companiesError: HttpError) => ({
  type: actionTypes.FETCH_COMPANIES_FAIL,
  companiesError,
});

export const fetchCompanyOptionsStart = () => ({
  type: actionTypes.FETCH_COMPANY_OPTIONS_START,
});

export const fetchCompanyOptionsSuccess = (companyOptions: Company[]) => ({
  type: actionTypes.FETCH_COMPANY_OPTIONS_SUCCESS,
  companyOptions,
});

export const fetchCompanyOptionsFail = (companyOptionsError: HttpError) => ({
  type: actionTypes.FETCH_COMPANY_OPTIONS_FAIL,
  companyOptionsError,
});

export const fetchCompanyStart = () => ({
  type: actionTypes.FETCH_COMPANY_START,
});

export const fetchCompanySuccess = (company: Company) => ({
  type: actionTypes.FETCH_COMPANY_SUCCESS,
  company,
});

export const fetchCompanyFail = (companyError: HttpError) => ({
  type: actionTypes.FETCH_COMPANY_FAIL,
  companyError,
});

export const createCompanyStart = () => ({
  type: actionTypes.CREATE_COMPANY_START,
});

export const createCompanySuccess = (createdCompany: Company) => ({
  type: actionTypes.CREATE_COMPANY_SUCCESS,
  createdCompany,
});

export const createCompanyFail = (companyCreateError: HttpError) => ({
  type: actionTypes.CREATE_COMPANY_FAIL,
  companyCreateError,
});

export const updateCompanyStart = () => ({
  type: actionTypes.UPDATE_COMPANY_START,
});

export const updateCompanySuccess = () => ({
  type: actionTypes.UPDATE_COMPANY_SUCCESS,
});

export const updateCompanyFail = (companyUpdateError: HttpError) => ({
  type: actionTypes.UPDATE_COMPANY_FAIL,
  companyUpdateError,
});

export const fetchExportDataStart = () => ({
  type: actionTypes.FETCH_EXPORT_DATA_START,
});

export const fetchExportDataSuccess = (exportData: ExportData) => ({
  type: actionTypes.FETCH_EXPORT_DATA_SUCCESS,
  exportData,
});

export const fetchExportDataFail = (exportDataError: HttpError) => ({
  type: actionTypes.FETCH_EXPORT_DATA_FAIL,
  exportDataError,
});

export const resetExportStore = () => ({
  type: actionTypes.RESET_EXPORT_STORE,
});

export const setSelectedCompany = (
  selectedCompany: number,
  availableSystems: CompanySystem[],
  companyLogo: Asset | null,
) => {
  localStorage.removeItem('companyLogo');

  if (companyLogo) {
    localStorage.setItem('companyLogo', JSON.stringify(companyLogo));
  }

  return {
    type: actionTypes.SET_SELECTED_COMPANY,
    selectedCompany,
    availableSystems,
    companyLogo,
  };
};

export const setSelectedCompanyId = (selectedCompany: number) => {
  localStorage.removeItem('companyLogo');

  return {
    type: actionTypes.SET_COMPANY_ID,
    selectedCompany,
  };
};

export const setSelectedSystem = (selectedSystem: number | null) => {
  if (selectedSystem) {
    localStorage.setItem('selectedSystem', selectedSystem.toString());
  } else {
    localStorage.removeItem('selectedSystem');
  }

  return {
    type: actionTypes.SET_SELECTED_SYSTEM,
    selectedSystem,
  };
};

export const setAvailableSystems = (availableSystems: CompanySystem[]) => {
  localStorage.setItem('availableSystems', JSON.stringify(availableSystems));

  return {
    type: actionTypes.SET_AVAILABLE_SYSTEMS,
    availableSystems,
  };
};

export const resetCompanyStore = () => ({
  type: actionTypes.RESET_COMPANY_STORE,
});
