import * as actionTypes from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { CompleteCourseResponse, Course } from '../../domain/Course';
import { ListResults } from '../../common/List/List';
import { Question } from '../../domain/Question';
import { SessionResponse } from '../../domain/Payment';

export const fetchCoursesStart = () => ({
  type: actionTypes.FETCH_COURSES_START,
});

export const fetchCoursesSuccess = (coursesList: ListResults<Course>) => ({
  type: actionTypes.FETCH_COURSES_SUCCESS,
  coursesList,
});

export const fetchCoursesFail = (courseError: HttpError) => ({
  type: actionTypes.FETCH_COURSES_FAIL,
  courseError,
});

export const fetchActiveCoursesStart = () => ({
  type: actionTypes.FETCH_ACTIVE_COURSES_START,
});

export const fetchActiveCoursesSuccess = (
  activeCoursesList: ListResults<Course>,
) => ({
  type: actionTypes.FETCH_ACTIVE_COURSES_SUCCESS,
  activeCoursesList,
});

export const fetchActiveCoursesFail = (activeCoursesError: HttpError) => ({
  type: actionTypes.FETCH_ACTIVE_COURSES_FAIL,
  activeCoursesError,
});

export const fetchCourseStart = () => ({
  type: actionTypes.FETCH_COURSE_START,
});

export const fetchCourseSuccess = (course: Course) => ({
  type: actionTypes.FETCH_COURSE_SUCCESS,
  course,
});

export const fetchCourseFail = (courseError: HttpError) => ({
  type: actionTypes.FETCH_COURSE_FAIL,
  courseError,
});

export const fetchActiveCourseStart = () => ({
  type: actionTypes.FETCH_ACTIVE_COURSE_START,
});

export const fetchActiveCourseSuccess = (activeCourse: Course) => ({
  type: actionTypes.FETCH_ACTIVE_COURSE_SUCCESS,
  activeCourse,
});

export const fetchActiveCourseFail = (activeCourseError: HttpError) => ({
  type: actionTypes.FETCH_ACTIVE_COURSE_FAIL,
  activeCourseError,
});

export const fetchMyCoursesStart = () => ({
  type: actionTypes.FETCH_MY_COURSES_START,
});

export const fetchMyCoursesSuccess = (myCoursesList: ListResults<Course>) => ({
  type: actionTypes.FETCH_MY_COURSES_SUCCESS,
  myCoursesList,
});

export const fetchMyCoursesFail = (myCoursesError: HttpError) => ({
  type: actionTypes.FETCH_MY_COURSES_FAIL,
  myCoursesError,
});

export const fetchMyCourseStart = () => ({
  type: actionTypes.FETCH_MY_COURSE_START,
});

export const fetchMyCourseSuccess = (myCourse: Course) => ({
  type: actionTypes.FETCH_MY_COURSE_SUCCESS,
  myCourse,
});

export const fetchMyCourseFail = (myCourseError: HttpError) => ({
  type: actionTypes.FETCH_MY_COURSE_FAIL,
  myCourseError,
});

export const createCourseStart = () => ({
  type: actionTypes.CREATE_COURSE_START,
});

export const createCourseSuccess = (createdCourse: Course) => ({
  type: actionTypes.CREATE_COURSE_SUCCESS,
  createdCourse,
});

export const createCourseFail = (courseCreateError: HttpError) => ({
  type: actionTypes.CREATE_COURSE_FAIL,
  courseCreateError,
});

export const updateCourseStart = () => ({
  type: actionTypes.UPDATE_COURSE_START,
});

export const updateCourseSuccess = () => ({
  type: actionTypes.UPDATE_COURSE_SUCCESS,
});

export const updateCourseFail = (courseUpdateError: HttpError) => ({
  type: actionTypes.UPDATE_COURSE_FAIL,
  courseUpdateError,
});

export const deleteCourseStart = () => ({
  type: actionTypes.DELETE_COURSE_START,
});

export const deleteCourseSuccess = () => ({
  type: actionTypes.DELETE_COURSE_SUCCESS,
});

export const deleteCourseFail = (courseDeleteError: HttpError) => ({
  type: actionTypes.DELETE_COURSE_FAIL,
  courseDeleteError,
});

export const finalizePurchaseCourseStart = () => ({
  type: actionTypes.FINALIZE_PURCHASE_COURSE_START,
});

export const finalizePurchaseCourseSuccess = () => ({
  type: actionTypes.FINALIZE_PURCHASE_COURSE_SUCCESS,
});

export const finalizePurchaseCourseFail = (purchaseCourseError: HttpError) => ({
  type: actionTypes.FINALIZE_PURCHASE_COURSE_FAIL,
  purchaseCourseError,
});

export const fetchCourseTestStart = () => ({
  type: actionTypes.FETCH_COURSE_TEST_START,
});

export const fetchCourseTestSuccess = (courseQuestions: Question[]) => ({
  type: actionTypes.FETCH_COURSE_TEST_SUCCESS,
  courseQuestions,
});

export const fetchCourseTestFail = (courseQuestionsError: HttpError) => ({
  type: actionTypes.FETCH_COURSE_TEST_FAIL,
  courseQuestionsError,
});

export const completeCourseTestStart = () => ({
  type: actionTypes.COMPLETE_COURSE_START,
});

export const completeCourseTestSuccess = (
  courseCompleteTestResult: CompleteCourseResponse,
) => ({
  type: actionTypes.COMPLETE_COURSE_SUCCESS,
  courseCompleteTestResult,
});

export const completeCourseTestFail = (courseCompleteTestError: HttpError) => ({
  type: actionTypes.COMPLETE_COURSE_FAIL,
  courseCompleteTestError,
});

export const createPurchaseSessionStart = () => ({
  type: actionTypes.CREATE_PURCHASE_SESSION_START,
});

export const createPurchaseSessionSuccess = (session: SessionResponse) => ({
  type: actionTypes.CREATE_PURCHASE_SESSION_SUCCESS,
  session,
});

export const createPurchaseSessionFail = (sessionError: HttpError) => ({
  type: actionTypes.CREATE_PURCHASE_SESSION_FAIL,
  sessionError,
});

export const resetCourseStore = () => ({
  type: actionTypes.RESET_COURSE_STORE,
});
