import * as actionTypes from './actionTypes';
import { AnswerActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';

export type AnswerStateType = {
  answersListUpdateNeeded: boolean;
  answerCreateLoading: boolean;
  answerCreateError: HttpError;
  answerUpdateLoading: boolean;
  answerUpdateError: HttpError;
  answerDeleteLoading: boolean;
  answerDeleteError: HttpError;
  answersPriorityUpdateLoading: boolean;
  answersPriorityUpdateError: HttpError;
  answersPriorityUpdateSuccess: boolean;
};

export type AnswerActionType = AnswerStateType & {
  type: AnswerActionTypes;
};

export const initialState: AnswerStateType = {
  answersListUpdateNeeded: false,
  answerCreateLoading: false,
  answerCreateError: null,
  answerUpdateLoading: false,
  answerUpdateError: null,
  answerDeleteLoading: false,
  answerDeleteError: null,
  answersPriorityUpdateLoading: false,
  answersPriorityUpdateError: null,
  answersPriorityUpdateSuccess: false,
};

const createAnswerStart = (state: AnswerStateType): AnswerStateType => ({
  ...state,
  answerCreateLoading: true,
  answersPriorityUpdateSuccess: false,
});

const createAnswerSuccess = (state: AnswerStateType): AnswerStateType => ({
  ...state,
  answerCreateLoading: false,
  answerCreateError: null,
  answersListUpdateNeeded: true,
});

const createAnswerFail = (
  state: AnswerStateType,
  action: AnswerActionType,
): AnswerStateType => ({
  ...state,
  answerCreateLoading: false,
  answerCreateError: action.answerCreateError,
});

const updateAnswerStart = (state: AnswerStateType): AnswerStateType => ({
  ...state,
  answerUpdateLoading: true,
  answersPriorityUpdateSuccess: false,
});

const updateAnswerSuccess = (state: AnswerStateType): AnswerStateType => ({
  ...state,
  answerUpdateLoading: false,
  answerUpdateError: null,
  answersListUpdateNeeded: true,
});

const updateAnswerFail = (
  state: AnswerStateType,
  action: AnswerActionType,
): AnswerStateType => ({
  ...state,
  answerUpdateLoading: false,
  answerUpdateError: action.answerUpdateError,
});

const deleteAnswerStart = (state: AnswerStateType): AnswerStateType => ({
  ...state,
  answerDeleteLoading: true,
  answersPriorityUpdateSuccess: false,
});

const deleteAnswerSuccess = (state: AnswerStateType): AnswerStateType => ({
  ...state,
  answerDeleteLoading: false,
  answerDeleteError: null,
  answersListUpdateNeeded: true,
});

const deleteAnswerFail = (
  state: AnswerStateType,
  action: AnswerActionType,
): AnswerStateType => ({
  ...state,
  answerDeleteLoading: false,
  answerDeleteError: action.answerDeleteError,
});

const updateAnswersPriorityStart = (
  state: AnswerStateType,
): AnswerStateType => ({
  ...state,
  answersPriorityUpdateLoading: true,
  answersPriorityUpdateSuccess: false,
});

const updateAnswersPrioritySuccess = (
  state: AnswerStateType,
): AnswerStateType => ({
  ...state,
  answersPriorityUpdateError: null,
  answersPriorityUpdateSuccess: true,
  answersListUpdateNeeded: true,
});

const updateAnswersPriorityFail = (
  state: AnswerStateType,
  action: AnswerActionType,
): AnswerStateType => ({
  ...state,
  answersPriorityUpdateLoading: false,
  answersPriorityUpdateError: action.answersPriorityUpdateError,
});

const resetAnswerStore = (): AnswerStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: AnswerActionType) => {
  switch (action.type) {
    case actionTypes.CREATE_ANSWER_START:
      return createAnswerStart(state);
    case actionTypes.CREATE_ANSWER_SUCCESS:
      return createAnswerSuccess(state);
    case actionTypes.CREATE_ANSWER_FAIL:
      return createAnswerFail(state, action);
    case actionTypes.UPDATE_ANSWER_START:
      return updateAnswerStart(state);
    case actionTypes.UPDATE_ANSWER_SUCCESS:
      return updateAnswerSuccess(state);
    case actionTypes.UPDATE_ANSWER_FAIL:
      return updateAnswerFail(state, action);
    case actionTypes.DELETE_ANSWER_START:
      return deleteAnswerStart(state);
    case actionTypes.DELETE_ANSWER_SUCCESS:
      return deleteAnswerSuccess(state);
    case actionTypes.DELETE_ANSWER_FAIL:
      return deleteAnswerFail(state, action);
    case actionTypes.RESET_ANSWER_STORE:
      return resetAnswerStore();
    case actionTypes.UPDATE_ANSWERS_PRIORITY_START:
      return updateAnswersPriorityStart(state);
    case actionTypes.UPDATE_ANSWERS_PRIORITY_SUCCESS:
      return updateAnswersPrioritySuccess(state);
    case actionTypes.UPDATE_ANSWERS_PRIORITY_FAIL:
      return updateAnswersPriorityFail(state, action);
    default:
      return state;
  }
};

export default reducer;
