export const CREATE_COURSE_CERTIFICATE_START =
  'CREATE_COURSE_CERTIFICATE_START';
export const CREATE_COURSE_CERTIFICATE_SUCCESS =
  'CREATE_COURSE_CERTIFICATE_SUCCESS';
export const CREATE_COURSE_CERTIFICATE_FAIL = 'CREATE_COURSE_CERTIFICATE_FAIL';

export const UPDATE_COURSE_CERTIFICATE_START =
  'UPDATE_COURSE_CERTIFICATE_START';
export const UPDATE_COURSE_CERTIFICATE_SUCCESS =
  'UPDATE_COURSE_CERTIFICATE_SUCCESS';
export const UPDATE_COURSE_CERTIFICATE_FAIL = 'UPDATE_COURSE_CERTIFICATE_FAIL';

export const RESET_COURSE_CERTIFICATE_STORE = 'RESET_COURSE_CERTIFICATE_STORE';

export type CourseCertificateActionTypes =
  | typeof CREATE_COURSE_CERTIFICATE_START
  | typeof CREATE_COURSE_CERTIFICATE_SUCCESS
  | typeof CREATE_COURSE_CERTIFICATE_FAIL
  | typeof UPDATE_COURSE_CERTIFICATE_START
  | typeof UPDATE_COURSE_CERTIFICATE_SUCCESS
  | typeof UPDATE_COURSE_CERTIFICATE_FAIL
  | typeof RESET_COURSE_CERTIFICATE_STORE;
