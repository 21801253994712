import * as actionTypes from './actionTypes';
import { InstructionGroupActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { ListResults } from '../../common/List/List';
import { InstructionGroup } from '../../domain/InstructionGroup';

export type InstructionGroupStateType = {
  instructionGroupsList: ListResults<InstructionGroup> | null;
  instructionGroupsLoading: boolean;
  instructionGroupsError: HttpError;
  instructionGroupsListUpdateNeeded: boolean;
  instructionGroupCreateLoading: boolean;
  instructionGroupCreateError: HttpError;
  instructionGroupUpdateLoading: boolean;
  instructionGroupUpdateError: HttpError;
  instructionGroupDeleteLoading: boolean;
  instructionGroupDeleteError: HttpError;
};

export type InstructionGroupActionType = InstructionGroupStateType & {
  type: InstructionGroupActionTypes;
};

export const initialState: InstructionGroupStateType = {
  instructionGroupsList: null,
  instructionGroupsLoading: false,
  instructionGroupsListUpdateNeeded: false,
  instructionGroupsError: null,
  instructionGroupCreateLoading: false,
  instructionGroupCreateError: null,
  instructionGroupUpdateLoading: false,
  instructionGroupUpdateError: null,
  instructionGroupDeleteLoading: false,
  instructionGroupDeleteError: null,
};

const fetchInstructionGroupsStart = (
  state: InstructionGroupStateType,
): InstructionGroupStateType => ({
  ...state,
  instructionGroupsLoading: true,
});

const fetchInstructionGroupsSuccess = (
  state: InstructionGroupStateType,
  action: InstructionGroupActionType,
): InstructionGroupStateType => ({
  ...state,
  instructionGroupsList: action.instructionGroupsList,
  instructionGroupsLoading: false,
  instructionGroupsError: null,
  instructionGroupsListUpdateNeeded: false,
});

const fetchInstructionGroupsFail = (
  state: InstructionGroupStateType,
  action: InstructionGroupActionType,
): InstructionGroupStateType => ({
  ...state,
  instructionGroupsError: action.instructionGroupsError,
  instructionGroupsLoading: false,
});

const createInstructionGroupStart = (
  state: InstructionGroupStateType,
): InstructionGroupStateType => ({
  ...state,
  instructionGroupCreateLoading: true,
});

const createInstructionGroupSuccess = (
  state: InstructionGroupStateType,
): InstructionGroupStateType => ({
  ...state,
  instructionGroupCreateLoading: false,
  instructionGroupCreateError: null,
  instructionGroupsListUpdateNeeded: true,
});

const createInstructionGroupFail = (
  state: InstructionGroupStateType,
  action: InstructionGroupActionType,
): InstructionGroupStateType => ({
  ...state,
  instructionGroupCreateLoading: false,
  instructionGroupCreateError: action.instructionGroupCreateError,
});

const updateInstructionGroupStart = (
  state: InstructionGroupStateType,
): InstructionGroupStateType => ({
  ...state,
  instructionGroupUpdateLoading: true,
});

const updateInstructionGroupSuccess = (
  state: InstructionGroupStateType,
): InstructionGroupStateType => ({
  ...state,
  instructionGroupUpdateLoading: false,
  instructionGroupUpdateError: null,
  instructionGroupsListUpdateNeeded: true,
});

const updateInstructionGroupFail = (
  state: InstructionGroupStateType,
  action: InstructionGroupActionType,
): InstructionGroupStateType => ({
  ...state,
  instructionGroupUpdateLoading: false,
  instructionGroupUpdateError: action.instructionGroupUpdateError,
});

const deleteInstructionGroupStart = (
  state: InstructionGroupStateType,
): InstructionGroupStateType => ({
  ...state,
  instructionGroupDeleteLoading: true,
});

const deleteInstructionGroupSuccess = (
  state: InstructionGroupStateType,
): InstructionGroupStateType => ({
  ...state,
  instructionGroupDeleteLoading: false,
  instructionGroupDeleteError: null,
  instructionGroupsListUpdateNeeded: true,
});

const deleteInstructionGroupFail = (
  state: InstructionGroupStateType,
  action: InstructionGroupActionType,
): InstructionGroupStateType => ({
  ...state,
  instructionGroupDeleteLoading: false,
  instructionGroupDeleteError: action.instructionGroupDeleteError,
});

const resetInstructionGroupStore = (): InstructionGroupStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: InstructionGroupActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_INSTRUCTION_GROUPS_START:
      return fetchInstructionGroupsStart(state);
    case actionTypes.FETCH_INSTRUCTION_GROUPS_SUCCESS:
      return fetchInstructionGroupsSuccess(state, action);
    case actionTypes.FETCH_INSTRUCTION_GROUPS_FAIL:
      return fetchInstructionGroupsFail(state, action);
    case actionTypes.CREATE_INSTRUCTION_GROUP_START:
      return createInstructionGroupStart(state);
    case actionTypes.CREATE_INSTRUCTION_GROUP_SUCCESS:
      return createInstructionGroupSuccess(state);
    case actionTypes.CREATE_INSTRUCTION_GROUP_FAIL:
      return createInstructionGroupFail(state, action);
    case actionTypes.UPDATE_INSTRUCTION_GROUP_START:
      return updateInstructionGroupStart(state);
    case actionTypes.UPDATE_INSTRUCTION_GROUP_SUCCESS:
      return updateInstructionGroupSuccess(state);
    case actionTypes.UPDATE_INSTRUCTION_GROUP_FAIL:
      return updateInstructionGroupFail(state, action);
    case actionTypes.DELETE_INSTRUCTION_GROUP_START:
      return deleteInstructionGroupStart(state);
    case actionTypes.DELETE_INSTRUCTION_GROUP_SUCCESS:
      return deleteInstructionGroupSuccess(state);
    case actionTypes.DELETE_INSTRUCTION_GROUP_FAIL:
      return deleteInstructionGroupFail(state, action);
    case actionTypes.RESET_INSTRUCTION_GROUP_STORE:
      return resetInstructionGroupStore();
    default:
      return state;
  }
};

export default reducer;
