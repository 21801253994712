export const FETCH_COMPANY_TYPES_START = 'FETCH_COMPANY_TYPES_START';
export const FETCH_COMPANY_TYPES_SUCCESS = 'FETCH_COMPANY_TYPES_SUCCESS';
export const FETCH_COMPANY_TYPES_FAIL = 'FETCH_COMPANY_TYPES_FAIL';

export const CREATE_COMPANY_TYPE_START = 'CREATE_COMPANY_TYPE_START';
export const CREATE_COMPANY_TYPE_SUCCESS = 'CREATE_COMPANY_TYPE_SUCCESS';
export const CREATE_COMPANY_TYPE_FAIL = 'CREATE_COMPANY_TYPE_FAIL';

export const UPDATE_COMPANY_TYPE_START = 'UPDATE_COMPANY_TYPE_START';
export const UPDATE_COMPANY_TYPE_SUCCESS = 'UPDATE_COMPANY_TYPE_SUCCESS';
export const UPDATE_COMPANY_TYPE_FAIL = 'UPDATE_COMPANY_TYPE_FAIL';

export const DELETE_COMPANY_TYPE_START = 'DELETE_COMPANY_TYPE_START';
export const DELETE_COMPANY_TYPE_SUCCESS = 'DELETE_COMPANY_TYPE_SUCCESS';
export const DELETE_COMPANY_TYPE_FAIL = 'DELETE_COMPANY_TYPE_FAIL';

export const RESET_COMPANY_TYPE_STORE = 'RESET_COMPANY_TYPE_STORE';
export const RESET_CREATED_COMPANY_TYPE = 'RESET_CREATED_COMPANY_TYPE';

export type CompanyTypeActionTypes =
  | typeof FETCH_COMPANY_TYPES_START
  | typeof FETCH_COMPANY_TYPES_SUCCESS
  | typeof FETCH_COMPANY_TYPES_FAIL
  | typeof CREATE_COMPANY_TYPE_START
  | typeof CREATE_COMPANY_TYPE_SUCCESS
  | typeof CREATE_COMPANY_TYPE_FAIL
  | typeof UPDATE_COMPANY_TYPE_START
  | typeof UPDATE_COMPANY_TYPE_SUCCESS
  | typeof UPDATE_COMPANY_TYPE_FAIL
  | typeof RESET_COMPANY_TYPE_STORE
  | typeof RESET_CREATED_COMPANY_TYPE
  | typeof DELETE_COMPANY_TYPE_START
  | typeof DELETE_COMPANY_TYPE_SUCCESS
  | typeof DELETE_COMPANY_TYPE_FAIL;
