import { IntlShape } from 'react-intl';
import { EntityTranslation } from '../../domain/EntityTranslation';
import { Locale } from '../../domain/Translation';

export const translate = (
  intl: IntlShape,
  id: string,
  defaultMessage?: string,
) =>
  intl.formatMessage({
    id,
    defaultMessage: defaultMessage || id,
  });

export const translateProperty = (
  property: string,
  defaultValue: string,
  translations: EntityTranslation[],
  locale: Locale,
) => {
  if (!translations || !translations.length) {
    return defaultValue;
  }

  const translation = translations.find(
    (translation) =>
      translation.property === property &&
      translation.language?.locale === locale,
  );

  return translation ? translation.value : defaultValue;
};
