export const FETCH_COURSES_START = 'FETCH_COURSES_START';
export const FETCH_COURSES_SUCCESS = 'FETCH_COURSES_SUCCESS';
export const FETCH_COURSES_FAIL = 'FETCH_COURSES_FAIL';

export const FETCH_ACTIVE_COURSES_START = 'FETCH_ACTIVE_COURSES_START';
export const FETCH_ACTIVE_COURSES_SUCCESS = 'FETCH_ACTIVE_COURSES_SUCCESS';
export const FETCH_ACTIVE_COURSES_FAIL = 'FETCH_ACTIVE_COURSES_FAIL';

export const FETCH_MY_COURSES_START = 'FETCH_MY_COURSES_START';
export const FETCH_MY_COURSES_SUCCESS = 'FETCH_MY_COURSES_SUCCESS';
export const FETCH_MY_COURSES_FAIL = 'FETCH_MY_COURSES_FAIL';

export const FETCH_MY_COURSE_START = 'FETCH_MY_COURSE_START';
export const FETCH_MY_COURSE_SUCCESS = 'FETCH_MY_COURSE_SUCCESS';
export const FETCH_MY_COURSE_FAIL = 'FETCH_MY_COURSE_FAIL';

export const FETCH_COURSE_START = 'FETCH_COURSE_START';
export const FETCH_COURSE_SUCCESS = 'FETCH_COURSE_SUCCESS';
export const FETCH_COURSE_FAIL = 'FETCH_COURSE_FAIL';

export const FETCH_ACTIVE_COURSE_START = 'FETCH_ACTIVE_COURSE_START';
export const FETCH_ACTIVE_COURSE_SUCCESS = 'FETCH_ACTIVE_COURSE_SUCCESS';
export const FETCH_ACTIVE_COURSE_FAIL = 'FETCH_ACTIVE_COURSE_FAIL';

export const FETCH_COURSE_TEST_START = 'FETCH_COURSE_TEST_START';
export const FETCH_COURSE_TEST_SUCCESS = 'FETCH_COURSE_TEST_SUCCESS';
export const FETCH_COURSE_TEST_FAIL = 'FETCH_COURSE_TEST_FAIL';

export const CREATE_COURSE_START = 'CREATE_COURSE_START';
export const CREATE_COURSE_SUCCESS = 'CREATE_COURSE_SUCCESS';
export const CREATE_COURSE_FAIL = 'CREATE_COURSE_FAIL';

export const UPDATE_COURSE_START = 'UPDATE_COURSE_START';
export const UPDATE_COURSE_SUCCESS = 'UPDATE_COURSE_SUCCESS';
export const UPDATE_COURSE_FAIL = 'UPDATE_COURSE_FAIL';

export const DELETE_COURSE_START = 'DELETE_COURSE_START';
export const DELETE_COURSE_SUCCESS = 'DELETE_COURSE_SUCCESS';
export const DELETE_COURSE_FAIL = 'DELETE_COURSE_FAIL';

export const FINALIZE_PURCHASE_COURSE_START = 'PURCHASE_COURSE_START';
export const FINALIZE_PURCHASE_COURSE_SUCCESS = 'PURCHASE_COURSE_SUCCESS';
export const FINALIZE_PURCHASE_COURSE_FAIL = 'PURCHASE_COURSE_FAIL';

export const COMPLETE_COURSE_START = 'COMPLETE_COURSE_START';
export const COMPLETE_COURSE_SUCCESS = 'COMPLETE_COURSE_SUCCESS';
export const COMPLETE_COURSE_FAIL = 'COMPLETE_COURSE_FAIL';

export const CREATE_PURCHASE_SESSION_START = 'CREATE_PURCHASE_SESSION_START';
export const CREATE_PURCHASE_SESSION_SUCCESS =
  'CREATE_PURCHASE_SESSION_SUCCESS';
export const CREATE_PURCHASE_SESSION_FAIL = 'CREATE_PURCHASE_SESSION_FAIL';

export const RESET_COURSE_STORE = 'RESET_COURSE_STORE';

export type CourseActionTypes =
  | typeof FETCH_COURSES_START
  | typeof FETCH_COURSES_SUCCESS
  | typeof FETCH_COURSES_FAIL
  | typeof FETCH_ACTIVE_COURSES_START
  | typeof FETCH_ACTIVE_COURSES_SUCCESS
  | typeof FETCH_ACTIVE_COURSES_FAIL
  | typeof FETCH_MY_COURSES_START
  | typeof FETCH_MY_COURSES_SUCCESS
  | typeof FETCH_MY_COURSES_FAIL
  | typeof FETCH_MY_COURSE_START
  | typeof FETCH_MY_COURSE_SUCCESS
  | typeof FETCH_MY_COURSE_FAIL
  | typeof FETCH_COURSE_START
  | typeof FETCH_COURSE_SUCCESS
  | typeof FETCH_COURSE_FAIL
  | typeof FETCH_ACTIVE_COURSE_START
  | typeof FETCH_ACTIVE_COURSE_SUCCESS
  | typeof FETCH_ACTIVE_COURSE_FAIL
  | typeof CREATE_COURSE_START
  | typeof CREATE_COURSE_SUCCESS
  | typeof CREATE_COURSE_FAIL
  | typeof UPDATE_COURSE_START
  | typeof UPDATE_COURSE_SUCCESS
  | typeof UPDATE_COURSE_FAIL
  | typeof FINALIZE_PURCHASE_COURSE_START
  | typeof FINALIZE_PURCHASE_COURSE_SUCCESS
  | typeof FINALIZE_PURCHASE_COURSE_FAIL
  | typeof DELETE_COURSE_START
  | typeof DELETE_COURSE_SUCCESS
  | typeof DELETE_COURSE_FAIL
  | typeof FETCH_COURSE_TEST_START
  | typeof FETCH_COURSE_TEST_SUCCESS
  | typeof FETCH_COURSE_TEST_FAIL
  | typeof COMPLETE_COURSE_START
  | typeof COMPLETE_COURSE_SUCCESS
  | typeof COMPLETE_COURSE_FAIL
  | typeof CREATE_PURCHASE_SESSION_START
  | typeof CREATE_PURCHASE_SESSION_SUCCESS
  | typeof CREATE_PURCHASE_SESSION_FAIL
  | typeof RESET_COURSE_STORE;
