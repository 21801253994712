import axios from '../../config/Axios/axios-instance';
import { getFormData } from '../../utility/form/formData';

const API_URL = '/assets';

export const createAsset = (
  image: Blob,
  onSuccess: (url: string) => void,
  onError: (message: string) => void,
) =>
  axios
    .post(
      API_URL,
      getFormData({
        image: image,
      }),
    )
    .then((response) => {
      onSuccess(response.data);
    })
    .catch((err) => {
      onError(err.response.data.message);
    });
