import * as actionTypes from './actionTypes';
import { CourseCertificateActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { CourseCertificate } from '../../domain/CourseCertificate';

export type CourseCertificateStateType = {
  courseCertificateCreateLoading: boolean;
  courseCertificateCreateError: HttpError;
  courseCertificateCreateSuccess: boolean;
  createdCourseCertificate: CourseCertificate | null;
  courseCertificateUpdateLoading: boolean;
  courseCertificateUpdateError: HttpError;
  courseCertificateUpdateSuccess: boolean;
};

export type CourseCertificateActionType = CourseCertificateStateType & {
  type: CourseCertificateActionTypes;
};

export const initialState: CourseCertificateStateType = {
  courseCertificateCreateLoading: false,
  courseCertificateCreateError: null,
  courseCertificateCreateSuccess: false,
  createdCourseCertificate: null,
  courseCertificateUpdateLoading: false,
  courseCertificateUpdateError: null,
  courseCertificateUpdateSuccess: false,
};

const createCourseCertificateStart = (
  state: CourseCertificateStateType,
): CourseCertificateStateType => ({
  ...state,
  courseCertificateCreateLoading: true,
});

const createCourseCertificateSuccess = (
  state: CourseCertificateStateType,
  action: CourseCertificateActionType,
): CourseCertificateStateType => ({
  ...state,
  courseCertificateCreateLoading: false,
  courseCertificateCreateError: null,
  courseCertificateCreateSuccess: true,
  createdCourseCertificate: action.createdCourseCertificate,
});

const createCourseCertificateFail = (
  state: CourseCertificateStateType,
  action: CourseCertificateActionType,
): CourseCertificateStateType => ({
  ...state,
  courseCertificateCreateLoading: false,
  courseCertificateCreateError: action.courseCertificateCreateError,
});

const updateCourseCertificateStart = (
  state: CourseCertificateStateType,
): CourseCertificateStateType => ({
  ...state,
  courseCertificateUpdateLoading: true,
});

const updateCourseCertificateSuccess = (
  state: CourseCertificateStateType,
): CourseCertificateStateType => ({
  ...state,
  courseCertificateUpdateLoading: false,
  courseCertificateUpdateError: null,
  courseCertificateUpdateSuccess: true,
});

const updateCourseCertificateFail = (
  state: CourseCertificateStateType,
  action: CourseCertificateActionType,
): CourseCertificateStateType => ({
  ...state,
  courseCertificateUpdateLoading: false,
  courseCertificateUpdateError: action.courseCertificateUpdateError,
});

const resetCourseStore = (): CourseCertificateStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: CourseCertificateActionType) => {
  switch (action.type) {
    case actionTypes.CREATE_COURSE_CERTIFICATE_START:
      return createCourseCertificateStart(state);
    case actionTypes.CREATE_COURSE_CERTIFICATE_SUCCESS:
      return createCourseCertificateSuccess(state, action);
    case actionTypes.CREATE_COURSE_CERTIFICATE_FAIL:
      return createCourseCertificateFail(state, action);
    case actionTypes.UPDATE_COURSE_CERTIFICATE_START:
      return updateCourseCertificateStart(state);
    case actionTypes.UPDATE_COURSE_CERTIFICATE_SUCCESS:
      return updateCourseCertificateSuccess(state);
    case actionTypes.UPDATE_COURSE_CERTIFICATE_FAIL:
      return updateCourseCertificateFail(state, action);
    case actionTypes.RESET_COURSE_CERTIFICATE_STORE:
      return resetCourseStore();
    default:
      return state;
  }
};

export default reducer;
