export const FETCH_INSTRUCTIONS_START = 'FETCH_INSTRUCTIONS_START';
export const FETCH_INSTRUCTIONS_SUCCESS = 'FETCH_INSTRUCTIONS_SUCCESS';
export const FETCH_INSTRUCTIONS_FAIL = 'FETCH_INSTRUCTIONS_FAIL';

export const FETCH_PENDING_INSTRUCTIONS_START =
  'FETCH_PENDING_INSTRUCTIONS_START';
export const FETCH_PENDING_INSTRUCTIONS_SUCCESS =
  'FETCH_PENDING_INSTRUCTIONS_SUCCESS';
export const FETCH_PENDING_INSTRUCTIONS_FAIL =
  'FETCH_PENDING_INSTRUCTIONS_FAIL';

export const ACKNOWLEDGE_INSTRUCTIONS_START = 'ACKNOWLEDGE_INSTRUCTIONS_START';
export const ACKNOWLEDGE_INSTRUCTIONS_SUCCESS =
  'ACKNOWLEDGE_INSTRUCTIONS_SUCCESS';
export const ACKNOWLEDGE_INSTRUCTIONS_FAIL = 'ACKNOWLEDGE_INSTRUCTIONS_FAIL';

export const PREPARE_TO_SIGN_INSTRUCTIONS_START =
  'PREPARE_TO_SIGN_INSTRUCTIONS_START';
export const PREPARE_TO_SIGN_INSTRUCTIONS_SUCCESS =
  'PREPARE_TO_SIGN_INSTRUCTIONS_SUCCESS';
export const PREPARE_TO_SIGN_INSTRUCTIONS_FAIL =
  'PREPARE_TO_SIGN_INSTRUCTIONS_FAIL';

export const SIGN_INSTRUCTIONS_START = 'SIGN_INSTRUCTIONS_START';
export const SIGN_INSTRUCTIONS_SUCCESS = 'SIGN_INSTRUCTIONS_SUCCESS';
export const SIGN_INSTRUCTIONS_FAIL = 'SIGN_INSTRUCTIONS_FAIL';

export const RESET_SECONDARY_INSTRUCTIONS_STORE =
  'RESET_SECONDARY_INSTRUCTIONS_STORE';
export const RESET_INSTRUCTIONS_STORE = 'RESET_INSTRUCTIONS_STORE';

export type EmployeeInstructionActionTypes =
  | typeof FETCH_INSTRUCTIONS_START
  | typeof FETCH_INSTRUCTIONS_SUCCESS
  | typeof FETCH_INSTRUCTIONS_FAIL
  | typeof FETCH_PENDING_INSTRUCTIONS_START
  | typeof FETCH_PENDING_INSTRUCTIONS_SUCCESS
  | typeof FETCH_PENDING_INSTRUCTIONS_FAIL
  | typeof ACKNOWLEDGE_INSTRUCTIONS_START
  | typeof ACKNOWLEDGE_INSTRUCTIONS_SUCCESS
  | typeof ACKNOWLEDGE_INSTRUCTIONS_FAIL
  | typeof PREPARE_TO_SIGN_INSTRUCTIONS_START
  | typeof PREPARE_TO_SIGN_INSTRUCTIONS_SUCCESS
  | typeof PREPARE_TO_SIGN_INSTRUCTIONS_FAIL
  | typeof SIGN_INSTRUCTIONS_START
  | typeof SIGN_INSTRUCTIONS_SUCCESS
  | typeof SIGN_INSTRUCTIONS_FAIL
  | typeof RESET_SECONDARY_INSTRUCTIONS_STORE
  | typeof RESET_INSTRUCTIONS_STORE;
