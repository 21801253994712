export const FETCH_PROJECTS_START = 'FETCH_PROJECTS_START';
export const FETCH_PROJECTS_SUCCESS = 'FETCH_PROJECTS_SUCCESS';
export const FETCH_PROJECTS_FAIL = 'FETCH_PROJECTS_FAIL';

export const FETCH_PROJECT_START = 'FETCH_PROJECT_START';
export const FETCH_PROJECT_SUCCESS = 'FETCH_PROJECT_SUCCESS';
export const FETCH_PROJECT_FAIL = 'FETCH_PROJECT_FAIL';

export const CREATE_PROJECT_START = 'CREATE_PROJECT_START';
export const CREATE_PROJECT_SUCCESS = 'CREATE_PROJECT_SUCCESS';
export const CREATE_PROJECT_FAIL = 'CREATE_PROJECT_FAIL';

export const UPDATE_PROJECT_START = 'UPDATE_PROJECT_START';
export const UPDATE_PROJECT_SUCCESS = 'UPDATE_PROJECT_SUCCESS';
export const UPDATE_PROJECT_FAIL = 'UPDATE_PROJECT_FAIL';

export const DELETE_PROJECT_START = 'DELETE_PROJECT_START';
export const DELETE_PROJECT_SUCCESS = 'DELETE_PROJECT_SUCCESS';
export const DELETE_PROJECT_FAIL = 'DELETE_PROJECT_FAIL';

export const RESET_PROJECT_STORE = 'RESET_PROJECT_STORE';
export const RESET_CREATED_PROJECT = 'RESET_CREATED_PROJECT';

export type ProjectActionTypes =
  | typeof FETCH_PROJECTS_START
  | typeof FETCH_PROJECTS_SUCCESS
  | typeof FETCH_PROJECTS_FAIL
  | typeof FETCH_PROJECT_START
  | typeof FETCH_PROJECT_SUCCESS
  | typeof FETCH_PROJECT_FAIL
  | typeof CREATE_PROJECT_START
  | typeof CREATE_PROJECT_SUCCESS
  | typeof CREATE_PROJECT_FAIL
  | typeof UPDATE_PROJECT_START
  | typeof UPDATE_PROJECT_SUCCESS
  | typeof UPDATE_PROJECT_FAIL
  | typeof RESET_PROJECT_STORE
  | typeof RESET_CREATED_PROJECT
  | typeof DELETE_PROJECT_START
  | typeof DELETE_PROJECT_SUCCESS
  | typeof DELETE_PROJECT_FAIL;
