import React, { useState } from 'react';
import styles from './LanguageSwitcher.module.scss';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { selectLocale } from '../../store/auth/actions';
import { StoreState } from '../../config/StoreProvider/StoreProvider';
import { Popover } from '@mui/material';
import { Locale } from '../../domain/Translation';
import { useIntl } from 'react-intl';
import { translate } from '../../utility/messageTranslator/translate';
import { UserSetting, UserSettingType } from '../../domain/UserSetting';
import * as userSettingService from '../../store/user-setting/service';
import { User } from '../../domain/User';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

type Props = {
  onSelectLocale: (locale: Locale) => void;
  selectedLocale: Locale;
  onSetSetting: (type: UserSettingType, value: string) => void;
  createdUserSetting: UserSetting | null;
  currentUser: User | null;
};

const LanguageSwitcher = ({
  onSelectLocale,
  selectedLocale,
  onSetSetting,
  currentUser,
}: Props) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const location = useLocation();
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<any>(null);

  const LANGUAGES = [
    {
      label: translate(intl, 'LANGUAGES.EN'),
      value: 'en',
    },
    {
      label: translate(intl, 'LANGUAGES.LT'),
      value: 'lt',
    },
    {
      label: translate(intl, 'LANGUAGES.NO'),
      value: 'no',
    },
  ];

  const selectedLocaleOption = LANGUAGES.find(
    (locale) => locale.value === selectedLocale,
  );

  const handleClick = (locale: Locale) => {
    if (selectedLocale === locale) {
      setIsPopoverOpen(false);
      return;
    }

    if (currentUser) {
      onSetSetting(UserSettingType.LANGUAGE, locale);
    }

    onSelectLocale(locale);

    const currentRoute = location.pathname;

    navigate(currentRoute.replace(selectedLocale, locale));

    setIsPopoverOpen(false);
  };

  const renderActions = () => {
    return LANGUAGES.map((language, index) => (
      <button
        className={styles.popoverListItem}
        key={index.toString()}
        onClick={() => handleClick(language.value as Locale)}
      >
        {language?.label}
      </button>
    ));
  };

  return (
    <>
      <div
        className={styles.switcherButton}
        onClick={(event) => {
          setAnchorEl(event.currentTarget);
          setIsPopoverOpen(true);
        }}
      >
        {selectedLocaleOption?.label}
      </div>
      <Popover
        className={styles.popover}
        open={isPopoverOpen}
        onClose={() => setIsPopoverOpen(false)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <div className={styles.popoverContent}>
          <ul className={styles.popoverList}>{renderActions()}</ul>
        </div>
      </Popover>
    </>
  );
};

const mapStateToProps = (state: StoreState) => ({
  selectedLocale: state.auth.selectedLocale,
  createdUserSetting: state.userSetting.createdUserSetting,
  currentUser: state.user.currentUser,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onSelectLocale: (locale: Locale) => dispatch(selectLocale(locale)),
  onSetSetting: (type: UserSettingType, value: string) =>
    dispatch(
      userSettingService.setUserSetting({
        type,
        value,
      }),
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSwitcher);
