export const FETCH_SECTIONS_START = 'FETCH_SECTIONS_START';
export const FETCH_SECTIONS_SUCCESS = 'FETCH_SECTIONS_SUCCESS';
export const FETCH_SECTIONS_FAIL = 'FETCH_SECTIONS_FAIL';

export const CREATE_SECTION_START = 'CREATE_SECTION_START';
export const CREATE_SECTION_SUCCESS = 'CREATE_SECTION_SUCCESS';
export const CREATE_SECTION_FAIL = 'CREATE_SECTION_FAIL';

export const UPDATE_SECTION_START = 'UPDATE_SECTION_START';
export const UPDATE_SECTION_SUCCESS = 'UPDATE_SECTION_SUCCESS';
export const UPDATE_SECTION_FAIL = 'UPDATE_SECTION_FAIL';

export const DELETE_SECTION_START = 'DELETE_SECTION_START';
export const DELETE_SECTION_SUCCESS = 'DELETE_SECTION_SUCCESS';
export const DELETE_SECTION_FAIL = 'DELETE_SECTION_FAIL';

export const UPDATE_SECTIONS_PRIORITY_START = 'UPDATE_SECTIONS_PRIORITY_START';
export const UPDATE_SECTIONS_PRIORITY_SUCCESS =
  'UPDATE_SECTIONS_PRIORITY_SUCCESS';
export const UPDATE_SECTIONS_PRIORITY_FAIL = 'UPDATE_SECTIONS_PRIORITY_FAIL';

export const TOGGLE_SECTION_STATUS_START = 'TOGGLE_SECTION_STATUS_START';
export const TOGGLE_SECTION_STATUS_SUCCESS = 'TOGGLE_SECTION_STATUS_SUCCESS';
export const TOGGLE_SECTION_STATUS_FAIL = 'TOGGLE_SECTION_STATUS_FAIL';

export const RESET_SECTION_STORE = 'RESET_SECTION_STORE';

export type SectionActionTypes =
  | typeof FETCH_SECTIONS_START
  | typeof FETCH_SECTIONS_SUCCESS
  | typeof FETCH_SECTIONS_FAIL
  | typeof CREATE_SECTION_START
  | typeof CREATE_SECTION_SUCCESS
  | typeof CREATE_SECTION_FAIL
  | typeof UPDATE_SECTION_START
  | typeof UPDATE_SECTION_SUCCESS
  | typeof UPDATE_SECTION_FAIL
  | typeof DELETE_SECTION_START
  | typeof DELETE_SECTION_SUCCESS
  | typeof DELETE_SECTION_FAIL
  | typeof RESET_SECTION_STORE
  | typeof TOGGLE_SECTION_STATUS_START
  | typeof TOGGLE_SECTION_STATUS_SUCCESS
  | typeof TOGGLE_SECTION_STATUS_FAIL
  | typeof UPDATE_SECTIONS_PRIORITY_START
  | typeof UPDATE_SECTIONS_PRIORITY_SUCCESS
  | typeof UPDATE_SECTIONS_PRIORITY_FAIL;
