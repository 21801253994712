export const FETCH_MEETINGS_START = 'FETCH_MEETINGS_START';
export const FETCH_MEETINGS_SUCCESS = 'FETCH_MEETINGS_SUCCESS';
export const FETCH_MEETINGS_FAIL = 'FETCH_MEETINGS_FAIL';

export const FETCH_PENDING_MEETING_START = 'FETCH_PENDING_MEETING_START';
export const FETCH_PENDING_MEETING_SUCCESS = 'FETCH_PENDING_MEETING_SUCCESS';
export const FETCH_PENDING_MEETING_FAIL = 'FETCH_PENDING_MEETING_FAIL';

export const ACKNOWLEDGE_MEETINGS_START = 'ACKNOWLEDGE_MEETINGS_START';
export const ACKNOWLEDGE_MEETINGS_SUCCESS = 'ACKNOWLEDGE_MEETINGS_SUCCESS';
export const ACKNOWLEDGE_MEETINGS_FAIL = 'ACKNOWLEDGE_MEETINGS_FAIL';

export const PREPARE_TO_SIGN_MEETINGS_START = 'PREPARE_TO_SIGN_MEETINGS_START';
export const PREPARE_TO_SIGN_MEETINGS_SUCCESS =
  'PREPARE_TO_SIGN_MEETINGS_SUCCESS';
export const PREPARE_TO_SIGN_MEETINGS_FAIL = 'PREPARE_TO_SIGN_MEETINGS_FAIL';

export const SIGN_MEETINGS_START = 'SIGN_MEETINGS_START';
export const SIGN_MEETINGS_SUCCESS = 'SIGN_MEETINGS_SUCCESS';
export const SIGN_MEETINGS_FAIL = 'SIGN_MEETINGS_FAIL';

export const RESET_MEETINGS_STORE = 'RESET_MEETINGS_STORE';

export type EmployeeMeetingActionTypes =
  | typeof FETCH_MEETINGS_START
  | typeof FETCH_MEETINGS_SUCCESS
  | typeof FETCH_MEETINGS_FAIL
  | typeof FETCH_PENDING_MEETING_START
  | typeof FETCH_PENDING_MEETING_SUCCESS
  | typeof FETCH_PENDING_MEETING_FAIL
  | typeof ACKNOWLEDGE_MEETINGS_START
  | typeof ACKNOWLEDGE_MEETINGS_SUCCESS
  | typeof ACKNOWLEDGE_MEETINGS_FAIL
  | typeof PREPARE_TO_SIGN_MEETINGS_START
  | typeof PREPARE_TO_SIGN_MEETINGS_SUCCESS
  | typeof PREPARE_TO_SIGN_MEETINGS_FAIL
  | typeof SIGN_MEETINGS_START
  | typeof SIGN_MEETINGS_SUCCESS
  | typeof SIGN_MEETINGS_FAIL
  | typeof RESET_MEETINGS_STORE;
