import * as actionTypes from './actionTypes';
import { EmployeeMeetingActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { ListResults } from '../../common/List/List';
import { EmployeeMeeting } from '../../domain/EmployeeMeeting';

export type EmployeeMeetingsStateType = {
  employeeMeetings: ListResults<EmployeeMeeting> | null;
  employeeMeetingsLoading: boolean;
  employeeMeetingsError: HttpError;
  pendingMeeting: EmployeeMeeting | null;
  pendingMeetingLoading: boolean;
  pendingMeetingError: HttpError;
  employeeMeetingsListUpdateNeeded: boolean;
  acknowledgeMeetingsLoading: boolean;
  acknowledgeMeetingsSuccess: boolean;
  acknowledgeMeetingsError: HttpError;
  prepareToSignMeetingsLoading: boolean;
  prepareToSignMeetingsSuccess: boolean;
  prepareToSignMeetingsError: HttpError;
  signMeetingsLoading: boolean;
  signMeetingsSuccess: boolean;
  signMeetingsError: HttpError;
};

export type EmployeeMeetingsActionType = EmployeeMeetingsStateType & {
  type: EmployeeMeetingActionTypes;
};

export const initialState: EmployeeMeetingsStateType = {
  employeeMeetings: null,
  employeeMeetingsLoading: false,
  employeeMeetingsError: null,
  pendingMeeting: null,
  pendingMeetingLoading: false,
  pendingMeetingError: null,
  employeeMeetingsListUpdateNeeded: false,
  acknowledgeMeetingsError: null,
  acknowledgeMeetingsLoading: false,
  acknowledgeMeetingsSuccess: false,
  prepareToSignMeetingsSuccess: false,
  prepareToSignMeetingsError: null,
  prepareToSignMeetingsLoading: false,
  signMeetingsSuccess: false,
  signMeetingsLoading: false,
  signMeetingsError: null,
};

const fetchMeetingsStart = (
  state: EmployeeMeetingsStateType,
): EmployeeMeetingsStateType => ({
  ...state,
  employeeMeetingsLoading: true,
});

const fetchMeetingsSuccess = (
  state: EmployeeMeetingsStateType,
  action: EmployeeMeetingsActionType,
): EmployeeMeetingsStateType => ({
  ...state,
  employeeMeetings: action.employeeMeetings,
  employeeMeetingsLoading: false,
  employeeMeetingsError: null,
  employeeMeetingsListUpdateNeeded: false,
});

const fetchMeetingsFail = (
  state: EmployeeMeetingsStateType,
  action: EmployeeMeetingsActionType,
): EmployeeMeetingsStateType => ({
  ...state,
  employeeMeetingsError: action.employeeMeetingsError,
  employeeMeetingsLoading: false,
});

const fetchPendingMeetingStart = (
  state: EmployeeMeetingsStateType,
): EmployeeMeetingsStateType => ({
  ...state,
  pendingMeetingLoading: true,
  signMeetingsLoading: false,
  prepareToSignMeetingsLoading: false,
});

const fetchPendingMeetingSuccess = (
  state: EmployeeMeetingsStateType,
  action: EmployeeMeetingsActionType,
): EmployeeMeetingsStateType => ({
  ...state,
  pendingMeeting: action.pendingMeeting,
  pendingMeetingLoading: false,
  pendingMeetingError: null,
});

const fetchPendingMeetingFail = (
  state: EmployeeMeetingsStateType,
  action: EmployeeMeetingsActionType,
): EmployeeMeetingsStateType => ({
  ...state,
  pendingMeetingError: action.pendingMeetingError,
  pendingMeetingLoading: false,
});

const acknowledgeMeetingsStart = (
  state: EmployeeMeetingsStateType,
): EmployeeMeetingsStateType => ({
  ...state,
  acknowledgeMeetingsLoading: true,
  acknowledgeMeetingsSuccess: false,
});

const acknowledgeMeetingsSuccess = (
  state: EmployeeMeetingsStateType,
): EmployeeMeetingsStateType => ({
  ...state,
  acknowledgeMeetingsSuccess: true,
  acknowledgeMeetingsLoading: false,
  acknowledgeMeetingsError: null,
});

const acknowledgeMeetingsFail = (
  state: EmployeeMeetingsStateType,
  action: EmployeeMeetingsActionType,
): EmployeeMeetingsStateType => ({
  ...state,
  acknowledgeMeetingsError: action.acknowledgeMeetingsError,
  acknowledgeMeetingsLoading: false,
});

const prepareToSignMeetingsStart = (
  state: EmployeeMeetingsStateType,
): EmployeeMeetingsStateType => ({
  ...state,
  prepareToSignMeetingsLoading: true,
  prepareToSignMeetingsSuccess: false,
});

const prepareToSignMeetingsSuccess = (
  state: EmployeeMeetingsStateType,
): EmployeeMeetingsStateType => ({
  ...state,
  prepareToSignMeetingsLoading: false,
  prepareToSignMeetingsError: null,
  prepareToSignMeetingsSuccess: true,
});

const prepareToSignMeetingsFail = (
  state: EmployeeMeetingsStateType,
  action: EmployeeMeetingsActionType,
): EmployeeMeetingsStateType => ({
  ...state,
  prepareToSignMeetingsLoading: false,
  prepareToSignMeetingsError: action.prepareToSignMeetingsError,
});

const signMeetingsStart = (
  state: EmployeeMeetingsStateType,
): EmployeeMeetingsStateType => ({
  ...state,
  signMeetingsLoading: true,
  signMeetingsSuccess: false,
});

const signMeetingsSuccess = (
  state: EmployeeMeetingsStateType,
): EmployeeMeetingsStateType => ({
  ...state,
  signMeetingsLoading: false,
  signMeetingsError: null,
  signMeetingsSuccess: true,
  employeeMeetingsListUpdateNeeded: true,
});

const signMeetingsFail = (
  state: EmployeeMeetingsStateType,
  action: EmployeeMeetingsActionType,
): EmployeeMeetingsStateType => ({
  ...state,
  signMeetingsLoading: false,
  signMeetingsError: action.signMeetingsError,
});

const resetMeetingsStore = (): EmployeeMeetingsStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: EmployeeMeetingsActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_MEETINGS_START:
      return fetchMeetingsStart(state);
    case actionTypes.FETCH_MEETINGS_SUCCESS:
      return fetchMeetingsSuccess(state, action);
    case actionTypes.FETCH_MEETINGS_FAIL:
      return fetchMeetingsFail(state, action);
    case actionTypes.FETCH_PENDING_MEETING_START:
      return fetchPendingMeetingStart(state);
    case actionTypes.FETCH_PENDING_MEETING_SUCCESS:
      return fetchPendingMeetingSuccess(state, action);
    case actionTypes.FETCH_PENDING_MEETING_FAIL:
      return fetchPendingMeetingFail(state, action);
    case actionTypes.ACKNOWLEDGE_MEETINGS_START:
      return acknowledgeMeetingsStart(state);
    case actionTypes.ACKNOWLEDGE_MEETINGS_SUCCESS:
      return acknowledgeMeetingsSuccess(state);
    case actionTypes.ACKNOWLEDGE_MEETINGS_FAIL:
      return acknowledgeMeetingsFail(state, action);
    case actionTypes.PREPARE_TO_SIGN_MEETINGS_START:
      return prepareToSignMeetingsStart(state);
    case actionTypes.PREPARE_TO_SIGN_MEETINGS_SUCCESS:
      return prepareToSignMeetingsSuccess(state);
    case actionTypes.PREPARE_TO_SIGN_MEETINGS_FAIL:
      return prepareToSignMeetingsFail(state, action);
    case actionTypes.SIGN_MEETINGS_START:
      return signMeetingsStart(state);
    case actionTypes.SIGN_MEETINGS_SUCCESS:
      return signMeetingsSuccess(state);
    case actionTypes.SIGN_MEETINGS_FAIL:
      return signMeetingsFail(state, action);
    case actionTypes.RESET_MEETINGS_STORE:
      return resetMeetingsStore();
    default:
      return state;
  }
};

export default reducer;
