export const FETCH_MEETINGS_START = 'FETCH_MEETINGS_START';
export const FETCH_MEETINGS_SUCCESS = 'FETCH_MEETINGS_SUCCESS';
export const FETCH_MEETINGS_FAIL = 'FETCH_MEETINGS_FAIL';

export const CREATE_MEETING_START = 'CREATE_MEETING_START';
export const CREATE_MEETING_SUCCESS = 'CREATE_MEETING_SUCCESS';
export const CREATE_MEETING_FAIL = 'CREATE_MEETING_FAIL';

export const UPDATE_MEETING_START = 'UPDATE_MEETING_START';
export const UPDATE_MEETING_SUCCESS = 'UPDATE_MEETING_SUCCESS';
export const UPDATE_MEETING_FAIL = 'UPDATE_MEETING_FAIL';

export const DELETE_MEETING_START = 'DELETE_MEETING_START';
export const DELETE_MEETING_SUCCESS = 'DELETE_MEETING_SUCCESS';
export const DELETE_MEETING_FAIL = 'DELETE_MEETING_FAIL';

export const RESET_MEETING_STORE = 'RESET_MEETING_STORE';

export type MeetingActionTypes =
  | typeof FETCH_MEETINGS_START
  | typeof FETCH_MEETINGS_SUCCESS
  | typeof FETCH_MEETINGS_FAIL
  | typeof CREATE_MEETING_START
  | typeof CREATE_MEETING_SUCCESS
  | typeof CREATE_MEETING_FAIL
  | typeof UPDATE_MEETING_START
  | typeof UPDATE_MEETING_SUCCESS
  | typeof UPDATE_MEETING_FAIL
  | typeof DELETE_MEETING_START
  | typeof DELETE_MEETING_SUCCESS
  | typeof DELETE_MEETING_FAIL
  | typeof RESET_MEETING_STORE;
