import * as actionTypes from './actionTypes';
import { CompanyInstructionActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { Employee } from '../../domain/Employee';

export type CompanyInstructionStateType = {
  companyInstructionsListUpdateNeeded: boolean;
  companyInstructionCreateLoading: boolean;
  companyInstructionCreateError: HttpError;
  companyInstructionUpdateLoading: boolean;
  companyInstructionUpdateError: HttpError;
  companyInstructionDeleteLoading: boolean;
  companyInstructionDeleteError: HttpError;
  companyInstructionStatusLoading: boolean;
  companyInstructionStatusSuccess: boolean;
  companyInstructionStatusError: HttpError;
  askToSignLoading: boolean;
  askToSignSuccess: boolean;
  askToSignError: HttpError;
  signedEmployees: Employee[] | null;
  signedEmployeesLoading: boolean;
  signedEmployeesError: HttpError;
};

export type CompanyInstructionActionType = CompanyInstructionStateType & {
  type: CompanyInstructionActionTypes;
};

export const initialState: CompanyInstructionStateType = {
  companyInstructionsListUpdateNeeded: false,
  companyInstructionCreateLoading: false,
  companyInstructionCreateError: null,
  companyInstructionUpdateLoading: false,
  companyInstructionUpdateError: null,
  companyInstructionDeleteLoading: false,
  companyInstructionDeleteError: null,
  companyInstructionStatusSuccess: false,
  companyInstructionStatusLoading: false,
  companyInstructionStatusError: null,
  askToSignError: null,
  askToSignSuccess: false,
  askToSignLoading: false,
  signedEmployees: null,
  signedEmployeesError: null,
  signedEmployeesLoading: false,
};

const createCompanyInstructionStart = (
  state: CompanyInstructionStateType,
): CompanyInstructionStateType => ({
  ...state,
  companyInstructionCreateLoading: true,
});

const createCompanyInstructionSuccess = (
  state: CompanyInstructionStateType,
): CompanyInstructionStateType => ({
  ...state,
  companyInstructionCreateLoading: false,
  companyInstructionCreateError: null,
  companyInstructionsListUpdateNeeded: true,
});

const createCompanyInstructionFail = (
  state: CompanyInstructionStateType,
  action: CompanyInstructionActionType,
): CompanyInstructionStateType => ({
  ...state,
  companyInstructionCreateLoading: false,
  companyInstructionCreateError: action.companyInstructionCreateError,
});

const updateCompanyInstructionStart = (
  state: CompanyInstructionStateType,
): CompanyInstructionStateType => ({
  ...state,
  companyInstructionUpdateLoading: true,
});

const updateCompanyInstructionSuccess = (
  state: CompanyInstructionStateType,
): CompanyInstructionStateType => ({
  ...state,
  companyInstructionUpdateLoading: false,
  companyInstructionUpdateError: null,
  companyInstructionsListUpdateNeeded: true,
});

const updateCompanyInstructionFail = (
  state: CompanyInstructionStateType,
  action: CompanyInstructionActionType,
): CompanyInstructionStateType => ({
  ...state,
  companyInstructionUpdateLoading: false,
  companyInstructionUpdateError: action.companyInstructionUpdateError,
});

const deleteCompanyInstructionStart = (
  state: CompanyInstructionStateType,
): CompanyInstructionStateType => ({
  ...state,
  companyInstructionDeleteLoading: true,
});

const deleteCompanyInstructionSuccess = (
  state: CompanyInstructionStateType,
): CompanyInstructionStateType => ({
  ...state,
  companyInstructionDeleteLoading: false,
  companyInstructionDeleteError: null,
  companyInstructionsListUpdateNeeded: true,
});

const deleteCompanyInstructionFail = (
  state: CompanyInstructionStateType,
  action: CompanyInstructionActionType,
): CompanyInstructionStateType => ({
  ...state,
  companyInstructionDeleteLoading: false,
  companyInstructionDeleteError: action.companyInstructionDeleteError,
});

const toggleCompanyInstructionStatusStart = (
  state: CompanyInstructionStateType,
): CompanyInstructionStateType => ({
  ...state,
  companyInstructionStatusLoading: true,
});

const toggleCompanyInstructionStatusSuccess = (
  state: CompanyInstructionStateType,
): CompanyInstructionStateType => ({
  ...state,
  companyInstructionStatusLoading: false,
  companyInstructionStatusError: null,
  companyInstructionStatusSuccess: true,
  companyInstructionsListUpdateNeeded: true,
});

const toggleCompanyInstructionStatusFail = (
  state: CompanyInstructionStateType,
  action: CompanyInstructionActionType,
): CompanyInstructionStateType => ({
  ...state,
  companyInstructionStatusLoading: false,
  companyInstructionStatusError: action.companyInstructionStatusError,
});

const askToSignStart = (
  state: CompanyInstructionStateType,
): CompanyInstructionStateType => ({
  ...state,
  askToSignLoading: true,
  askToSignSuccess: false,
});

const askToSignSuccess = (
  state: CompanyInstructionStateType,
): CompanyInstructionStateType => ({
  ...state,
  askToSignLoading: false,
  askToSignError: null,
  askToSignSuccess: true,
});

const askToSignFail = (
  state: CompanyInstructionStateType,
  action: CompanyInstructionActionType,
): CompanyInstructionStateType => ({
  ...state,
  askToSignLoading: false,
  askToSignError: action.askToSignError,
});

const fetchSignedEmployeesStart = (
  state: CompanyInstructionStateType,
): CompanyInstructionStateType => ({
  ...state,
  signedEmployeesLoading: true,
  signedEmployees: null,
  signedEmployeesError: null,
});

const fetchSignedEmployeesSuccess = (
  state: CompanyInstructionStateType,
  action: CompanyInstructionActionType,
): CompanyInstructionStateType => ({
  ...state,
  signedEmployeesLoading: false,
  signedEmployeesError: null,
  signedEmployees: action.signedEmployees,
});

const fetchSignedEmployeesFail = (
  state: CompanyInstructionStateType,
  action: CompanyInstructionActionType,
): CompanyInstructionStateType => ({
  ...state,
  signedEmployeesLoading: false,
  signedEmployeesError: action.signedEmployeesError,
});

const resetCompanyInstructionStore = (): CompanyInstructionStateType => ({
  ...initialState,
});

const reducer = (
  state = initialState,
  action: CompanyInstructionActionType,
) => {
  switch (action.type) {
    case actionTypes.CREATE_COMPANY_INSTRUCTION_START:
      return createCompanyInstructionStart(state);
    case actionTypes.CREATE_COMPANY_INSTRUCTION_SUCCESS:
      return createCompanyInstructionSuccess(state);
    case actionTypes.CREATE_COMPANY_INSTRUCTION_FAIL:
      return createCompanyInstructionFail(state, action);
    case actionTypes.UPDATE_COMPANY_INSTRUCTION_START:
      return updateCompanyInstructionStart(state);
    case actionTypes.UPDATE_COMPANY_INSTRUCTION_SUCCESS:
      return updateCompanyInstructionSuccess(state);
    case actionTypes.UPDATE_COMPANY_INSTRUCTION_FAIL:
      return updateCompanyInstructionFail(state, action);
    case actionTypes.DELETE_COMPANY_INSTRUCTION_START:
      return deleteCompanyInstructionStart(state);
    case actionTypes.DELETE_COMPANY_INSTRUCTION_SUCCESS:
      return deleteCompanyInstructionSuccess(state);
    case actionTypes.DELETE_COMPANY_INSTRUCTION_FAIL:
      return deleteCompanyInstructionFail(state, action);
    case actionTypes.RESET_COMPANY_INSTRUCTION_STORE:
      return resetCompanyInstructionStore();
    case actionTypes.ASK_TO_SIGN_START:
      return askToSignStart(state);
    case actionTypes.ASK_TO_SIGN_SUCCESS:
      return askToSignSuccess(state);
    case actionTypes.ASK_TO_SIGN_FAIL:
      return askToSignFail(state, action);
    case actionTypes.TOGGLE_COMPANY_INSTRUCTION_STATUS_START:
      return toggleCompanyInstructionStatusStart(state);
    case actionTypes.TOGGLE_COMPANY_INSTRUCTION_STATUS_SUCCESS:
      return toggleCompanyInstructionStatusSuccess(state);
    case actionTypes.TOGGLE_COMPANY_INSTRUCTION_STATUS_FAIL:
      return toggleCompanyInstructionStatusFail(state, action);
    case actionTypes.FETCH_SIGNED_EMPLOYEES_START:
      return fetchSignedEmployeesStart(state);
    case actionTypes.FETCH_SIGNED_EMPLOYEES_SUCCESS:
      return fetchSignedEmployeesSuccess(state, action);
    case actionTypes.FETCH_SIGNED_EMPLOYEES_FAIL:
      return fetchSignedEmployeesFail(state, action);
    default:
      return state;
  }
};

export default reducer;
