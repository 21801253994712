export const FETCH_USER_START = 'FETCH_USER_START';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAIL = 'FETCH_USER_FAIL';

export const FETCH_USERS_START = 'FETCH_USERS_START';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAIL = 'FETCH_USERS_FAIL';

export const FETCH_ALL_USERS_START = 'FETCH_ALL_USERS_START';
export const FETCH_ALL_USERS_SUCCESS = 'FETCH_ALL_USERS_SUCCESS';
export const FETCH_ALL_USERS_FAIL = 'FETCH_ALL_USERS_FAIL';

export const CREATE_USER_START = 'CREATE_USER_START';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAIL = 'CREATE_USER_FAIL';

export const UPDATE_USER_START = 'UPDATE_USER_START';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL';

export const DELETE_USER_START = 'DELETE_USER_START';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAIL = 'DELETE_USER_FAIL';

export const FETCH_ME_START = 'FETCH_ME_START';
export const FETCH_ME_SUCCESS = 'FETCH_ME_SUCCESS';
export const FETCH_ME_FAIL = 'FETCH_ME_FAIL';

export const UPDATE_USER_INFO_START = 'UPDATE_USER_INFO_START';
export const UPDATE_USER_INFO_SUCCESS = 'UPDATE_USER_INFO_SUCCESS';
export const UPDATE_USER_INFO_FAIL = 'UPDATE_USER_INFO_FAIL';
export const RESET_USER_INFO_STORE = 'RESET_USER_INFO_STORE';

export const RESET_CREATED_USER = 'RESET_CREATED_USER';

export const RESET_USER_STORE = 'RESET_USER_STORE';

export const LOGOUT = 'LOGOUT';

export type UserActionTypes =
  | typeof FETCH_USER_START
  | typeof FETCH_USER_SUCCESS
  | typeof FETCH_USER_FAIL
  | typeof FETCH_ALL_USERS_START
  | typeof FETCH_ALL_USERS_SUCCESS
  | typeof FETCH_ALL_USERS_FAIL
  | typeof FETCH_USERS_START
  | typeof FETCH_USERS_SUCCESS
  | typeof FETCH_USERS_FAIL
  | typeof CREATE_USER_START
  | typeof CREATE_USER_SUCCESS
  | typeof CREATE_USER_FAIL
  | typeof UPDATE_USER_START
  | typeof UPDATE_USER_SUCCESS
  | typeof UPDATE_USER_FAIL
  | typeof DELETE_USER_START
  | typeof DELETE_USER_SUCCESS
  | typeof DELETE_USER_FAIL
  | typeof FETCH_ME_START
  | typeof FETCH_ME_SUCCESS
  | typeof FETCH_ME_FAIL
  | typeof UPDATE_USER_INFO_START
  | typeof UPDATE_USER_INFO_SUCCESS
  | typeof UPDATE_USER_INFO_FAIL
  | typeof RESET_USER_INFO_STORE
  | typeof RESET_USER_STORE
  | typeof RESET_CREATED_USER
  | typeof LOGOUT;
