import { Roles } from '../../domain/Role';
import { CompanySystem } from '../../domain/Company';

export const isVisibleToSystem = (
  selectedRole: Roles | null,
  selectedSystem: number,
  roles: Roles[],
  systems?: CompanySystem[],
) =>
  selectedRole &&
  roles.includes(selectedRole) &&
  (selectedRole === Roles.ADMIN
    ? true
    : selectedRole === Roles.OWNER || selectedRole === Roles.MANAGER
    ? !systems || systems.includes(selectedSystem ?? 0)
    : true);

export const isVisibleRoute = (
  selectedRole: Roles | null,
  availableSystems: CompanySystem[] | null,
  selectedSystem: CompanySystem | null,
  system: CompanySystem,
) => {
  if (!selectedRole) {
    return false;
  }

  if (selectedRole === Roles.ADMIN) {
    return true;
  }

  return (
    availableSystems &&
    availableSystems.includes(system) &&
    selectedSystem === system
  );
};
