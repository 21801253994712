import * as actionTypes from './actionTypes';
import { RiskGroupActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { ListResults } from '../../common/List/List';
import { RiskGroup } from '../../domain/RiskGroup';

export type RiskGroupStateType = {
  riskGroupsList: ListResults<RiskGroup> | null;
  riskGroupsLoading: boolean;
  riskGroupsError: HttpError;
  riskGroupsListUpdateNeeded: boolean;
  riskGroupCreateLoading: boolean;
  riskGroupCreateError: HttpError;
  riskGroupUpdateLoading: boolean;
  riskGroupUpdateError: HttpError;
  riskGroupDeleteLoading: boolean;
  riskGroupDeleteError: HttpError;
};

export type RiskGroupActionType = RiskGroupStateType & {
  type: RiskGroupActionTypes;
};

export const initialState: RiskGroupStateType = {
  riskGroupsList: null,
  riskGroupsLoading: false,
  riskGroupsListUpdateNeeded: false,
  riskGroupsError: null,
  riskGroupCreateLoading: false,
  riskGroupCreateError: null,
  riskGroupUpdateLoading: false,
  riskGroupUpdateError: null,
  riskGroupDeleteLoading: false,
  riskGroupDeleteError: null,
};

const fetchRiskGroupsStart = (
  state: RiskGroupStateType,
): RiskGroupStateType => ({
  ...state,
  riskGroupsLoading: true,
});

const fetchRiskGroupsSuccess = (
  state: RiskGroupStateType,
  action: RiskGroupActionType,
): RiskGroupStateType => ({
  ...state,
  riskGroupsList: action.riskGroupsList,
  riskGroupsLoading: false,
  riskGroupsError: null,
  riskGroupsListUpdateNeeded: false,
});

const fetchRiskGroupsFail = (
  state: RiskGroupStateType,
  action: RiskGroupActionType,
): RiskGroupStateType => ({
  ...state,
  riskGroupsError: action.riskGroupsError,
  riskGroupsLoading: false,
});

const createRiskGroupStart = (
  state: RiskGroupStateType,
): RiskGroupStateType => ({
  ...state,
  riskGroupCreateLoading: true,
});

const createRiskGroupSuccess = (
  state: RiskGroupStateType,
): RiskGroupStateType => ({
  ...state,
  riskGroupCreateLoading: false,
  riskGroupCreateError: null,
  riskGroupsListUpdateNeeded: true,
});

const createRiskGroupFail = (
  state: RiskGroupStateType,
  action: RiskGroupActionType,
): RiskGroupStateType => ({
  ...state,
  riskGroupCreateLoading: false,
  riskGroupCreateError: action.riskGroupCreateError,
});

const updateRiskGroupStart = (
  state: RiskGroupStateType,
): RiskGroupStateType => ({
  ...state,
  riskGroupUpdateLoading: true,
});

const updateRiskGroupSuccess = (
  state: RiskGroupStateType,
): RiskGroupStateType => ({
  ...state,
  riskGroupUpdateLoading: false,
  riskGroupUpdateError: null,
  riskGroupsListUpdateNeeded: true,
});

const updateRiskGroupFail = (
  state: RiskGroupStateType,
  action: RiskGroupActionType,
): RiskGroupStateType => ({
  ...state,
  riskGroupUpdateLoading: false,
  riskGroupUpdateError: action.riskGroupUpdateError,
});

const deleteRiskGroupStart = (
  state: RiskGroupStateType,
): RiskGroupStateType => ({
  ...state,
  riskGroupDeleteLoading: true,
});

const deleteRiskGroupSuccess = (
  state: RiskGroupStateType,
): RiskGroupStateType => ({
  ...state,
  riskGroupDeleteLoading: false,
  riskGroupDeleteError: null,
  riskGroupsListUpdateNeeded: true,
});

const deleteRiskGroupFail = (
  state: RiskGroupStateType,
  action: RiskGroupActionType,
): RiskGroupStateType => ({
  ...state,
  riskGroupDeleteLoading: false,
  riskGroupDeleteError: action.riskGroupDeleteError,
});

const resetRiskGroupStore = (): RiskGroupStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: RiskGroupActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_RISK_GROUPS_START:
      return fetchRiskGroupsStart(state);
    case actionTypes.FETCH_RISK_GROUPS_SUCCESS:
      return fetchRiskGroupsSuccess(state, action);
    case actionTypes.FETCH_RISK_GROUPS_FAIL:
      return fetchRiskGroupsFail(state, action);
    case actionTypes.CREATE_RISK_GROUP_START:
      return createRiskGroupStart(state);
    case actionTypes.CREATE_RISK_GROUP_SUCCESS:
      return createRiskGroupSuccess(state);
    case actionTypes.CREATE_RISK_GROUP_FAIL:
      return createRiskGroupFail(state, action);
    case actionTypes.UPDATE_RISK_GROUP_START:
      return updateRiskGroupStart(state);
    case actionTypes.UPDATE_RISK_GROUP_SUCCESS:
      return updateRiskGroupSuccess(state);
    case actionTypes.UPDATE_RISK_GROUP_FAIL:
      return updateRiskGroupFail(state, action);
    case actionTypes.DELETE_RISK_GROUP_START:
      return deleteRiskGroupStart(state);
    case actionTypes.DELETE_RISK_GROUP_SUCCESS:
      return deleteRiskGroupSuccess(state);
    case actionTypes.DELETE_RISK_GROUP_FAIL:
      return deleteRiskGroupFail(state, action);
    case actionTypes.RESET_RISK_GROUP_STORE:
      return resetRiskGroupStore();
    default:
      return state;
  }
};

export default reducer;
