export const CREATE_LESSON_START = 'CREATE_LESSON_START';
export const CREATE_LESSON_SUCCESS = 'CREATE_LESSON_SUCCESS';
export const CREATE_LESSON_FAIL = 'CREATE_LESSON_FAIL';

export const UPDATE_LESSON_START = 'UPDATE_LESSON_START';
export const UPDATE_LESSON_SUCCESS = 'UPDATE_LESSON_SUCCESS';
export const UPDATE_LESSON_FAIL = 'UPDATE_LESSON_FAIL';

export const DELETE_LESSON_START = 'DELETE_LESSON_START';
export const DELETE_LESSON_SUCCESS = 'DELETE_LESSON_SUCCESS';
export const DELETE_LESSON_FAIL = 'DELETE_LESSON_FAIL';

export const UPDATE_LESSONS_PRIORITY_START = 'UPDATE_LESSONS_PRIORITY_START';
export const UPDATE_LESSONS_PRIORITY_SUCCESS =
  'UPDATE_LESSONS_PRIORITY_SUCCESS';
export const UPDATE_LESSONS_PRIORITY_FAIL = 'UPDATE_LESSONS_PRIORITY_FAIL';

export const COMPLETE_LESSON_START = 'COMPLETE_LESSON_START';
export const COMPLETE_LESSON_SUCCESS = 'COMPLETE_LESSON_SUCCESS';
export const COMPLETE_LESSON_FAIL = 'COMPLETE_LESSON_FAIL';

export const TOGGLE_LESSON_STATUS_START = 'TOGGLE_LESSON_STATUS_START';
export const TOGGLE_LESSON_STATUS_SUCCESS = 'TOGGLE_LESSON_STATUS_SUCCESS';
export const TOGGLE_LESSON_STATUS_FAIL = 'TOGGLE_LESSON_STATUS_FAIL';

export const RESET_COMPLETE_LESSON_STORE = 'RESET_COMPLETE_LESSON_STORE';
export const RESET_LESSON_STORE = 'RESET_LESSON_STORE';

export type LessonActionTypes =
  | typeof CREATE_LESSON_START
  | typeof CREATE_LESSON_SUCCESS
  | typeof CREATE_LESSON_FAIL
  | typeof UPDATE_LESSON_START
  | typeof UPDATE_LESSON_SUCCESS
  | typeof UPDATE_LESSON_FAIL
  | typeof DELETE_LESSON_START
  | typeof DELETE_LESSON_SUCCESS
  | typeof DELETE_LESSON_FAIL
  | typeof COMPLETE_LESSON_START
  | typeof COMPLETE_LESSON_SUCCESS
  | typeof COMPLETE_LESSON_FAIL
  | typeof RESET_LESSON_STORE
  | typeof RESET_COMPLETE_LESSON_STORE
  | typeof TOGGLE_LESSON_STATUS_START
  | typeof TOGGLE_LESSON_STATUS_SUCCESS
  | typeof TOGGLE_LESSON_STATUS_FAIL
  | typeof UPDATE_LESSONS_PRIORITY_START
  | typeof UPDATE_LESSONS_PRIORITY_SUCCESS
  | typeof UPDATE_LESSONS_PRIORITY_FAIL;
