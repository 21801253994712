import * as actionTypes from './actionTypes';
import { LessonActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';

export type LessonStateType = {
  lessonsListUpdateNeeded: boolean;
  lessonCreateLoading: boolean;
  lessonCreateError: HttpError;
  lessonUpdateLoading: boolean;
  lessonUpdateError: HttpError;
  lessonDeleteLoading: boolean;
  lessonDeleteError: HttpError;
  lessonsPriorityUpdateLoading: boolean;
  lessonsPriorityUpdateError: HttpError;
  lessonsPriorityUpdateSuccess: boolean;
  lessonCompleteLoading: boolean;
  lessonCompleteError: HttpError;
  lessonCompleteSuccess: boolean;
  lessonStatusLoading: boolean;
  lessonStatusSuccess: boolean;
  lessonStatusError: HttpError;
};

export type LessonActionType = LessonStateType & {
  type: LessonActionTypes;
};

export const initialState: LessonStateType = {
  lessonsListUpdateNeeded: false,
  lessonCreateLoading: false,
  lessonCreateError: null,
  lessonUpdateLoading: false,
  lessonUpdateError: null,
  lessonDeleteLoading: false,
  lessonDeleteError: null,
  lessonsPriorityUpdateLoading: false,
  lessonsPriorityUpdateError: null,
  lessonsPriorityUpdateSuccess: false,
  lessonCompleteError: null,
  lessonCompleteLoading: false,
  lessonCompleteSuccess: false,
  lessonStatusError: null,
  lessonStatusSuccess: false,
  lessonStatusLoading: false,
};

const createLessonStart = (state: LessonStateType): LessonStateType => ({
  ...state,
  lessonCreateLoading: true,
  lessonsPriorityUpdateSuccess: false,
});

const createLessonSuccess = (state: LessonStateType): LessonStateType => ({
  ...state,
  lessonCreateLoading: false,
  lessonCreateError: null,
  lessonsListUpdateNeeded: true,
});

const createLessonFail = (
  state: LessonStateType,
  action: LessonActionType,
): LessonStateType => ({
  ...state,
  lessonCreateLoading: false,
  lessonCreateError: action.lessonCreateError,
});

const updateLessonStart = (state: LessonStateType): LessonStateType => ({
  ...state,
  lessonUpdateLoading: true,
  lessonsPriorityUpdateSuccess: false,
});

const updateLessonSuccess = (state: LessonStateType): LessonStateType => ({
  ...state,
  lessonUpdateLoading: false,
  lessonUpdateError: null,
  lessonsListUpdateNeeded: true,
});

const updateLessonFail = (
  state: LessonStateType,
  action: LessonActionType,
): LessonStateType => ({
  ...state,
  lessonUpdateLoading: false,
  lessonUpdateError: action.lessonUpdateError,
});

const deleteLessonStart = (state: LessonStateType): LessonStateType => ({
  ...state,
  lessonDeleteLoading: true,
  lessonsPriorityUpdateSuccess: false,
});

const deleteLessonSuccess = (state: LessonStateType): LessonStateType => ({
  ...state,
  lessonDeleteLoading: false,
  lessonDeleteError: null,
  lessonsListUpdateNeeded: true,
});

const deleteLessonFail = (
  state: LessonStateType,
  action: LessonActionType,
): LessonStateType => ({
  ...state,
  lessonDeleteLoading: false,
  lessonDeleteError: action.lessonDeleteError,
});

const updateLessonsPriorityStart = (
  state: LessonStateType,
): LessonStateType => ({
  ...state,
  lessonsPriorityUpdateLoading: true,
  lessonsPriorityUpdateSuccess: false,
});

const updateLessonsPrioritySuccess = (
  state: LessonStateType,
): LessonStateType => ({
  ...state,
  lessonsPriorityUpdateError: null,
  lessonsPriorityUpdateSuccess: true,
  lessonsListUpdateNeeded: true,
});

const updateLessonsPriorityFail = (
  state: LessonStateType,
  action: LessonActionType,
): LessonStateType => ({
  ...state,
  lessonsPriorityUpdateLoading: false,
  lessonsPriorityUpdateError: action.lessonsPriorityUpdateError,
});

const completeLessonStart = (state: LessonStateType): LessonStateType => ({
  ...state,
  lessonCompleteLoading: true,
});

const completeLessonSuccess = (state: LessonStateType): LessonStateType => ({
  ...state,
  lessonCompleteLoading: false,
  lessonCompleteError: null,
  lessonCompleteSuccess: true,
});

const completeLessonFail = (
  state: LessonStateType,
  action: LessonActionType,
): LessonStateType => ({
  ...state,
  lessonCompleteLoading: false,
  lessonCompleteError: action.lessonCompleteError,
});

const toggleLessonStatusStart = (state: LessonStateType): LessonStateType => ({
  ...state,
  lessonStatusLoading: true,
});

const toggleLessonStatusSuccess = (
  state: LessonStateType,
): LessonStateType => ({
  ...state,
  lessonStatusLoading: false,
  lessonStatusError: null,
  lessonStatusSuccess: true,
  lessonsListUpdateNeeded: true,
});

const toggleLessonStatusFail = (
  state: LessonStateType,
  action: LessonActionType,
): LessonStateType => ({
  ...state,
  lessonStatusLoading: false,
  lessonStatusError: action.lessonStatusError,
});

const resetCompeteLessonStore = (state: LessonStateType): LessonStateType => ({
  ...state,
  lessonCompleteSuccess: false,
});

const resetLessonStore = (): LessonStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: LessonActionType) => {
  switch (action.type) {
    case actionTypes.CREATE_LESSON_START:
      return createLessonStart(state);
    case actionTypes.CREATE_LESSON_SUCCESS:
      return createLessonSuccess(state);
    case actionTypes.CREATE_LESSON_FAIL:
      return createLessonFail(state, action);
    case actionTypes.UPDATE_LESSON_START:
      return updateLessonStart(state);
    case actionTypes.UPDATE_LESSON_SUCCESS:
      return updateLessonSuccess(state);
    case actionTypes.UPDATE_LESSON_FAIL:
      return updateLessonFail(state, action);
    case actionTypes.DELETE_LESSON_START:
      return deleteLessonStart(state);
    case actionTypes.DELETE_LESSON_SUCCESS:
      return deleteLessonSuccess(state);
    case actionTypes.DELETE_LESSON_FAIL:
      return deleteLessonFail(state, action);
    case actionTypes.RESET_COMPLETE_LESSON_STORE:
      return resetCompeteLessonStore(state);
    case actionTypes.RESET_LESSON_STORE:
      return resetLessonStore();
    case actionTypes.COMPLETE_LESSON_START:
      return completeLessonStart(state);
    case actionTypes.COMPLETE_LESSON_SUCCESS:
      return completeLessonSuccess(state);
    case actionTypes.COMPLETE_LESSON_FAIL:
      return completeLessonFail(state, action);
    case actionTypes.TOGGLE_LESSON_STATUS_START:
      return toggleLessonStatusStart(state);
    case actionTypes.TOGGLE_LESSON_STATUS_SUCCESS:
      return toggleLessonStatusSuccess(state);
    case actionTypes.TOGGLE_LESSON_STATUS_FAIL:
      return toggleLessonStatusFail(state, action);
    case actionTypes.UPDATE_LESSONS_PRIORITY_START:
      return updateLessonsPriorityStart(state);
    case actionTypes.UPDATE_LESSONS_PRIORITY_SUCCESS:
      return updateLessonsPrioritySuccess(state);
    case actionTypes.UPDATE_LESSONS_PRIORITY_FAIL:
      return updateLessonsPriorityFail(state, action);
    default:
      return state;
  }
};

export default reducer;
