export const FETCH_INSTRUCTION_GROUPS_START = 'FETCH_INSTRUCTION_GROUPS_START';
export const FETCH_INSTRUCTION_GROUPS_SUCCESS =
  'FETCH_INSTRUCTION_GROUPS_SUCCESS';
export const FETCH_INSTRUCTION_GROUPS_FAIL = 'FETCH_INSTRUCTION_GROUPS_FAIL';

export const CREATE_INSTRUCTION_GROUP_START = 'CREATE_INSTRUCTION_GROUP_START';
export const CREATE_INSTRUCTION_GROUP_SUCCESS =
  'CREATE_INSTRUCTION_GROUP_SUCCESS';
export const CREATE_INSTRUCTION_GROUP_FAIL = 'CREATE_INSTRUCTION_GROUP_FAIL';

export const UPDATE_INSTRUCTION_GROUP_START = 'UPDATE_INSTRUCTION_GROUP_START';
export const UPDATE_INSTRUCTION_GROUP_SUCCESS =
  'UPDATE_INSTRUCTION_GROUP_SUCCESS';
export const UPDATE_INSTRUCTION_GROUP_FAIL = 'UPDATE_INSTRUCTION_GROUP_FAIL';

export const DELETE_INSTRUCTION_GROUP_START = 'DELETE_INSTRUCTION_GROUP_START';
export const DELETE_INSTRUCTION_GROUP_SUCCESS =
  'DELETE_INSTRUCTION_GROUP_SUCCESS';
export const DELETE_INSTRUCTION_GROUP_FAIL = 'DELETE_INSTRUCTION_GROUP_FAIL';

export const RESET_INSTRUCTION_GROUP_STORE = 'RESET_INSTRUCTION_GROUP_STORE';

export type InstructionGroupActionTypes =
  | typeof FETCH_INSTRUCTION_GROUPS_START
  | typeof FETCH_INSTRUCTION_GROUPS_SUCCESS
  | typeof FETCH_INSTRUCTION_GROUPS_FAIL
  | typeof CREATE_INSTRUCTION_GROUP_START
  | typeof CREATE_INSTRUCTION_GROUP_SUCCESS
  | typeof CREATE_INSTRUCTION_GROUP_FAIL
  | typeof UPDATE_INSTRUCTION_GROUP_START
  | typeof UPDATE_INSTRUCTION_GROUP_SUCCESS
  | typeof UPDATE_INSTRUCTION_GROUP_FAIL
  | typeof DELETE_INSTRUCTION_GROUP_START
  | typeof DELETE_INSTRUCTION_GROUP_SUCCESS
  | typeof DELETE_INSTRUCTION_GROUP_FAIL
  | typeof RESET_INSTRUCTION_GROUP_STORE;
